import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { CardRadialFilterBarChart } from 'src/components/card-radial-bar-chart/card-radial-filter-bar-chart';

import { FundingType } from '../../../../../../types';

type FundingTotalProps = {
  funding?: FundingType;
  title?: string;
  seriesValues?: string[];
  showFilter?: boolean;
  customSeriesValue?: string;
  customTotal?: number;
  updateCustomSeriesValue?: (value: string) => void;
}

export const FundingTotal = (props: FundingTotalProps) => {
  const {
    funding,
    showFilter,
    seriesValues,
    title = 'Funding',
    updateCustomSeriesValue,
    customSeriesValue,
    customTotal,
  } = props;
  const theme = useTheme();

  const generatedMockDataForRadialChart = useMemo(() => {
    return [
      {
        label: 'Soft Committed',
        value: funding?.softCommitedAmount || 0,
        seriesFilter: customSeriesValue,
      },
      {
        label: 'Capital Secured',
        value: funding?.securedAmount || 0,
        seriesFilter: customSeriesValue,
      },
    ];
  }, [customSeriesValue, funding?.securedAmount, funding?.softCommitedAmount]);

  return (
    <CardRadialFilterBarChart
      showFilter={showFilter}
      customSeriesValue={customSeriesValue}
      updateCustomSeriesValue={updateCustomSeriesValue}
      seriesValues={seriesValues}
      customTotal={customTotal}
      title={title}
      chartHeader='Total Required'
      chart={{
        colors: [
          [theme.palette.primary.main, theme.palette.primary.main],
          [theme.palette.primary.lighter, theme.palette.primary.lighter],
        ],
        series: generatedMockDataForRadialChart,
      }}
    />
  );
};
