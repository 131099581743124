import * as App from './app';
import * as Auth from './auth';
import * as Finance from './finance';
import * as Financial from './financial';
import * as Lead from './lead';
import * as Partners from './partners';
import * as ProjectPreparation from './project-preparation';
import * as ReadyForInvestors from './ready-for-investors';
import * as Research from './research';
import * as Sell from './sell';
import * as Storage from './storage';
import * as Tendering from './tendering';


export * from './store';

export const Actions = {
  App,
  Auth,
  Lead,
  Storage,
  Partners,
  Financial,
  Finance,
  Research,
  ReadyForInvestors,
  Tendering,
  ProjectPreparation,
  Sell,
};
