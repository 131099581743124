import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { UploadCloudIcon } from 'src/assets';
import { getFileConfig, UploadedFilesType } from 'src/components';
import { BorderedTableRow } from 'src/components/table/bordered-table-row';
import { useResponsive } from 'src/hooks';
import { openInNewTab, showEmptyValue } from 'src/utils';

type DocumentsTableRowProps = {
  row: UploadedFilesType;
  showUploadedBy?: boolean;
  hideUploadCell?: boolean;
}

export const DocumentsTableRow = (props: DocumentsTableRowProps) => {
  const theme = useTheme();
  const {
    row,
    showUploadedBy,
    hideUploadCell,
  } = props;
  const { uploadedAt } = row;
  const mdUp = useResponsive('up', 'md');

  const onOpen = (str: string) => () => {
    openInNewTab(str);
  };

  return (
    <BorderedTableRow hover>
      <TableCell>
        <Stack direction='row' gap={2} alignItems='center'>
          {getFileConfig(row.filetype).icon}
          <Stack>
            <Typography
              variant='body2'
              color='text.primary'
              sx={mdUp ? {} : {
                width: '115px !important',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {row.filename}
            </Typography>
            <Typography
              variant='caption'
              color={theme.palette.text.secondary}
            >
              {row.size || row.filesize} KB
            </Typography>
          </Stack>
        </Stack>
      </TableCell>

      {!hideUploadCell &&
        <TableCell>
          <Stack gap={0.5}>
            <Typography variant='body2' color='text.primary'>
              {showEmptyValue(uploadedAt)}
            </Typography>

            {showUploadedBy && row.uploadedBy &&
              <Typography variant='caption' color='text.secondary'>
                {row.uploadedBy as string}
              </Typography>
            }
          </Stack>
        </TableCell>
      }

      <TableCell align='right'>
        <Stack direction='row' justifyContent='flex-end' alignItems='center'>
          <Button
            onClick={onOpen(row.url!)}
            startIcon={<UploadCloudIcon/>}
            variant='outlined'
            size='small'
            disabled={!row.url}
          >
            Download
          </Button>
        </Stack>
      </TableCell>
    </BorderedTableRow>
  );
};
