import { Card, Grid, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import {
  CardDonutChart,
  CardRadialBarChart,
  EcommerceWidgetSummary,
  FieldType,
  HelpCloud,
  HelpCloudProps,
  ImageStack,
  ItemType,
  LandDetail,
  ReportCard,
  ReportCardProps,
  StepType,
  Summary,
  useConstructionResult,
} from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';

import { FileForUpload, Scenario, UnitsArray } from '../../../../../types';
import { StepperBacklog } from './stepper-backlog';

export type OverviewProps = HelpCloudProps & {
  scenario?: Scenario;
  note?: string;
  summaryItemsTitle?: string;
  summaryItems?: ItemType[];
  leadSummaryItems?: ItemType[];
  learnMoreItems?: ItemType[];
  landFields?: FieldType[];
  showData?: boolean;
  showLearnMoreButton?: boolean;
  reportCards?: ReportCardProps[];
  images?: FileForUpload[];
  steps: StepType[];
  leadId?: string;
  projectId?: string;
}

export const Overview = (props: OverviewProps) => {
  const {
    scenario,
    note,
    showData = false,
    showLearnMoreButton = true,
    summaryItemsTitle = 'Project Summary',
    summaryItems = [],
    leadSummaryItems = [],
    learnMoreItems = [],
    landFields = [],
    reportCards = [],
    images = [],
    steps = [],
    leadId,
    projectId,
    ...helpCloudProps
  } = props;

  const unitsArray = scenario?.calculate?.input?.units_array?.filter(unit => unit.property_type !== 'BRF Loan (Sweden only)');

  const calculation = useMemo(() => {
    const result: { label: string; value: number; }[] = [];
    unitsArray?.forEach((unit: UnitsArray) => {
      const unitNumber = unit.number || 0;
      const existingEntry = result.find(entry => entry.label === `${unit.bedroom_no} room apartment`);
      if (existingEntry) {
        existingEntry.value += unitNumber;
      } else {
        result.push({
          label: `${unit.bedroom_no} room apartment`,
          value: unitNumber,
        });
      }
    });
    return result;
  }, [unitsArray]);


  const { series } = useConstructionResult(scenario?.calculate?.input || {}, scenario?.calculate?.output || {});

  return (
    <Stack gap={5}>
      <Card>
        <StepperBacklog
          leadId={leadId}
          projectId={projectId}
          steps={steps}
        />
      </Card>
      <HelpCloud
        {...helpCloudProps}
      />
      {reportCards.length > 0 &&
        <Stack gap={3} flexDirection='row'>
          {reportCards.map((cardItem, index) => {
            return (
              <ReportCard
                key={index}
                showData={showData}
                skeletonWidth='100%'
                stackSx={{ width: '100%' }}
                {...cardItem}
              />
            );
          })}
        </Stack>
      }
      <Grid container spacing={3}>
        <Grid container item xs={8} spacing={3}>
          {images.length > 0 &&
            <Grid item xs={12}>
              <ImageStack
                images={images}
                showData={showData}
              />
            </Grid>
          }
          <Grid container item xs={6} spacing={3}>
            <Grid item xs={12}>
              <EcommerceWidgetSummary
                title='GDV'
                total={scenario?.calculate?.output?.GDV}
                showWidget={showData}
                postfix={` ${DEFAULT_CURRENCY}`}
              />
            </Grid>
            <Grid item xs={12}>
              <EcommerceWidgetSummary
                title='Profit on Cost'
                total={scenario?.calculate?.output?.['Profit on cost']}
                showWidget={showData}
                postfix='%'
              />
            </Grid>
            <Grid item xs={12}>
              <EcommerceWidgetSummary
                title='Profit on GDV'
                total={scenario?.calculate?.output?.['Profit on GDV']}
                postfix='%'
                showWidget={showData}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <CardDonutChart
              title='Total Units'
              chartHeader='Total Units'
              showWidget={showData}
              chart={{ series: calculation }}
            />
          </Grid>
          <Grid item xs={6}>
            <CardDonutChart
              title='Finance'
              chartHeader={`Total project cost, ${DEFAULT_CURRENCY}`}
              showWidget={showData}
              chart={{ series: series[0] }}
            />
          </Grid>
          <Grid item xs={6}>
            <CardRadialBarChart
              title='Overview'
              chartHeader={`Total costs, ${DEFAULT_CURRENCY}`}
              showWidget={showData}
              chart={{ series: series[1] }}
            />
          </Grid>
          <Grid item xs={12}>
            <LandDetail
              headLabel='Land Plot Detail'
              coordinates={scenario?.lead?.location?.coordinates}
              fields={landFields}
              showSkeleton={!showData}
            />
          </Grid>
          {note &&
            <Grid item xs={12}>
              <Card>
                <Stack
                  spacing={2}
                  sx={{
                    padding: 3,
                    typography: 'body2',
                  }}
                >
                  <Typography variant='h6'>Note</Typography>
                  {note}
                </Stack>
              </Card>
            </Grid>
          }
        </Grid>
        <Grid container item xs={4} spacing={3} alignContent='flex-start'>
          <Grid item xs={12}>
            <Card>
              <Summary
                showSkeleton={!showData}
                title={summaryItemsTitle}
                items={summaryItems}
              />
            </Card>
          </Grid>
          {leadSummaryItems.length > 0 &&
            <Grid item xs={12}>
              <Card>
                <Summary
                  showLearnMoreButton={showLearnMoreButton}
                  showSkeleton={!showData}
                  title='Lead Summary'
                  items={leadSummaryItems}
                  learnMoreItems={learnMoreItems}
                />
              </Card>
            </Grid>
          }
        </Grid>
      </Grid>
    </Stack>
  );
};
