import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { SyntheticEvent } from 'react';
import { fDistanceDate } from 'src/utils';

import { NotificationType } from '../../../../../types';

export type NotificationItemProps = {
  notification: NotificationType;
  onReadClickHandler: (notification: NotificationType) => (event: SyntheticEvent) => void;
  onOpenClickHandler: (notification: NotificationType) => (event: SyntheticEvent) => void;
};

export const NotificationItem = (props: NotificationItemProps) => {
  const {
    notification,
    onReadClickHandler,
    onOpenClickHandler,
  } = props;

  const renderAvatar = (
    <ListItemAvatar>
      <Avatar
        src={notification.logoUrl}
      />
    </ListItemAvatar>
  );

  const renderText = (
    <ListItemText
      disableTypography
      sx={{ pr: 1 }}
      primary={<MessageWithHighlight message={notification.message}/>}
      secondary={
        <Stack
          direction='row'
          alignItems='center'
          sx={{
            typography: 'caption',
            color: 'text.disabled',
          }}
          divider={
            <Box
              sx={{
                width: 2,
                height: 2,
                bgcolor: 'currentColor',
                mx: 0.5,
                borderRadius: '50%',
              }}
            />
          }
        >
          {fDistanceDate(notification.date)}
          {notification.section}
        </Stack>
      }
    />
  );

  const renderUnReadBadge = !notification.isDone && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
      }}
    />
  );

  const action = (
    <Stack spacing={1} direction='row' sx={{ mt: 1.5 }}>
      <Button
        size='small'
        variant='contained'
        onClick={onOpenClickHandler(notification)}
      >
        Open
      </Button>
      <Button
        size='small'
        variant='outlined'
        disabled={notification.isDone}
        onClick={onReadClickHandler(notification)}
      >
        Read
      </Button>
    </Stack>
  );

  return (
    <ListItemButton
      disableRipple
      onClick={onReadClickHandler(notification)}
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      {renderUnReadBadge}

      {renderAvatar}

      <Stack sx={{ flexGrow: 1 }}>
        {renderText}
        {action}
      </Stack>
    </ListItemButton>
  );
};

export interface MessageWithHighlightProps {
  message: string;
}

export const MessageWithHighlight = (props: MessageWithHighlightProps) => {
  const { message } = props;
  const parts = message.split(/(\[[^\]]+\])/g);

  return (
    <Box
      display='flex'
      gap={0.5}
      rowGap={0}
      flexWrap='wrap'
    >
      <>
        {parts.map((part, index) => {
          if (!part) return null;

          if (part.startsWith('[') && part.endsWith(']')) {
            const content = part.slice(1, -1);
            return (
              <Typography whiteSpace='nowrap' variant='subtitle2' component='span' key={index}>
                {content}
              </Typography>
            );
          }
          return (
            <Typography whiteSpace='nowrap' variant='body2' color='text.primary' key={index}>
              {part}
            </Typography>
          );
        })}
      </>
    </Box>
  );
};
