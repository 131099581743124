import { UpdateStatus, User } from '../../../types';
import { paths, rolesAccess } from '../config';
import { getStorage, removeStorage } from '../hooks';

export type authHelperParams = {
  authStatus: UpdateStatus,
  user?: User
  ignoreLoginsCount?: boolean;
  customReturnUrl?: string;
  isAuthCompleted?: boolean;
}

export const authHelper = (params: authHelperParams) => {
  const {
    authStatus,
    user,
    ignoreLoginsCount = false,
    customReturnUrl = '',
    isAuthCompleted = true,
  } = params;

  const returnUrl = getStorage('returnUrl') || customReturnUrl;
  const pathname = window.location.pathname;
  const loginsCount = ignoreLoginsCount ? 1000 : (user?.loginsCount ?? 0);
  const isFirstSeeReport = returnUrl.includes('/report') && loginsCount === 0;

  if ((loginsCount > 0 && (!user?.confirmed || !isAuthCompleted)) && pathname !== '/get-started') {
    window.location.href = paths.getStarted;
    return;
  }

  if ((pathname === '/partner-portal' || pathname === '/partner-portal/') && user) {
    window.location.href = paths.partnerPortal.overview;
  }

  if ((pathname === '/investor' || pathname === '/investor/') && user) {
    window.location.href = paths.investorPortal.projects.list;
  }

  if (pathname !== '/' || authStatus !== 'success' || !user?.id) {
    return;
  }

  removeStorage('returnUrl');
  if (pathname === '/' || isFirstSeeReport) {
    window.location.href = returnUrl || rolesAccess[user?.role].defaultPath;
  }
};
