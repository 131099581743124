import { Avatar, Card, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import { StyledCardHeader } from 'src/components/team-member-card/styles';

export interface AssigneeCardProps {
  headTitle?: string;
  title?: string;
  linkText?: string;
  linkTo?: string;
  logoUrl?: string;
}

export const AssigneeCard = (props: AssigneeCardProps) => {
  const {
    title,
    linkText,
    logoUrl,
    linkTo,
    headTitle,
  } = props;

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography
        variant='h6'
        sx={{
          pt: 3,
          px: 3,
        }}
      >
        {headTitle}
      </Typography>
      <StyledCardHeader
        avatar={
          <Avatar src={logoUrl}/>
        }
        title={title}
        subheader={
          <>
            <Link
              color='#3698AF'
              underline='none'
              target='_blank'
              href={linkTo}
            >
              {linkText}
            </Link>
          </>
        }
      />
    </Card>
  );
};
