import { LoadingButton } from '@mui/lab';
import { Card, CardHeader, Grid, Stack, Typography } from '@mui/material';
import _, { debounce, isEmpty } from 'lodash';
import React, { SyntheticEvent, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  CrumbType,
  FormProvider,
  ProjectToolbar,
  RHFAutocomplete,
  RHFDatePicker,
  RHFEditor,
  RHFMultiCheckbox,
  RHFTextField,
  RoleKey,
  TeamMemberCard,
  UploadedFilesType,
  UploadFile,
  UploadPhotos,
} from 'src/components';
import { paths } from 'src/config';
import { allChipColors, DEFAULT_AREA, DEFAULT_CURRENCY, distributionCheckboxesListForLead } from 'src/constants/constants';
import { useResponsive, useRouter, useScrollToError } from 'src/hooks';
import { Actions, RootState } from 'src/store';
import { getFileSections } from 'src/store/app';
import { CreateLead, createLead, getLocalArea, getLocalAreaSchools, getLocalAreaStations, getLocalMarket, loadLeadDetails } from 'src/store/lead';
import { getAddresses, getCoordinates, resetDetailsViewData, setIsLoading } from 'src/store/storage';
import { convertToOnlyNumber, fNumber, formatFullName, getAllFiles, getCoverImagesByUrl, isEmailValid, isNumberInput, isPhoneNumberValid, limitLength } from 'src/utils';

import { AssigneeType, AssignmentMember, CoverImagesType, Lead, TeamRoleType, User } from '../../../../types';
import defaultImageApartments from './images/apartments.png';
import defaultImageHouses from './images/houses.png';

export interface NewLeadProps extends Omit<Lead, 'totalFloorArea' | 'plotArea' | 'files' | 'distribution'> {
  totalFloorArea?: string;
  plotArea?: string;
  offeringClosingDate?: string;
  analyst?: AssignmentMember;
  projectManager?: AssignmentMember;
  files?: UploadedFilesType[];
  filesToDelete?: UploadedFilesType[];
  newCoverImageId?: string;
  coverImages?: CoverImagesType[];
  coverImagesToDelete?: CoverImagesType[];
  distribution?: string[];
}

export type LocationOptionType = {
  name: string;
  address: string;
}

export const NewLead = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{
    id?: string
  }>();

  const mdUp = useResponsive('up', 'md');
  const router = useRouter();
  const userList = useSelector((state: RootState) => state.App.userList);
  const currentUser = useSelector((state: RootState) => state.Auth.user);
  const addresses = useSelector((state: RootState) => state.Storage.addresses);
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const fileSections = useSelector((state: RootState) => state.App.fileSections);
  const isLoading = useSelector((state: RootState) => state.Storage.isLoading);
  const reducedMenu = useSelector((state: RootState) => state.App.config?.reducedMenu);

  const lead = detailsData?.lead;
  const assets = detailsData?.assets;

  const defaultProjectManager: User | undefined = useMemo(() => {
    const userInList = userList.find(user => user.id === currentUser?.id);
    const isUserCreator = lead?.userId === userInList?.id;
    return (!id || isUserCreator) ? userInList : undefined;
  }, [currentUser?.id, id, lead?.userId, userList]);

  const defaultValues = useMemo(() => {
    const distribution = lead?.distribution ? _.chain(lead?.distribution)
      .pickBy(value => value)
      .keys()
      .value() : ['research', 'projectPreparation'];
    const files = id ? getAllFiles(fileSections)?.filter(file => file.section === 'Lead') : [];
    return {
      location: lead?.location,
      offeringClosingDate: assets?.general?.offeringClosingDate || '',
      projectManager: detailsData?.users?.find(assignee => assignee.designation === 'Project manager'),
      analyst: detailsData?.users?.find(assignee => assignee.designation === 'Analyst'),
      note: assets?.marketPlaceListing?.description || '',
      name: lead?.name || '',
      price: lead?.price || 0,
      buyerFeeAmount: lead?.buyerFeeAmount || 0,
      totalFloorArea: fNumber(lead?.totalFloorArea, '', true),
      plotArea: fNumber(lead?.plotArea, '', true),
      cadastralId: lead?.cadastralId || '',
      contactDetails: lead?.contactDetails || {
        company: '',
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
      },
      files: files,
      coverImages: assets?.marketPlaceListing?.coverImages || [],
      distribution,
    };
  }, [assets?.general?.offeringClosingDate, assets?.marketPlaceListing?.coverImages, assets?.marketPlaceListing?.description, detailsData?.users, fileSections, id, lead?.buyerFeeAmount, lead?.cadastralId, lead?.contactDetails, lead?.distribution, lead?.location, lead?.name, lead?.plotArea, lead?.price, lead?.totalFloorArea]);

  const form = useForm<NewLeadProps>({ defaultValues });
  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: {
      errors,
      isSubmitting,
    },
  } = form;
  useScrollToError(isEmpty(errors) ? null : errors);

  const {
    files: uploadedFiles,
    analyst,
    projectManager,
    filesToDelete,
    price,
    location,
    coverImages = [],
    coverImagesToDelete = [],
    distribution,
  } = watch();

  useEffect(() => {
    if (id) {
      dispatch(loadLeadDetails(id));
      dispatch(getFileSections(id));
    }
    dispatch(Actions.App.getUserList());
  }, [dispatch, id]);

  useEffect(() => {
    reset(defaultValues);
    if (!id) {
      dispatch(resetDetailsViewData());
    }
    (async () => {
      if (id) {
        return;
      }

      const images = await getCoverImagesByUrl([defaultImageApartments, defaultImageHouses]);
      setValue('coverImages', images);
    })();
  }, [defaultValues, dispatch, id, reset, setValue]);

  useEffect(() => {
    if (defaultProjectManager) {
      handleAssign('projectManager', 'Project manager')(defaultProjectManager);
    }
  }, [defaultProjectManager]);

  const onSubmit = (data: NewLeadProps) => {
    if (Boolean(Object.keys(errors).length)) return;
    const {
      files,
      filesToDelete,
      projectManager,
      analyst,
      totalFloorArea,
      plotArea,
      coverImagesToDelete,
      coverImages,
      newCoverImageId,
      distribution = [],
      buyerFeeAmount,
      offeringClosingDate,
      ...rest
    } = data;

    const findAssignee = (designation: string) => detailsData?.users?.find(assignee => assignee.designation === designation);
    const assignedProjectManager = findAssignee('Project manager');
    const assignedAnalyst = findAssignee('Analyst');

    const isNewlyAssigned = (assigned?: AssigneeType, newValue?: AssignmentMember) => assigned?.profileLink === newValue?.id;
    const isNewProject = isNewlyAssigned(assignedProjectManager, projectManager);
    const isNewAnalyst = isNewlyAssigned(assignedAnalyst, analyst);
    const correspondingData: CreateLead = {
      lead: {
        ...rest,
        buyerFeeAmount: convertToOnlyNumber(buyerFeeAmount),
        totalFloorArea: convertToOnlyNumber(totalFloorArea),
        plotArea: convertToOnlyNumber(plotArea),
        _id: id || '',
        source: 'Manual',
        distribution: {
          research: distribution?.includes('research'),
          projectPreparation: distribution?.includes('projectPreparation'),
          tendering: distribution?.includes('tendering'),
          debtFinancing: distribution?.includes('debtFinancing'),
          equityFinancing: distribution?.includes('equityFinancing'),
          sell: distribution?.includes('sell'),
        },
      },
      files: files?.filter(file => file.file),
      filesToDelete,
      asset: assets,
      newCoverImageId,
      coverImagesToDelete,
      coverImages,
      offeringClosingDate,
      assigneesToDelete: _.compact([
        isNewProject ? undefined : assignedProjectManager,
        isNewAnalyst ? undefined : assignedAnalyst,
      ]),
      assignedMembers: _.compact([
        isNewProject ? undefined : projectManager,
        isNewAnalyst ? undefined : analyst,
      ]),
      callback: () => {
        router.push(paths.leads.list);
      },
    };
    dispatch(createLead(correspondingData));
  };

  const uploadFileHandler = (files: UploadedFilesType[]) => {
    setValue('files', files);
  };

  const deleteFilesHandler = (files: UploadedFilesType[]) => {
    setValue('filesToDelete', files);
  };

  const handleAssign = (roleKey: RoleKey, designation: TeamRoleType) => (newUser: Omit<User, 'email' | 'role'>) => {
    const isSameUser = getValues(roleKey)?.id === newUser?.id;
    const user = userList.find(user => user.id === newUser?.id);
    const correspondingUser: AssignmentMember = {
      ...user,
      name: formatFullName(user?.name, user?.surname),
      designation,
      profileLink: user?.id || 'no data',
    };
    setValue(roleKey, isSameUser ? undefined : correspondingUser);
  };

  const crumbs: CrumbType[] = [
    {
      title: 'Opportunities',
      href: paths.leads.list,
    },
    {
      title: 'Leads',
      href: paths.leads.list,
    },
    { title: id ? lead?.location?.address : 'New Lead' },
  ];

  const autoCompleteInputOnChangeHandler = (_: SyntheticEvent, value: string) => {
    setValue('location.address', value);
    debouncedGetAddresses(value);
  };

  const debouncedGetAddresses = debounce((value: string) => {
    dispatch(getAddresses(value));
  }, 500);

  const autoCompleteOnChangeHandler = async (_: SyntheticEvent, value: null | string | string[]) => {
    if (value) {
      await locationHandler(value as string);
    }
  };

  const locationHandler = async (value: string) => {
    dispatch(setIsLoading(true));
    const geoCodes = await getCoordinates(value);
    if (!geoCodes) {
      setError('location', { message: 'Location wasn\'t found' });
      return;
    }

    const [localMarket, localArea, schools, stations] = await Promise.all([
      getLocalMarket(geoCodes.geometry.coordinates),
      getLocalArea(geoCodes.geometry.coordinates),
      getLocalAreaSchools(geoCodes.geometry.coordinates),
      getLocalAreaStations(geoCodes.geometry.coordinates),
    ]);
    setValue('localArea', localArea);
    setValue('localMarket', localMarket);
    setValue('stations', stations);
    setValue('schools', schools);
    setValue('location', {
      address: geoCodes.placeName,
      coordinates: geoCodes.geometry.coordinates,
    });
    clearErrors('location');
    dispatch(setIsLoading(false));
  };

  const autoCompleteOnBlurHandler = async () => {
    await locationHandler(location?.address || '');
  };

  const uploadCoverImages = async (files: CoverImagesType[]) => {
    const availableToUpload = 100 - coverImages.length;
    const slicedFiles = availableToUpload > 0 ? files.splice(0, availableToUpload) : [];
    setValue('coverImages', [...coverImages, ...slicedFiles]);
  };

  const deleteCoverImage = (file: CoverImagesType, updatedImages: CoverImagesType[]) => {
    if (!file.file) {
      setValue('coverImagesToDelete', [...coverImagesToDelete, file]);
    }
    if (updatedImages.length > 0 && file.isCoverImage) {
      setValue('newCoverImageId', updatedImages[0]._id);
    }
    setValue('coverImages', updatedImages);
  };

  const setImageAsCover = (file: CoverImagesType) => {
    const updatedCover: CoverImagesType[] = coverImages.map(image => {
      return {
        ...image,
        isCoverImage: file._id === image._id,
      };
    });
    setValue('newCoverImageId', file._id);
    setValue('coverImages', updatedCover);
  };

  const sortCoverImages = (files: CoverImagesType[]) => {
    setValue('coverImages', files);
  };

  const leadInformation = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Lead Information
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title='Lead Information'/>}

          <Stack spacing={3} sx={{ p: 3 }} flexWrap='wrap'>
            <RHFTextField
              name='name'
              label='Project name'
            />
            <RHFAutocomplete
              autoHighlight
              required
              name='location'
              label='Location'
              value={location?.address || ''}
              noOptionsText='No Location'
              onInputChange={autoCompleteInputOnChangeHandler}
              onChange={autoCompleteOnChangeHandler}
              onBlur={autoCompleteOnBlurHandler}
              options={addresses.map(address => address.name)}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option}>
                  {option}
                </li>
              )}
            />
            <RHFDatePicker
              name='offeringClosingDate'
              label='Offering Estimated Closing Date'
            />
            <Stack>
              <Typography variant='body2' fontWeight={600} mb={1.5}>
                Basic Details
              </Typography>
              <RHFEditor simple name='note'/>
            </Stack>
            <RHFTextField
              required
              name='price'
              label={`Purchase price, ${DEFAULT_CURRENCY}`}
              value={fNumber(price)}
              onChange={(event) => {
                const value = event.target.value;
                if (value && isNumberInput(value)) clearErrors('price');
                setValue('price', +limitLength(value));
              }}
              rules={{
                validate: value => {
                  return (isNumberInput(value) && value) || 'Invalid price format';
                },
              }}
            />
            <RHFTextField
              useSeparators
              name='buyerFeeAmount'
              label={`Buyer's fee, ${DEFAULT_CURRENCY}`}
              rules={{
                validate: value => {
                  if (!distribution?.includes('sell')) return;
                  return isNumberInput(value) || 'Invalid fee format';
                },
              }}
            />
            <RHFTextField
              name='totalFloorArea'
              label={`Project size, ${DEFAULT_AREA}`}
              onChange={(event) => {
                const value = event.target.value;
                setValue('totalFloorArea', fNumber(value));
              }}
              rules={{
                validate: value => {
                  if (!value) return;
                  return isNumberInput(value) || 'Invalid size format';
                },
              }}
            />
            <RHFTextField
              name='plotArea'
              label={`Plot size, ${DEFAULT_AREA}`}
              onChange={(event) => {
                const value = event.target.value;
                setValue('plotArea', fNumber(value));
              }}
              rules={{
                validate: value => {
                  if (!value) return;
                  return isNumberInput(value) || 'Invalid size format';
                },
              }}
            />
            <RHFTextField
              name='cadastralId'
              label='Cadastral number'
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const team = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Team
          </Typography>
        </Grid>
      )}
      <Grid xs={12} md={8}>
        {!mdUp &&
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Team
          </Typography>
        }
        <Stack direction='row' flexWrap='wrap' gap={3}>
          <TeamMemberCard
            showAssignBtn
            showSkeleton={!userList}
            users={userList}
            teamRole='Project Manager'
            assignedUser={projectManager}
            handleAssign={handleAssign('projectManager', 'Project manager')}
          />
          <TeamMemberCard
            showAssignBtn
            showSkeleton={!userList}
            users={userList}
            teamRole='Analyst'
            assignedUser={analyst}
            handleAssign={handleAssign('analyst', 'Analyst')}
          />
        </Stack>

      </Grid>
    </>
  );

  const contactPerson = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Contact Person
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title='Contact Person'/>}
          <Stack spacing={3} sx={{ p: 3 }} direction='row' flexWrap='wrap'>
            <RHFTextField
              name='contactDetails.firstName'
              label='First name'
            />
            <RHFTextField
              name='contactDetails.lastName'
              label='Second name'
            />
            <RHFTextField
              name='contactDetails.email'
              label='Email'
              rules={{
                validate: value => {
                  if (!value) return;
                  return isEmailValid(value) || 'Invalid email format';
                },
              }}
            />
            <RHFTextField
              name='contactDetails.phone'
              label='Phone number'
              helperText='Format +46–ХХ–ХХХ–ХХХХ'
              rules={{
                validate: value => {
                  if (!value) return;
                  return isPhoneNumberValid(value) || 'Invalid phone number format';
                },
              }}
            />
            <RHFTextField
              name='contactDetails.company'
              label='Company name'
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const leadDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Attachments
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title='Attachments'/>}
          <Stack spacing={3} sx={{ p: 3 }} flexWrap='wrap'>
            <UploadFile
              multiple
              title='Files'
              deleteFilesHandler={deleteFilesHandler}
              uploadFileHandler={uploadFileHandler}
              uploadedFiles={uploadedFiles}
              filesToDelete={filesToDelete}
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const coverImagesBlock = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Cover Images
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            These images are displayed in the marketplace listing
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp &&
            <CardHeader
              title='Cover Images'
              subheader='These images are displayed in the marketplace listing'
            />
          }
          <Stack spacing={3} sx={{ p: 3 }}>
            <UploadPhotos
              coverImages={coverImages}
              sortCoverImages={sortCoverImages}
              deleteCoverImageHandler={deleteCoverImage}
              setImageAsCoverHandler={setImageAsCover}
              uploadCoverImagesHandler={uploadCoverImages}
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const generalSettings = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Distribution
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp &&
            <CardHeader title='Distribution'/>
          }
          <Stack spacing={1} sx={{ p: 3 }}>
            <Typography variant='subtitle2'>Publish in</Typography>
            <Typography variant='caption'>
              The selected items will determine in which sections this project will be displayed and
              processed by team
              members. This is critically important and a mandatory field. The project can be for sale,
              funding only,
              tendering, or all options.
            </Typography>
            <RHFMultiCheckbox
              row
              spacing={4}
              name='distribution'
              options={distributionCheckboxesListForLead(reducedMenu || false)}
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <LoadingButton
      sx={{ ml: 'auto' }}
      type='submit'
      variant='contained'
      size='large'
      loading={isSubmitting || isLoading}
    >
      {id ? 'Update Changes' : 'Create Lead'}
    </LoadingButton>
  );


  return (
    <>
      <ProjectToolbar
        showSkeleton={!userList}
        badgeTitle={id ? lead?.source : 'Owner Request'}
        title={id ? 'Edit Lead' : 'New Lead'}
        crumbs={crumbs}
        badgeColor={allChipColors[id ? (lead?.source ?? 'Manual') : 'Owner Request']}
        badgeVariant='soft'
      />
      <FormProvider methods={form} onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          sx={{
            mx: 0,
            px: 3,
          }}
          rowGap={3}
        >
          {leadInformation}
          {coverImagesBlock}
          {team}
          {contactPerson}
          {leadDetails}
          {generalSettings}
          {renderActions}
        </Grid>
      </FormProvider>
    </>
  );
};
