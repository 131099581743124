import { Fragment, useMemo } from 'react';
import { InvestorPage, PageWrap } from 'src/components';
import { ReportPage } from 'src/features/report-page';

import { usePortalFlags } from './use-portal-flags';

export const useLayoutByPath = () => {
  const {
    isInvestorPortal,
    isReportView,
    isGetStarted,
  } = usePortalFlags();

  const Layout = useMemo(() => {
    if (isInvestorPortal) return InvestorPage;
    if (isReportView) return ReportPage;
    if (isGetStarted) return Fragment;
    return PageWrap;
  }, [isGetStarted, isInvestorPortal, isReportView]);

  return Layout;
};
