import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import { debounce } from 'lodash';
import { ChangeEvent, memo, MouseEvent, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { CustomTab, TableCustom, TableFiltersResult, TablePaginationCustom, TabType } from 'src/components';
import { useTable } from 'src/hooks';

import { PaginationParams, PartnerTransactionsTableFilters, PartnerTransactionStatusType, PartnerTransactionType } from '../../../../../../../types';
import { _mockPartnerTransactions, defaultFilters, TABLE_HEAD } from './mock-data';
import { TransactionTableRow } from './transaction-table-row';

export const PartnerTransactions = memo(() => {
  const [filters, setFilters] = useState(defaultFilters);
  const [tableData] = useState<PartnerTransactionType[]>(_mockPartnerTransactions);
  const dataFiltered = tableData;

  const [correspondingBody, setCorrespondingBody] = useState<PaginationParams>({
    page: 1,
    limit: 5,
  });
  const table = useTable();

  const getLenByStatus = useCallback((status: PartnerTransactionStatusType) => {
    return tableData?.filter((item) => item.status === status)?.length;
  }, [tableData]);

  const TABS: TabType[] = useMemo(() => {
    return [
      {
        value: 'All',
        label: 'All',
        count: tableData?.length,
      },
      {
        value: 'Pending',
        label: 'Pending',
        count: getLenByStatus('Pending'),
      },
      {
        value: 'Sent',
        label: 'Sent',
        count: getLenByStatus('Sent'),
      },
    ];
  }, [getLenByStatus, tableData?.length]);

  const handleFilters = useCallback((name: keyof PartnerTransactionsTableFilters, value: PartnerTransactionStatusType) => {
    table.onResetPage();
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, [table]);

  const handleFilterStatus = useCallback((event: SyntheticEvent, newValue: string) => {
    handleFilters('status', newValue as PartnerTransactionStatusType);
  }, [handleFilters]);

  const canReset = filters.status !== 'All';

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const updateBody = (newBodyPart: Partial<PaginationParams>) => {
    const newBody: PaginationParams = {
      ...correspondingBody,
      ...newBodyPart,
    };
    setCorrespondingBody(newBody);
  };

  const noDataFound = dataFiltered?.length === 0;

  const onPageChange = debounce((event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    updateBody({ page: page + 1 });
    table.onChangePage(event, page);
  }, 200);

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updateBody({
      limit: +event.target.value,
      page: 1,
    });
    table.onChangeRowsPerPage(event);
  };

  const onFiltersDeleteHandler = useCallback((filterKey: keyof PartnerTransactionsTableFilters) => {
    const newValue = defaultFilters[filterKey];
    handleFilters(filterKey, newValue);
  }, [handleFilters]);

  return (
    <Card>
      <CardHeader sx={{ p: 3 }} title='Transactions'/>
      <CustomTab
        tabs={TABS}
        defaultTabValue='All'
        currentTabValue={filters.status}
        handleTabOnChange={handleFilterStatus}
      />
      {canReset && (
        <Stack
          sx={{
            paddingLeft: '20px',
            paddingBottom: '16px',
          }}
        >
          <TableFiltersResult
            filters={filters}
            defaultFilters={defaultFilters}
            onFiltersDeleteHandler={onFiltersDeleteHandler}
            onResetFilters={handleResetFilters}
            results={dataFiltered.length}
          />
        </Stack>
      )}

      <TableCustom
        headLabels={TABLE_HEAD}
        table={table}
        rowCount={dataFiltered?.length}
        noDataFound={noDataFound}
        bodyCells={(
          <>
            {dataFiltered?.map((row) => (
              <TransactionTableRow
                key={row._id}
                row={row}
              />
            ))}
          </>
        )}
      />

      <TablePaginationCustom
        count={tableData.length}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Card>
  );
});
