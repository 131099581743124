import { Card, Grid, Stack, Typography, useTheme } from '@mui/material';
import { memo } from 'react';
import { UserGroupIcon } from 'src/assets/svg';
import { FieldType, HelpCloud, ItemType, LandDetail, StepType, Summary } from 'src/components';
import { StepperBacklog } from 'src/features/research-details';

interface OverviewProps {
  coordinates?: number[];
  showSkeleton: boolean;
  showCloud?: boolean;
  notes?: string;
  leadId?: string;
  projectId?: string;
  landFields: FieldType[];
  summaryItems: ItemType[];
  steps: StepType[];
  onAssignCloudBtnClick?: VoidFunction;
}

export const LeadOverview = memo((props: OverviewProps) => {
  const {
    summaryItems,
    landFields,
    coordinates = null,
    showSkeleton,
    showCloud,
    notes,
    steps,
    onAssignCloudBtnClick,
    leadId,
    projectId,
  } = props;

  const theme = useTheme();

  return (
    <Stack gap={5}>
      <Card>
        <StepperBacklog
          leadId={leadId}
          projectId={projectId}
          steps={steps}
        />
      </Card>
      <HelpCloud
        showCloud={showCloud}
        showSkeleton={showSkeleton}
        cardSx={{ background: '#f1e4ff' }}
        avatar={<UserGroupIcon color={theme.palette.secondary.main}/>}
        onBtnClickHandler={onAssignCloudBtnClick}
        btnTitle='Assign'
        title='Assign a Project Team'
        subtitle='Select from the list of employees analysts and project manager'
      />
      <Grid container spacing={3}>
        <Grid item md={8}>
          <LandDetail
            headLabel='Land Plot Detail'
            coordinates={coordinates}
            fields={landFields}
            showSkeleton={showSkeleton}
          />
        </Grid>
        <Grid item md={4}>
          <Card>
            <Summary showSkeleton={showSkeleton} title='Summary' items={summaryItems}/>
          </Card>
        </Grid>
        {notes &&
          <Grid item md={8}>
            <Card>
              <Stack spacing={2} sx={{
                padding: 3,
                typography: 'body2',
              }}>
                <Typography variant='h6'>Note</Typography>
                {notes}
              </Stack>
            </Card>
          </Grid>
        }
      </Grid>
    </Stack>
  );
});
