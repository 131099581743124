import { Chip } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RHFAutocomplete, RHFCheckbox, RHFEditor, RHFTextField, UploadedFilesType } from 'src/components';
import { UploadPhotos } from 'src/components/';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { useDocuments } from 'src/hooks';
import { RootState } from 'src/store';
import { getAllFiles } from 'src/utils';

import { AssetDocumentsType, CoverImagesType, FinanceUploadFileType } from '../../../../../../types';
import { DefaultAssetTabProps } from './assets';
import { MergedType } from './general';
import { ProjectDocuments } from './project-documents/project-documents';

export interface ListingProps extends DefaultAssetTabProps {
  methods: UseFormReturn<MergedType>;
}

export const Listing = (props: ListingProps) => {
  const { methods } = props;
  const [documentsModalList, setDocumentsModalList] = useState<UploadedFilesType[]>([]);
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const fileSections = useSelector((state: RootState) => state.App.fileSections);

  const { createAndUploadDocument } = useDocuments();

  const {
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;
  const values = watch();

  const loadDocuments = useCallback(async () => {
    const documentsModalList = getAllFiles(fileSections);
    setDocumentsModalList(documentsModalList.filter(doc => doc.url));
  }, [fileSections]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  const onAddDocumentsClickHandler = (files: UploadedFilesType[], missingDocs: UploadedFilesType[] = []) => {
    const selectedFiles = values.documents || [];
    const documentsToDelete = values.marketPlaceListing?.documentsToDelete || [];
    const filteredSelectedDocuments = selectedFiles.filter(doc => !missingDocs.find(file => file._id === doc._id));
    setValue('documents', [...filteredSelectedDocuments, ...files as AssetDocumentsType[]]);
    setValue('marketPlaceListing.documentsToDelete', [...documentsToDelete, ...missingDocs as AssetDocumentsType[]]);
  };

  const onRemoveUploadedFileClickHandler = async (file: UploadedFilesType) => {
    const filesToDelete = values.marketPlaceListing?.documentsToDelete || [];
    const updatedFiles = values.documents?.filter(doc => doc._id !== file._id);
    setValue('marketPlaceListing.documentsToDelete', [...filesToDelete, file]);
    setValue('documents', updatedFiles);
  };

  const uploadCoverImagesHandler = async (files: CoverImagesType[]) => {
    const availableToUpload = 100 - (values.marketPlaceListing?.coverImages?.length || 0);
    const slicedFiles = availableToUpload > 0 ? files.splice(0, availableToUpload) : [];
    setValue('marketPlaceListing.coverImages', [...(values.marketPlaceListing?.coverImages || []), ...slicedFiles]);
    clearErrors('marketPlaceListing.coverImages');
  };

  const deleteCoverImageHandler = (file: CoverImagesType, updatedImages: CoverImagesType[]) => {
    if (!file.file) {
      setValue('marketPlaceListing.coverImagesToDelete', [...(values?.marketPlaceListing?.coverImagesToDelete || []), file]);
    }
    if (updatedImages.length > 0 && file.isCoverImage) {
      setValue('marketPlaceListing.newCoverImageId', updatedImages[0]._id);
    }
    setValue('marketPlaceListing.coverImages', updatedImages);
  };

  const setImageAsCoverHandler = (file: CoverImagesType) => {
    const updatedCover: CoverImagesType[] = values.marketPlaceListing?.coverImages?.map(image => {
      return {
        ...image,
        isCoverImage: file._id === image._id,
      };
    }) || [];
    setValue('marketPlaceListing.newCoverImageId', file._id);
    setValue('marketPlaceListing.coverImages', updatedCover);
  };

  const modalUploadFileHandler = async (file: FinanceUploadFileType) => {
    const uploadedFile = await createAndUploadDocument(file, detailsData.project?._id, detailsData.lead?._id);
    if (uploadedFile) {
      setDocumentsModalList([...documentsModalList, {
        ...uploadedFile,
        size: Math.round((uploadedFile.size || 0) / 1024),
        filename: uploadedFile.sectionFilename || uploadedFile.filename,
      }]);
    }
  };

  const sortCoverImagesHandler = (files: CoverImagesType[]) => {
    setValue('marketPlaceListing.coverImages', files);
  };

  const checkboxOnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setValue('marketPlaceListing.isDocumentDownloadAllowed', value);
  };

  const coverImagesBlock = (
    <Card>
      <CardHeader
        title='Cover Images *'
        subheader='These images are displayed in the marketplace listing'
        subheaderTypographyProps={{ variant: 'body2' }}
      />
      <CardContent>
        <UploadPhotos
          uploadBoxError={!!errors.marketPlaceListing?.coverImages}
          formName='marketPlaceListing.coverImages'
          uploadBoxHelperText={errors.marketPlaceListing?.coverImages?.message}
          coverImages={values.marketPlaceListing?.coverImages}
          sortCoverImages={sortCoverImagesHandler}
          deleteCoverImageHandler={deleteCoverImageHandler}
          setImageAsCoverHandler={setImageAsCoverHandler}
          uploadCoverImagesHandler={uploadCoverImagesHandler}
        />
      </CardContent>
    </Card>
  );

  const basicDetails = (
    <>
      <Card>
        <CardHeader title='Basic Details'/>
        <CardContent>
          <Stack gap={3}>
            <RHFEditor
              id='description'
              name='marketPlaceListing.description'
              placeholder='Description *'
            />
            <RHFAutocomplete
              multiple
              freeSolo
              isTagAutoComplete
              name='marketPlaceListing.tags'
              label='Tags'
              placeholder='Tags'
              options={[]}
              getOptionLabel={undefined}
              renderOption={undefined}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option}
                    label={option}
                    size='small'
                    variant='soft'
                  />
                ))
              }
            />
            <RHFTextField
              useSeparators
              name='marketPlaceListing.buyerFeeAmount'
              label={`Buyer's fee, ${DEFAULT_CURRENCY}`}
            />
            <Stack gap={0.5}>
              <RHFCheckbox
                sx={{ m: 0 }}
                name='marketPlaceListing.displayRaiseTarget'
                label='Display Raise Target'
                checkboxProps={{ size: 'medium' }}
              />
              <RHFCheckbox
                sx={{ m: 0 }}
                name='marketPlaceListing.displayEstimatedClosingDate'
                label='Display Estimated Closing Date'
                checkboxProps={{ size: 'medium' }}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );

  const reportDisplay = (
    <Card>
      <CardHeader title='Report Display'/>
      <CardContent>
        <Stack gap={0.5}>
          <RHFCheckbox
            sx={{ m: 0 }}
            name='marketPlaceListing.displayFullReport'
            label='Display Full Report'
            checkboxProps={{ size: 'medium' }}
          />
          <RHFCheckbox
            sx={{ m: 0 }}
            name='marketPlaceListing.displayPmReport'
            label='Display PM Report'
            checkboxProps={{ size: 'medium' }}
          />
          <RHFCheckbox
            sx={{ m: 0 }}
            name='marketPlaceListing.displayLenderReport'
            label='Display Lendor Report'
            checkboxProps={{ size: 'medium' }}
          />
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <Stack gap={3} flex={1}>
      {coverImagesBlock}
      {basicDetails}
      {reportDisplay}
      <ProjectDocuments
        showCheckbox
        onRemoveUploadedFileClickHandler={onRemoveUploadedFileClickHandler}
        modalUploadFileHandler={modalUploadFileHandler}
        uploadedDocuments={values.documents}
        documentsModalList={documentsModalList}
        subTitle='Add documents that will be displayed in the marketplace listing'
        subheader='These documents will be displayed in the marketplace listing'
        checkboxHandler={checkboxOnChangeHandler}
        allowToDownloadDefault={Boolean(values?.marketPlaceListing?.isDocumentDownloadAllowed)}
        onAddDocumentsClickHandler={onAddDocumentsClickHandler}
      />
    </Stack>
  );
};
