import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Stack from '@mui/material/Stack/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { differenceInDays } from 'date-fns';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { fNumber, fPercent } from 'src/utils';

import { MarketplaceOffer } from '../../../../../../types';

const currentDate = new Date();

type OngoingOfferingProps = {
  offering: MarketplaceOffer;
  onOfferingClick: (offering: MarketplaceOffer) => void;
}

export const OngoingOffering = (props: OngoingOfferingProps) => {
  const theme = useTheme();
  const {
    offering,
    onOfferingClick,
  } = props;
  const publishedDate = new Date(offering.published);
  const daysDiff = differenceInDays(currentDate, publishedDate);

  const total = offering.totalRequired || 0;
  const softCommittedPerc = (offering.softCommitted || 0) / total * 100;
  const securedPerc = (offering.secured || 0) / total * 100;

  const handleClick = () => {
    onOfferingClick(offering);
  };

  return (
    <Card sx={{ flex: 1 }}>
      <CardActionArea onClick={handleClick}>
        <CardContent
          sx={{
            px: 2,
            pt: 2.5,
            pb: 2,
            borderBottom: `1px dashed ${theme.palette.divider}`,
          }}
        >
          <Stack direction='row' gap={1.5}>
            <ListItemAvatar sx={{ mr: 0 }}>
              <Avatar
                sx={{
                  borderRadius: 1.5,
                  w: 5,
                  h: 5,
                }}
                alt={offering.name}
                src={offering.logo?.url}
              />
            </ListItemAvatar>
            <Stack gap={0.25}>
              <Typography variant='subtitle1'>{offering.name}</Typography>
              <Typography variant='caption'>{offering.projectType}</Typography>
            </Stack>
            <Chip sx={{ ml: 'auto' }} variant='soft' size='small' label={`${daysDiff}d`}/>
          </Stack>
        </CardContent>
        <CardContent
          sx={{
            px: 2,
            pt: 2,
            pb: 2.5,
          }}
          style={{ paddingBottom: '20px' }}
        >
          <Stack gap={2}>
            <Stack direction='row' gap={1} alignItems='center'>
              <Typography
                sx={{ minWidth: 140 }}
                variant='caption'
                color={theme.palette.text.secondary}
              >
                Soft Commitment
              </Typography>
              <Box sx={{ width: 118 }}>
                <LinearProgress variant='buffer' value={softCommittedPerc} valueBuffer={100}/>
              </Box>
              <Typography style={{ fontWeight: 600 }} variant='caption'>{fPercent(softCommittedPerc)}</Typography>
            </Stack>

            <Stack direction='row' gap={1} alignItems='center'>
              <Typography
                sx={{ minWidth: 140 }}
                variant='caption'
                color={theme.palette.text.secondary}
              >
                Secured Capital
              </Typography>
              <Box sx={{ width: 118 }}>
                <LinearProgress variant='buffer' value={securedPerc} valueBuffer={100}/>
              </Box>
              <Typography style={{ fontWeight: 600 }} variant='caption'>{fPercent(securedPerc)}</Typography>
            </Stack>

            <Stack direction='row' gap={1} alignItems='center'>
              <Typography
                sx={{ minWidth: 140 }}
                variant='caption'
                color={theme.palette.text.secondary}
              >
                Total Required
              </Typography>
              <Typography variant='caption' style={{ fontWeight: 600 }}>{fNumber(total)} {DEFAULT_CURRENCY}</Typography>
            </Stack>

            <Stack direction='row' gap={1} alignItems='center'>
              <Typography
                sx={{ minWidth: 140 }}
                variant='caption'
                color={theme.palette.text.secondary}
              >
                Type
              </Typography>
              <Typography variant='caption' style={{ fontWeight: 600 }}>{offering.investmentType}</Typography>
            </Stack>

            <Stack direction='row' gap={1} alignItems='center'>
              <Typography
                sx={{ minWidth: 140 }}
                variant='caption'
                color={theme.palette.text.secondary}
              >
                Views
              </Typography>
              <Typography variant='caption' style={{ fontWeight: 600 }}>{fNumber(offering.views)}</Typography>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
