import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomSelect, FileModal, getFileConfig, Iconify, SearchField, UploadedFilesType } from 'src/components';
import { sectionsList } from 'src/constants/constants';
import { useBoolean, useDocuments } from 'src/hooks';
import { RootState } from 'src/store';
import { fNumber, openInNewTab } from 'src/utils';

import { FinanceUploadFileType } from '../../../../../types';

type FilesProps = {
  documentsList?: UploadedFilesType[];
  setDocumentsModalList?: Dispatch<SetStateAction<UploadedFilesType[]>>;
  alreadySelectedDocuments?: UploadedFilesType[];
  updateSelectedDocuments?: Dispatch<SetStateAction<UploadedFilesType[]>>
}

export const Files = (props: FilesProps) => {
  const {
    documentsList = [],
    alreadySelectedDocuments = [],
    setDocumentsModalList,
    updateSelectedDocuments,
  } = props;
  const theme = useTheme();
  const uploadModal = useBoolean();
  const [search, setSearch] = useState<string>('');
  const [section, setSection] = useState<string>('');

  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const { createAndUploadDocument } = useDocuments();

  const selectedIds = alreadySelectedDocuments.map(doc => doc?._id);

  const toggleCheckbox = (file: UploadedFilesType) => () => {
    const selected = selectedIds.includes(file._id);
    if (selected) {
      const selectedDocuments = alreadySelectedDocuments.filter(doc => doc._id !== file._id);
      updateSelectedDocuments?.(selectedDocuments);
    } else {
      const selectedDocuments = [...alreadySelectedDocuments, file];
      updateSelectedDocuments?.(selectedDocuments);
    }
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const onSectionChange = (e: SelectChangeEvent<string[]>) => {
    const v = e.target.value;
    if (!v || !v.length) {
      setSection('');
    } else {
      setSection(v as string);
    }
  };

  const filteredDocs: UploadedFilesType[] = useMemo(() => {
    if (!search && !section) return documentsList;
    const searchLow = search.trim().toLowerCase();

    const bySearch = searchLow ? documentsList.filter((doc) => doc.filename?.trim().toLowerCase().includes(searchLow)) : documentsList;

    return section ? bySearch.filter((doc) => doc.section === section) : bySearch;
  }, [documentsList, search, section]);

  const closUploadModal = () => {
    uploadModal.onFalse();
  };

  const modalUploadFileHandler = async (file: FinanceUploadFileType) => {
    const uploadedFile = await createAndUploadDocument(file, detailsData.project?._id, detailsData.lead?._id);
    if (uploadedFile) {
      setDocumentsModalList?.([...documentsList, {
        ...uploadedFile,
        size: Math.round((uploadedFile.size || 0) / 1024),
        filename: uploadedFile.sectionFilename || uploadedFile.filename,
      }]);
    }
  };

  const openFileModal = () => {
    uploadModal.onTrue();
  };

  const onFileClickHandler = (file: UploadedFilesType) => (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    if (target.tagName.toLowerCase() === 'input') {
      return;
    }
    openInNewTab(file.url);
  };

  return (
    <Stack gap={4}>
      <Stack direction='row' gap={1.5}>
        <Box sx={{ flex: 1 }}>
          <CustomSelect
            controlSx={{ width: '100%' }}
            value={section ? [section] : []}
            handleSelectOnChange={onSectionChange}
            label='Section'
            options={sectionsList}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <SearchField
            value={search}
            inputSx={{ width: 204 }}
            handleFieldOnChange={onSearchChange}
            isFullWidth
          />
        </Box>
        <Button variant='outlined' onClick={openFileModal}>
          <Iconify icon='eva:cloud-upload-fill'/>
        </Button>
      </Stack>
      <Stack gap={3}>
        {filteredDocs.map(doc =>
          <Stack
            key={doc._id}
            gap={1}
            direction='row'
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={onFileClickHandler(doc)}
          >
            <Checkbox
              checked={selectedIds.includes(doc._id)}
              onChange={toggleCheckbox(doc)}
              size='medium'
              color='primary'
            />
            {getFileConfig(doc.filetype).icon}
            <Stack gap={0.5}>
              <Typography variant='subtitle2'>
                {doc.filename}
              </Typography>
              <Typography
                variant='caption'
                color={theme.palette.text.disabled}
              >
                {doc.section}
              </Typography>
            </Stack>
            {doc.size &&
              <Typography
                variant='subtitle2'
                sx={{
                  ml: 'auto',
                  textAlign: 'right',
                }}
              >
                {fNumber(doc.size)} KB
              </Typography>
            }
          </Stack>,
        )}
      </Stack>
      <FileModal
        openModal={uploadModal.value}
        modalUploadFileHandler={modalUploadFileHandler}
        onClose={closUploadModal}
      />
    </Stack>
  );
};
