import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { CardBarFiltersChart, ChartWithFiltersType, SeriesWithFiltersType } from 'src/components';
import { monthsShortLabels } from 'src/constants/constants';
import { fDate } from 'src/utils';

import { FundingDataType } from '../../../../../../types';

type FundingActivityProps = {
  chartData?: FundingDataType
  title?: string;
  showAdditionalFilter?: boolean;
  additionalSeriesValues?: string[];
  customAdditionalSeriesValue?: string;
  updateCustomAdditionalSeriesValue?: (value: string) => void;
}

export const FundingActivity = (props: FundingActivityProps) => {
  const theme = useTheme();
  const {
    chartData,
    title = 'Funding Activity',
    showAdditionalFilter,
    additionalSeriesValues,
    updateCustomAdditionalSeriesValue,
    customAdditionalSeriesValue,
  } = props;

  const generatedMockDataForChart: ChartWithFiltersType = useMemo(() => {
    const series: SeriesWithFiltersType[] = [
      {
        seriesFilter: 'Week',
        categories: chartData?.week.date || [],
        data: [
          {
            name: 'Soft Committed',
            data: chartData?.week.softCommitted || [],
          },
          {
            name: 'Capital Secured',
            data: chartData?.week.secured || [],
          },
        ],
      },
      {
        seriesFilter: 'Month',
        categories: chartData?.month.date?.map(date => fDate(date, 'dd')) || [],
        xAsisTitle: chartData?.month.date?.map(date => fDate(date, 'MMMM'))[0],
        data: [
          {
            name: 'Soft Committed',
            data: chartData?.month.softCommitted || [],
          },
          {
            name: 'Capital Secured',
            data: chartData?.month.secured || [],
          },
        ],
      },
      {
        seriesFilter: 'Year',
        categories: monthsShortLabels,
        data: [
          {
            name: 'Soft Committed',
            data: chartData?.year.softCommitted || [],
          },
          {
            name: 'Capital Secured',
            data: chartData?.year.secured || [],
          },
        ],
      },
    ];

    return {
      categories: monthsShortLabels,
      colors: [theme.palette.primary.lighter, theme.palette.primary.main],
      series: series,
    };
  }, [chartData?.month.date, chartData?.month.secured, chartData?.month.softCommitted, chartData?.week.date, chartData?.week.secured, chartData?.week.softCommitted, chartData?.year.secured, chartData?.year.softCommitted, theme.palette.primary.lighter, theme.palette.primary.main]);

  return (
    <CardBarFiltersChart
      customAdditionalSeriesValue={customAdditionalSeriesValue}
      updateCustomAdditionalSeriesValue={updateCustomAdditionalSeriesValue}
      showAdditionalFilter={showAdditionalFilter}
      additionalSeriesValues={additionalSeriesValues}
      cardProps={{
        sx: {
          flex: 1,
          height: '100%',
        },
      }}
      title={title}
      seriesValues={['Week', 'Month', 'Year']}
      chart={generatedMockDataForChart}
    />
  );
};
