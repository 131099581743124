import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { DrawerCustom, FormProvider, RHFTextField } from 'src/components';
import { _projectTypeList } from 'src/features/projects-offering-page';
import { UseBooleanReturnType } from 'src/hooks';
import * as Yup from 'yup';

import { PreliminaryQuotationRequestFormType } from './preliminary-quotation-request';

type EditDrawerProps = {
  editDrawer: UseBooleanReturnType;
};

type EditDrawerPreliminaryQuotationRequestFormType = Pick<PreliminaryQuotationRequestFormType,
'projectType' |
'location' |
'totalBoa' |
'buildTime' |
'constructionCostAmount' |
'unitTypeText'
>

export const EditDrawer = (props: EditDrawerProps) => {
  const { editDrawer } = props;

  const formContext = useFormContext<PreliminaryQuotationRequestFormType>();
  const quotationDetails = formContext.getValues();

  const EditSchema: Yup.ObjectSchema<EditDrawerPreliminaryQuotationRequestFormType> = Yup.object().shape({
    projectType: Yup.string().oneOf(_projectTypeList),
    location: Yup.string(),
    totalBoa: Yup.string(),
    buildTime: Yup.number(),
    constructionCostAmount: Yup.string(),
    unitTypeText: Yup.string(),
  });

  const defaultValues: EditDrawerPreliminaryQuotationRequestFormType = useMemo(() => ({
    projectType: quotationDetails.projectType,
    location: quotationDetails.location,
    totalBoa: quotationDetails.totalBoa,
    buildTime: quotationDetails.buildTime,
    constructionCostAmount: quotationDetails.constructionCostAmount,
    unitTypeText: quotationDetails.unitTypeText,
  }), [quotationDetails.buildTime, quotationDetails.constructionCostAmount, quotationDetails.location, quotationDetails.projectType, quotationDetails.totalBoa, quotationDetails.unitTypeText]);

  const methods = useForm<EditDrawerPreliminaryQuotationRequestFormType>({
    resolver: yupResolver(EditSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (quotationDetails.projectId) {
      reset(defaultValues);
    }
  }, [defaultValues, quotationDetails.projectId, reset]);

  const onCloseDrawerHandler = () => {
    editDrawer.onFalse();
    reset(defaultValues);
  };

  const onSubmit = handleSubmit(async (data) => {
    const {
      projectType,
      location,
      totalBoa,
      buildTime,
      constructionCostAmount,
      unitTypeText,
    } = data;

    formContext.setValue('location', location);
    formContext.setValue('totalBoa', totalBoa);
    formContext.setValue('buildTime', buildTime);
    formContext.setValue('constructionCostAmount', constructionCostAmount);
    formContext.setValue('unitTypeText', unitTypeText);
    formContext.setValue('projectType', projectType);
    onCloseDrawerHandler();
  });

  return (
    <DrawerCustom
      open={editDrawer.value}
      onCloseDrawerHandler={onCloseDrawerHandler}
      headChildren={
        <Typography variant='h6'>Edit Preliminary Quotation</Typography>
      }
      bodyChildren={
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack height='100%' justifyContent='space-between'>
            <Stack gap={3}>
              <RHFTextField
                select
                name='projectType'
                id='project-type-select'
                label='Project Type *'
              >
                {_projectTypeList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </RHFTextField>
              <RHFTextField
                name='location'
                label='Location'
              />
              <RHFTextField
                useSeparators
                name='totalBoa'
                label='Total BOA'
              />
              <RHFTextField
                type='number'
                name='buildTime'
                label='Build time'
              />
              <RHFTextField
                useSeparators
                name='constructionCostAmount'
                label='Construction cost'
              />
              <RHFTextField
                multiline
                rows={3}
                name='unitTypeText'
                label='Unit type'
              />
            </Stack>
            <LoadingButton
              fullWidth
              type='button'
              variant='outlined'
              size='large'
              loading={isSubmitting}
              onClick={onSubmit}
            >
              Save Changes
            </LoadingButton>
          </Stack>
        </FormProvider>
      }
      bodyChildrenSx={{ height: '100%' }}
    />

  );
};
