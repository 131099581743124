import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { OverviewPhotos } from 'src/features/investor-portal/projects/project-details/overview/components/overview-photos';
import { RootState } from 'src/store';

import { AssigneeType, DebtDetailsType, ReportKeyType, ReportSettingsType } from '../../../../../../../types';
import { OverviewButtons, OverviewButtonsProps } from './overview-buttons';
import { OverviewInfoAbout } from './overview-info-about';
import { OverviewSidebar } from './overview-sidebar';

export type OverviewInfoProps = OverviewButtonsProps & {
  type: ReportKeyType;
  detailsList?: DebtDetailsType;
  isSettingExist?: ReportSettingsType;
  projectManager?: AssigneeType;
}

export const OverviewInfo = (props: OverviewInfoProps) => {
  const {
    type,
    openDocumentsClickHandler,
    openDetailedProjectClickHandler,
    detailsList,
    isSettingExist,
    projectManager,
  } = props;

  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const assets = detailsData?.assets;
  const photos = assets?.marketPlaceListing?.coverImages;

  const isBank = type === 'bank';
  const isSale = type === 'seller';
  const showOverviewButtons = isSale || isBank;
  const isGalleryActive = isSettingExist?.sections?.find(section => section.section === 'Gallery');

  return (
    <Stack direction='row' gap={5} flexWrap='wrap'>
      <Stack gap={3} sx={{ flex: 1 }}>
        {isGalleryActive?.isActive &&
          <OverviewPhotos photos={photos}/>
        }
        <OverviewInfoAbout
          type={type}
          detailsList={detailsList}
          isSettingExist={isSettingExist}
        />
        {showOverviewButtons &&
          <OverviewButtons
            openDocumentsClickHandler={openDocumentsClickHandler}
            openDetailedProjectClickHandler={openDetailedProjectClickHandler}
          />
        }
      </Stack>
      <OverviewSidebar
        projectManager={projectManager}
        type={type}
      />
    </Stack>
  );
};
