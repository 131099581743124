import { identity, omit, pickBy } from 'lodash';
import { demoPartnersBanks, demoPartnersEquityPartners, demoPartnersSuppliers } from 'src/constants/demo-mock-data';

import { CoverImagesType, PartnerItemKeys, PartnerItemType, ProductType, SupplierProductType } from '../../../../types';
import { apiFetch } from '../api';
import { setIsLoading } from '../storage';
import { AppThunk } from '../store';
import { addPartner, addProduct, deletePartner, deleteProduct, setCurrentPartner, setCurrentProduct, setPartners, updateCurrentPartner, updateCurrentProduct } from './index';
import { GetProductsResponse, LoadProductsParams, PartnerVariantType } from './types';

export const getPartners = (partnerVariant: PartnerVariantType, storeKey: PartnerItemKeys): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    dispatch(setPartners({
      partnerKey: 'banks',
      partners: demoPartnersBanks,
    }));
    dispatch(setPartners({
      partnerKey: 'suppliers',
      partners: demoPartnersSuppliers,
    }));
    dispatch(setPartners({
      partnerKey: 'equityPartners',
      partners: demoPartnersEquityPartners,
    }));
    return;
  }

  try {
    dispatch(setIsLoading(true));
    const partnersList: PartnerItemType[] = await apiFetch(`partner/list/${partnerVariant}`, { method: 'get' });
    if (!partnersList) {
      throw new Error('Can not get partners');
    }

    dispatch(setPartners({
      partnerKey: storeKey,
      partners: partnersList,
    }));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setPartners({
      partnerKey: storeKey,
      partners: [],
    }));
    dispatch(setIsLoading(false));
  }
};

export const deletePartnerThunk = (partnerId: string, storeKey: PartnerItemKeys): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    dispatch(deletePartner({
      partnerId,
      partnerKey: storeKey,
    }));
    return;
  }

  try {
    const response = await apiFetch(`partner/delete/${partnerId}`, { method: 'delete' });
    if (!response) {
      throw new Error('Can not delete partner');
    }
    dispatch(deletePartner({
      partnerId,
      partnerKey: storeKey,
    }));
  } catch (error) {
    console.error(error);
  }
};

export const getPartnerDetail = (partnerId: string): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    const supplier = demoPartnersSuppliers.find(supplier => supplier._id === partnerId);
    const equityPartner = demoPartnersEquityPartners.find(partner => partner._id === partnerId);
    const bank = demoPartnersBanks.find(bank => bank._id === partnerId);
    dispatch(setCurrentPartner(supplier || equityPartner || bank || demoPartnersSuppliers[0]));
    return;
  }

  try {
    dispatch(setIsLoading(true));
    const response = await apiFetch(`partner/details/${partnerId}`, { method: 'get' });
    if (!response) {
      throw new Error('Can not get partner details');
    }
    dispatch(setCurrentPartner(response));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    console.error(error);
  }
};

export const createPartner = (partner: Partial<PartnerItemType>, storeKey?: PartnerItemKeys, callBack?: (partner: PartnerItemType) => void): AppThunk => async (dispatch) => {
  const filteredPartner = pickBy(partner, identity);
  const updatedBody = omit(filteredPartner, ['logo', 'logoUrl']);

  try {
    const response: { partnerId: string } = await apiFetch(`partner${partner._id ? `/${partner._id}` : ''}`, {
      method: 'post',
      body: JSON.stringify({ ...updatedBody }),
      headers: { 'Content-Type': 'application/json' },
    });
    if (!response) {
      throw new Error('Can not delete partner');
    }
    const updatedPartner: PartnerItemType = {
      ...updatedBody,
      _id: response.partnerId,
    };

    callBack?.(updatedPartner);

    if (!storeKey) return;

    dispatch(updateCurrentPartner({
      partner: updatedPartner,
      partnerKey: storeKey,
    }));
    if (!partner._id) {
      dispatch(addPartner({
        partnerKey: storeKey,
        newPartner: updatedPartner,
      }));
    }

    if (filteredPartner.logo) {
      dispatch(uploadPartnerImg({
        ...filteredPartner,
        _id: response.partnerId,
      }, storeKey));
    }
  } catch (error) {
    console.error(error);
  }
};

export const uploadPartnerImg = (partner: PartnerItemType, storeKey: PartnerItemKeys): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    return;
  }
  try {
    const response = await apiFetch(`partner/upload-logo/${partner._id}`, {
      method: 'post',
      body: partner.logo,
      headers: { 'Content-Type': partner.logo?.type || 'image/png' },
    });
    if (!response) {
      throw new Error('Can not upload logo');
    }
    const updatedPartner = omit({
      ...partner,
      ...response,
    }, 'logo');
    dispatch(updateCurrentPartner({
      partner: updatedPartner,
      partnerKey: storeKey,
    }));
  } catch (error) {
    console.error(error);
  }
};

export const createProduct = (partnerId: string, data: Partial<SupplierProductType>, callBack?: VoidFunction): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    data._id
      ? dispatch(updateCurrentProduct(data))
      : dispatch(addProduct({
        partnerId,
        product: {
          ...data,
          _id: new Date().toString(),
        },
      }));
    return;
  }

  try {
    const updatedData = {
      ...omit(data, ['images', '_id', 'coverImage', 'imagesToDelete']),
      partnerId: partnerId,
    };

    if (data.roofAngle || data.roofAngle === 0) {
      updatedData.roofAngle = String(data.roofAngle);
    }

    const res: { productId: string } = await apiFetch(`partner/product/create${data._id ? `/${data._id}` : ''}`, {
      method: 'post',
      body: JSON.stringify(updatedData),
      headers: { 'Content-Type': 'application/json' },
    });
    const productId = res.productId;
    if (productId) {
      const files = [];
      for (let index = 0; index < (data.images || []).length; index++) {
        const image = data.images?.[index];
        if (image?.file) {
          const uploadedFile = await uploadProductCoverImage(productId, image, index + 1, isDemo);
          files.push(uploadedFile);
        } else {
          files.push(image);
        }
      }
      for (const image of data.imagesToDelete || []) {
        deleteProductCoverImage(image._id, isDemo);
      }
      const coverImage = files.find(file => file?.isCoverImage);
      coverImage && await setProductCoverImageAsDefault(coverImage._id, isDemo);
    }


    data._id
      ? dispatch(updateCurrentProduct(data))
      : dispatch(addProduct({
        partnerId,
        product: {
          ...data,
          _id: productId,
        },
      }));
    callBack?.();
  } catch (error) {
    console.error(error);
  }
};

export const deleteProductThunk = (partnerId: string, productId: string): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    dispatch(deleteProduct({
      partnerId,
      productId,
    }));
    return;
  }
  await apiFetch(`partner/product/delete/${productId}`, {
    method: 'delete',
    headers: { 'Content-Type': 'application/json' },
  });
  try {
    dispatch(deleteProduct({
      partnerId,
      productId,
    }));

  } catch (error) {
    console.error(error);
  }
};

export const loadProducts = (params: LoadProductsParams): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;

  if (isDemo) {
    dispatch(setCurrentPartner({
      products: params.demoProducts,
      productAmount: params.demoProducts?.length,
      ...params.demoPartners[0],
    }));
    return;
  }

  try {
    const clearedParams = omit(params, ['demoProducts', 'demoPartners']);
    const response: GetProductsResponse = await apiFetch('partner/product/list', {
      method: 'post',
      body: JSON.stringify(clearedParams),
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch(setCurrentPartner({
      products: response.products,
      productAmount: response.products?.length,
      netCount: response.netCount,
      ...response.partner,
    }));

  } catch (error) {
    console.error(error);
  }
};

export const loadProductDetails = (productId: string, demoProducts: ProductType[]): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;

  if (isDemo) {
    const productInStore = getState().Partners.currentPartner?.products?.find(product => product._id === productId);
    const productInDemo = demoProducts?.find(product => product._id === productId);
    dispatch(setCurrentProduct({ _id: new Date().toString(), ...(productInStore || productInDemo) }));
    return;
  }

  try {
    const response: ProductType = await apiFetch(`partner/product/details/${productId}`, { method: 'get' });
    dispatch(setCurrentProduct(response));

  } catch (error) {
    console.error(error);
  }
};

export const uploadProductCoverImage = async (productId: string, file: CoverImagesType, index: number, isDemo?: boolean) => {
  if (isDemo) {
    return {
      ...file,
      _id: new Date().toISOString(),
    };
  }

  try {
    const response: {
      _id: string
    } = await apiFetch(`partner/product/cover-image/${productId}/${file.isCoverImage}/${index}`, {
      method: 'post',
      headers: { 'Content-Type': file.file?.type || file.filetype || 'image/png' },
      body: file.file,
    });
    return {
      ...file,
      _id: response._id,
    };
  } catch (error) {
    console.error(error);
  }
};

export const setProductCoverImageAsDefault = async (imagerId: string, isDemo?: boolean) => {
  if (isDemo) {
    return;
  }

  try {
    await apiFetch(`partner/product/cover-image-default/${imagerId}/`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
    });
    return;
  } catch (error) {
    console.error(error);
  }
};

export const deleteProductCoverImage = async (imagerId: string, isDemo?: boolean) => {
  if (isDemo) {
    return;
  }

  try {
    await apiFetch(`partner/product/cover-image-delete/${imagerId}/`, {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
    });
    return;
  } catch (error) {
    console.error(error);
  }
};
