import {
  AssetSections,
  BankProductType,
  CountryCode,
  DetailViewData,
  FilesTable,
  Lead,
  PartnerItemType,
  Project,
  Research,
  Scenario,
  SupplierProductType,
  User,
} from '../../../types';

export const demoLeads: Lead[] = [
  {
    _id: '664f3db31d581f02fe9f287b',
    location: {
      address: 'Västergarn och stora mafrids, Gotland, Sweden',
      coordinates: [0, 0],
    },
    source: 'Scraping',
    status: 'Waiting for Review',
    plotArea: 2340,
    price: 9_000_000,
    totalFloorArea: 3464,
    createdAt: new Date('2024-12-02').toISOString(),
  },
  {
    _id: '2',
    location: {
      address: 'Spadvägen, Heby, Sweden',
      coordinates: [0, 0],
    },
    source: 'Scraping',
    status: 'Waiting for Review',
    plotArea: 3868,
    price: 11_000_000,
    totalFloorArea: 8781,
    createdAt: new Date('2024-12-02').toISOString(),
  },
  {
    _id: '3',
    location: {
      address: 'Kumla Trädgårdsgata, Uppsala, Sweden',
      coordinates: [0, 0],
    },
    source: 'Manual',
    status: 'In Progress',
    plotArea: 1782,
    price: 4_200_000,
    totalFloorArea: 3018,
    createdAt: new Date('2024-12-02').toISOString(),
  },
  {
    _id: '4',
    location: {
      address: 'Herrhamra, Nynäshamn, Sweden',
      coordinates: [0, 0],
    },
    source: 'Manual',
    status: 'Rejected',
    plotArea: 568,
    price: 1_200_000,
    totalFloorArea: 1068,
    createdAt: new Date('2024-12-02').toISOString(),
  },
  {
    _id: '5',
    location: {
      address: 'Ösmo, Sweden',
      coordinates: [0, 0],
    },
    source: 'Manual',
    status: 'Approved',
    plotArea: 2868,
    price: 2_900_000,
    totalFloorArea: 6044,
    createdAt: new Date('2024-12-02').toISOString(),
  },
];

export const demoScenario: Scenario = {
  '_id': '664f3df21d581f02fe9f2898',
  'projectId': '664f3df11d581f02fe9f288e',
  createdAt: '23 Dec 2023',
  name: 'Ella Park - Täby',
  status: 'In Progress',
  'calculate': {
    'input': {
      'build_time_months': 8,
      'buyer_type': '',
      'completion_date': '2025-01-31',
      'construction_costs_array': [
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Exterior walls',
          'payment_start': '2024-05-31',
          'payment_type': 'Monthly',
          'total_cost': 2_451_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Frame completion',
          'payment_start': '2024-05-31',
          'payment_type': 'Monthly',
          'total_cost': 8_124_600,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Interior walls',
          'payment_start': '2024-05-31',
          'payment_type': 'Monthly',
          'total_cost': 1_480_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Windows',
          'payment_start': '2024-05-31',
          'payment_type': 'Monthly',
          'total_cost': 2_760_600,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Roof trusses',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 4_337_200,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Plate',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 2_985_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Plastering',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 2_468_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Interior doors',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 559_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Ceramics',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 2_195_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Floor',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 1_652_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Painting',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 2_440_200,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Kitchen',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 4_653_500,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Furniture',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 1_870_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Appliances',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 2_581_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Electricity',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 2_460_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Plumbing',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 3_410_650,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Elevator',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 3_255_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Commissioning',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 500_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'Heating and ventilation',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 2_291_000,
        },
        {
          'construction_cost_category': 'Construction',
          'cost_type': 'VAT',
          'payment_start': '2024-03-31',
          'payment_type': 'Monthly',
          'total_cost': 14_670_000,
        },
        {
          'construction_cost_category': 'Pre-construction',
          'cost_type': '',
          'payment_start': '2024-03-31',
          'payment_type': '',
        },
        {
          'construction_cost_category': 'Professional fees',
          'cost_type': 'Property cost',
          'payment_start': '2024-03-31',
          'payment_type': 'Single',
          'total_cost': 2_250_000,
        },
        {
          'construction_cost_category': 'Professional fees',
          'cost_type': 'Builders cost',
          'payment_start': '2024-03-31',
          'payment_type': 'Single',
          'total_cost': 2_100_000,
        },
      ],
      'construction_type': '',
      'contingency': 0,
      'contingency_array': [],
      'contract_deposit': 0,
      'contract_type': '',
      'country': 'SWE',
      'finish_type': '',
      'funding_array': [
        {
          'drawdowns': 'single',
          'fees': 3,
          'interest': 11,
          'percentage': 70,
          'profit_share': 0,
          'ratio': 'LTC',
          'repayment_type': 'single',
          'rolled_up_interest': true,
          'section': 'Debt funding',
          'security': 'First Charge',
          'type': 'Senior debt',
        },
        {
          'drawdowns': 'single',
          'fees': 0,
          'interest': 0,
          'percentage': 30,
          'profit_share': 90,
          'ratio': 'LTC',
          'repayment_type': 'single',
          'rolled_up_interest': false,
          'section': 'Equity',
          'security': '',
          'type': 'Developer\'s equity',
        },
        {
          'drawdowns': '',
          'fees': 0,
          'percentage': 0,
          'profit_share': 10,
          'ratio': 'LTC',
          'repayment_type': '',
          'rolled_up_interest': false,
          'section': 'Equity',
          'security': '',
          'type': 'JV Equity',
        },
      ],
      'gdv': 173_023_471,
      'nia/gea': 0.78,
      'nia/gea_cost_ratio': 0.48,
      'number_of_units': 30,
      'payment_type': '',
      'planning_application_url': '',
      'planning_status': '',
      'project_name': 'Ella Park - Täby',
      'project_purchase_construction': 15,
      'project_start_date': '2024-03-31',
      'project_start_purchase': 45,
      'purchase_costs_array': [
        {
          'cost_type': 'Solicitor',
          'payment': '2024-05-31',
          'total_cost': 0,
        },
        {
          'cost_type': 'Surveyor',
          'payment': '2024-05-31',
          'total_cost': 30_000,
        },
        {
          'cost_type': 'Stamp duty',
          'payment': '2024-05-31',
          'total_cost': 0,
        },
      ],
      'sales_complete': '2025-05-31',
      'sales_costs_array': [
        {
          'cost_type': 'Estate Agent',
          'payment': '2025-01-31',
          'total_cost': 1_000_000,
        },
      ],
      'sales_strategy': 'On completion',
      'show_home_unit': '',
      'site_address': 'Turebergsvägen 31, Täby, Sverige',
      'site_purchase_price': 55_000_000,
      'site_type': '',
      'stamp_duty_type': '',
      'start_of_sales': '',
      'total_construction_cost': 71_493_750,
      'total_of_loans': '',
      'total_sales_cost': 1_000_000,
      'type_of_project': 'New build apartments',
      'units_array': [
        {
          'bedroom_no': 1,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 1_989_972,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 1,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 2_089_980,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 1,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 2_149_992,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 2,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 2_789_996,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 2,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 3_350_004,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 2,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 3_389_961,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 2,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 3_489_996,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 2,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 2_789_996,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 3,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 4_449_984,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 3,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 4_650_000,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 3,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 4_649_940,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 3,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 4_750_000,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 3,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 4_749_954,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 3,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 4_449_984,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 3,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 4_650_000,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 3,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 4_649_940,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 3,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 4_750_000,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 3,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 4_749_954,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 2,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 3_350_004,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 4,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 7_950_000,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 4,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 6_150_000,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 4,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 5_950_000,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 4,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 6_090_000,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 4,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 5_949_966,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 4,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 7_950_000,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 5,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 6_889_995,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 5,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 6_889_995,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 5,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 6_949_910,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 5,
          'finish_type': 'N/A',
          'number': 1,
          'price_per_unit': 7_449_948,
          'property_type': 'Apartment',
          'size': 0,
          'type': 'residential',
          'unit_name': '',
        },
        {
          'bedroom_no': 0,
          'finish_type': '',
          'number': 1,
          'price_per_unit': 32_914_000,
          'property_type': 'BRF Loan (Sweden only)',
          'size': 2351,
          'type': 'other',
          'unit_name': '',
        },
      ],
    },
    'output': {
      '% profit for developer': 90,
      'Agent fees': 1_000_000,
      'Average price per unit': 5_767_449.033_333_333,
      'Budget summary': {
        '0 - Construction - Exterior walls': [
          2_451_000,
          3.428_271_702_071_86,
          1.788_513_701_745_128_2,
        ],
        '1 - Construction - Frame completion': [
          8_124_599.999_999_999,
          11.364_070_285_864_148,
          5.928_583_607_180_117_5,
        ],
        '10 - Construction - Painting': [
          2_440_200,
          3.413_165_486_493_574_5,
          1.780_632_858_016_508_5,
        ],
        '11 - Construction - Kitchen': [
          4_653_500,
          6.508_960_573_476_702,
          3.395_695_026_956_734,
        ],
        '12 - Construction - Furniture': [
          1_870_000,
          2.615_613_252_906_722_4,
          1.364_553_497_455_483_6,
        ],
        '13 - Construction - Appliances': [
          2_581_000,
          3.610_105_778_477_14,
          1.883_375_709_589_627_3,
        ],
        '14 - Construction - Electricity': [
          2_460_000,
          3.440_860_215_053_763_5,
          1.795_081_071_518_978_4,
        ],
        '15 - Construction - Plumbing': [
          3_410_649.999_999_999_5,
          4.770_556_866_858_99,
          2.488_777_746_575_692,
        ],
        '16 - Construction - Elevator': [
          3_254_999.999_999_999_5,
          4.552_845_528_455_284_5,
          2.375_198_734_875_721,
        ],
        '17 - Construction - Commissioning': [
          500_000,
          0.699_361_832_328_000_6,
          0.364_853_876_324_995_6,
        ],
        '18 - Construction - Heating and ventilation': [
          2_291_000,
          3.204_475_915_726_899_6,
          1.671_760_461_321_129_9,
        ],
        '19 - Construction - VAT': [
          14_670_000,
          20.519_276_160_503_54,
          10.704_812_731_375_371,
        ],
        '2 - Construction - Interior walls': [
          1_480_000.000_000_000_2,
          2.070_111_023_690_882_4,
          1.079_967_473_921_987,
        ],
        '21 - Professional fees - Property cost': [
          2_250_000,
          3.147_128_245_476_003,
          1.641_842_443_462_480_3,
        ],
        '22 - Professional fees - Builders cost': [
          2_100_000,
          2.937_319_695_777_603,
          1.532_386_280_564_981_5,
        ],
        '3 - Construction - Windows': [
          2_760_600,
          3.861_316_548_649_357_5,
          2.014_431_221_965_565_6,
        ],
        '4 - Construction - Roof trusses': [
          4_337_200,
          6.066_544_278_346_01,
          3.164_888_464_793_542,
        ],
        '5 - Construction - Plate': [
          2_985_000,
          4.175_190_138_998_165,
          2.178_177_641_660_224,
        ],
        '6 - Construction - Plastering': [
          2_468_000,
          3.452_050_004_371_011_4,
          1.800_918_733_540_178_2,
        ],
        '7 - Construction - Interior doors': [
          559_000,
          0.781_886_528_542_704_8,
          0.407_906_633_731_345_06,
        ],
        '8 - Construction - Ceramics': [
          2_195_000.000_000_000_5,
          3.070_198_443_919_924,
          1.601_708_517_066_731_1,
        ],
        '9 - Construction - Floor': [
          1_651_999.999_999_999_8,
          2.310_691_494_011_714,
          1.205_477_207_377_785_3,
        ],
        'Developer\'s equity costs': [
          0,
          0,
          0,
        ],
        'JV Equity costs': [
          0,
          0,
          0,
        ],
        'Purchase cost - Solicitor': [
          0,
          0,
          0,
        ],
        'Purchase cost - Stamp duty': [
          0,
          0,
          0,
        ],
        'Purchase cost - Surveyor': [
          30_000,
          0.041_961_709_939_680_04,
          0.021_891_232_579_499_736,
        ],
        'Sales cost - Estate Agent': [
          1_000_000,
          1.398_723_664_656_001_3,
          0.729_707_752_649_991_2,
        ],
        'Senior debt costs': [
          9_517_414.27,
          13.312_232_565_783_722,
          6.944_930_978_000_656,
        ],
        'Total construction costs': [
          71_493_750,
          100,
          52.169_543_641_020_31,
        ],
        'Total costs': [
          137_041_164.270_000_04,
          191.682_719_496_459_55,
          100,
        ],
        'Total finance costs': [
          9_517_414.27,
          13.312_232_565_783_722,
          6.944_930_978_000_656,
        ],
        'Total non finance costs': [
          127_523_750.000_000_04,
          178.370_486_930_675_84,
          93.055_069_021_999_34,
        ],
        'Total purchase costs': [
          55_030_000,
          76.971_763_266_019_75,
          40.155_817_628_329_02,
        ],
        'Total sales costs': [
          1_000_000,
          1.398_723_664_656_001_3,
          0.729_707_752_649_991_2,
        ],
        'Unit sales': [
          173_023_471,
          242.012_023_428_621_38,
          126.256_568_179_110_93,
        ],
        'land_purchase': [
          55_000_000,
          76.929_801_556_080_08,
          40.133_926_395_749_51,
        ],
      },
      'Budget summary v2': {
        '0 - Construction - Exterior walls': {
          'items': [
            [
              2_451_000,
              3.428_271_702_071_86,
              1.788_513_701_745_128_2,
            ],
          ],
        },
        '1 - Construction - Frame completion': {
          'items': [
            [
              8_124_599.999_999_999,
              11.364_070_285_864_148,
              5.928_583_607_180_117_5,
            ],
          ],
        },
        '10 - Construction - Painting': {
          'items': [
            [
              2_440_200,
              3.413_165_486_493_574_5,
              1.780_632_858_016_508_5,
            ],
          ],
        },
        '11 - Construction - Kitchen': {
          'items': [
            [
              4_653_500,
              6.508_960_573_476_702,
              3.395_695_026_956_734,
            ],
          ],
        },
        '12 - Construction - Furniture': {
          'items': [
            [
              1_870_000,
              2.615_613_252_906_722_4,
              1.364_553_497_455_483_6,
            ],
          ],
        },
        '13 - Construction - Appliances': {
          'items': [
            [
              2_581_000,
              3.610_105_778_477_14,
              1.883_375_709_589_627_3,
            ],
          ],
        },
        '14 - Construction - Electricity': {
          'items': [
            [
              2_460_000,
              3.440_860_215_053_763_5,
              1.795_081_071_518_978_4,
            ],
          ],
        },
        '15 - Construction - Plumbing': {
          'items': [
            [
              3_410_649.999_999_999_5,
              4.770_556_866_858_99,
              2.488_777_746_575_692,
            ],
          ],
        },
        '16 - Construction - Elevator': {
          'items': [
            [
              3_254_999.999_999_999_5,
              4.552_845_528_455_284_5,
              2.375_198_734_875_721,
            ],
          ],
        },
        '17 - Construction - Commissioning': {
          'items': [
            [
              500_000,
              0.699_361_832_328_000_6,
              0.364_853_876_324_995_6,
            ],
          ],
        },
        '18 - Construction - Heating and ventilation': {
          'items': [
            [
              2_291_000,
              3.204_475_915_726_899_6,
              1.671_760_461_321_129_9,
            ],
          ],
        },
        '19 - Construction - VAT': {
          'items': [
            [
              14_670_000,
              20.519_276_160_503_54,
              10.704_812_731_375_371,
            ],
          ],
        },
        '2 - Construction - Interior walls': {
          'items': [
            [
              1_480_000.000_000_000_2,
              2.070_111_023_690_882_4,
              1.079_967_473_921_987,
            ],
          ],
        },
        '21 - Professional fees - Property cost': {
          'items': [
            [
              2_250_000,
              3.147_128_245_476_003,
              1.641_842_443_462_480_3,
            ],
          ],
        },
        '22 - Professional fees - Builders cost': {
          'items': [
            [
              2_100_000,
              2.937_319_695_777_603,
              1.532_386_280_564_981_5,
            ],
          ],
        },
        '3 - Construction - Windows': {
          'items': [
            [
              2_760_600,
              3.861_316_548_649_357_5,
              2.014_431_221_965_565_6,
            ],
          ],
        },
        '4 - Construction - Roof trusses': {
          'items': [
            [
              4_337_200,
              6.066_544_278_346_01,
              3.164_888_464_793_542,
            ],
          ],
        },
        '5 - Construction - Plate': {
          'items': [
            [
              2_985_000,
              4.175_190_138_998_165,
              2.178_177_641_660_224,
            ],
          ],
        },
        '6 - Construction - Plastering': {
          'items': [
            [
              2_468_000,
              3.452_050_004_371_011_4,
              1.800_918_733_540_178_2,
            ],
          ],
        },
        '7 - Construction - Interior doors': {
          'items': [
            [
              559_000,
              0.781_886_528_542_704_8,
              0.407_906_633_731_345_06,
            ],
          ],
        },
        '8 - Construction - Ceramics': {
          'items': [
            [
              2_195_000.000_000_000_5,
              3.070_198_443_919_924,
              1.601_708_517_066_731_1,
            ],
          ],
        },
        '9 - Construction - Floor': {
          'items': [
            [
              1_651_999.999_999_999_8,
              2.310_691_494_011_714,
              1.205_477_207_377_785_3,
            ],
          ],
        },
        'Developer\'s equity costs': {
          'items': [
            [
              0,
              0,
              0,
            ],
          ],
        },
        'JV Equity costs': {
          'items': [
            [
              0,
              0,
              0,
            ],
          ],
        },
        'Purchase cost - Solicitor': {
          'items': [
            [
              0,
              0,
              0,
            ],
          ],
        },
        'Purchase cost - Stamp duty': {
          'items': [
            [
              0,
              0,
              0,
            ],
          ],
        },
        'Purchase cost - Surveyor': {
          'items': [
            [
              30_000,
              0.041_961_709_939_680_04,
              0.021_891_232_579_499_736,
            ],
          ],
        },
        'Sales cost - Estate Agent': {
          'items': [
            [
              1_000_000,
              1.398_723_664_656_001_3,
              0.729_707_752_649_991_2,
            ],
          ],
        },
        'Senior debt costs': {
          'items': [
            [
              9_517_414.27,
              13.312_232_565_783_722,
              6.944_930_978_000_656,
            ],
          ],
        },
        'Total construction costs': {
          'items': [
            [
              71_493_750,
              100,
              52.169_543_641_020_31,
            ],
          ],
        },
        'Total costs': {
          'items': [
            [
              137_041_164.270_000_04,
              191.682_719_496_459_55,
              100,
            ],
          ],
        },
        'Total finance costs': {
          'items': [
            [
              9_517_414.27,
              13.312_232_565_783_722,
              6.944_930_978_000_656,
            ],
          ],
        },
        'Total non finance costs': {
          'items': [
            [
              127_523_750.000_000_04,
              178.370_486_930_675_84,
              93.055_069_021_999_34,
            ],
          ],
        },
        'Total purchase costs': {
          'items': [
            [
              55_030_000,
              76.971_763_266_019_75,
              40.155_817_628_329_02,
            ],
          ],
        },
        'Total sales costs': {
          'items': [
            [
              1_000_000,
              1.398_723_664_656_001_3,
              0.729_707_752_649_991_2,
            ],
          ],
        },
        'Unit sales': {
          'items': [
            [
              173_023_471,
              242.012_023_428_621_38,
              126.256_568_179_110_93,
            ],
          ],
        },
        'land_purchase': {
          'items': [
            [
              55_000_000,
              76.929_801_556_080_08,
              40.133_926_395_749_51,
            ],
          ],
        },
      },
      'Budget visualisation': {
        'Bank balance': [
          39_481_733.647_055_22,
          39_481_733.647_055_22,
          67_522_748.853_333_37,
          60_062_332.186_666_705,
          52_601_915.520_000_04,
          45_141_498.853_333_38,
          37_681_082.186_666_71,
          30_220_665.520_000_044,
          22_760_248.853_333_376,
          15_299_832.186_666_708,
          75_464_040.377_055_21,
        ],
        'Cash flow': [
          0,
          0,
          -69_518_415.416_666_67,
          -7_460_416.666_666_667,
          -7_460_416.666_666_667,
          -7_460_416.666_666_667,
          -7_460_416.666_666_667,
          -7_460_416.666_666_667,
          -7_460_416.666_666_667,
          -7_460_416.666_666_667,
          157_723_638.813_333_33,
        ],
      },
      'Build cost psqft': 30_409.93,
      'Build costs breakdown': [
        {
          'perSqFt': 1042.535_091_450_446_5,
          'perUnit': 81_700,
          'percentConstructionCost': 3.428_271_702_071_86,
          'percentTotalCost': 1.788_513_701_745_128_2,
          'title': 'Construction - Exterior walls',
          'total': 2_451_000,
        },
        {
          'perSqFt': 3455.806_039_982_985_6,
          'perUnit': 270_819.999_999_999_94,
          'percentConstructionCost': 11.364_070_285_864_148,
          'percentTotalCost': 5.928_583_607_180_117_5,
          'title': 'Construction - Frame completion',
          'total': 8_124_599.999_999_999,
        },
        {
          'perSqFt': 629.519_353_466_61,
          'perUnit': 49_333.333_333_333_34,
          'percentConstructionCost': 2.070_111_023_690_882_4,
          'percentTotalCost': 1.079_967_473_921_987,
          'title': 'Construction - Interior walls',
          'total': 1_480_000.000_000_000_2,
        },
        {
          'perSqFt': 1174.223_734_581_029_2,
          'perUnit': 92_020,
          'percentConstructionCost': 3.861_316_548_649_357_5,
          'percentTotalCost': 2.014_431_221_965_565_6,
          'title': 'Construction - Windows',
          'total': 2_760_600,
        },
        {
          'perSqFt': 1844.831_986_388_770_7,
          'perUnit': 144_573.333_333_333_34,
          'percentConstructionCost': 6.066_544_278_346_01,
          'percentTotalCost': 3.164_888_464_793_542,
          'title': 'Construction - Roof trusses',
          'total': 4_337_200,
        },
        {
          'perSqFt': 1269.672_479_795_831_6,
          'perUnit': 99_500,
          'percentConstructionCost': 4.175_190_138_998_165,
          'percentTotalCost': 2.178_177_641_660_224,
          'title': 'Construction - Plate',
          'total': 2_985_000,
        },
        {
          'perSqFt': 1049.766_056_997_022_6,
          'perUnit': 82_266.666_666_666_67,
          'percentConstructionCost': 3.452_050_004_371_011_4,
          'percentTotalCost': 1.800_918_733_540_178_2,
          'title': 'Construction - Plastering',
          'total': 2_468_000,
        },
        {
          'perSqFt': 237.771_161_207_996_58,
          'perUnit': 18_633.333_333_333_332,
          'percentConstructionCost': 0.781_886_528_542_704_8,
          'percentTotalCost': 0.407_906_633_731_345_06,
          'title': 'Construction - Interior doors',
          'total': 559_000,
        },
        {
          'perSqFt': 933.645_257_337_303_5,
          'perUnit': 73_166.666_666_666_69,
          'percentConstructionCost': 3.070_198_443_919_924,
          'percentTotalCost': 1.601_708_517_066_731_1,
          'title': 'Construction - Ceramics',
          'total': 2_195_000.000_000_000_5,
        },
        {
          'perSqFt': 702.679_710_761_378,
          'perUnit': 55_066.666_666_666_66,
          'percentConstructionCost': 2.310_691_494_011_714,
          'percentTotalCost': 1.205_477_207_377_785_3,
          'title': 'Construction - Floor',
          'total': 1_651_999.999_999_999_8,
        },
        {
          'perSqFt': 1037.941_301_573_798_5,
          'perUnit': 81_340,
          'percentConstructionCost': 3.413_165_486_493_574_5,
          'percentTotalCost': 1.780_632_858_016_508_5,
          'title': 'Construction - Painting',
          'total': 2_440_200,
        },
        {
          'perSqFt': 1979.370_480_646_533_5,
          'perUnit': 155_116.666_666_666_66,
          'percentConstructionCost': 6.508_960_573_476_702,
          'percentTotalCost': 3.395_695_026_956_734,
          'title': 'Construction - Kitchen',
          'total': 4_653_500,
        },
        {
          'perSqFt': 795.406_210_123_351_8,
          'perUnit': 62_333.333_333_333_336,
          'percentConstructionCost': 2.615_613_252_906_722_4,
          'percentTotalCost': 1.364_553_497_455_483_6,
          'title': 'Construction - Furniture',
          'total': 1_870_000,
        },
        {
          'perSqFt': 1097.830_710_336_027_3,
          'perUnit': 86_033.333_333_333_33,
          'percentConstructionCost': 3.610_105_778_477_14,
          'percentTotalCost': 1.883_375_709_589_627_3,
          'title': 'Construction - Appliances',
          'total': 2_581_000,
        },
        {
          'perSqFt': 1046.363_249_680_986_9,
          'perUnit': 82_000,
          'percentConstructionCost': 3.440_860_215_053_763_5,
          'percentTotalCost': 1.795_081_071_518_978_4,
          'title': 'Construction - Electricity',
          'total': 2_460_000,
        },
        {
          'perSqFt': 1450.723_096_554_657_3,
          'perUnit': 113_688.333_333_333_31,
          'percentConstructionCost': 4.770_556_866_858_99,
          'percentTotalCost': 2.488_777_746_575_692,
          'title': 'Construction - Plumbing',
          'total': 3_410_649.999_999_999_5,
        },
        {
          'perSqFt': 1384.517_226_712_037_2,
          'perUnit': 108_499.999_999_999_99,
          'percentConstructionCost': 4.552_845_528_455_284_5,
          'percentTotalCost': 2.375_198_734_875_721,
          'title': 'Construction - Elevator',
          'total': 3_254_999.999_999_999_5,
        },
        {
          'perSqFt': 212.675_457_252_233_1,
          'perUnit': 16_666.666_666_666_668,
          'percentConstructionCost': 0.699_361_832_328_000_6,
          'percentTotalCost': 0.364_853_876_324_995_6,
          'title': 'Construction - Commissioning',
          'total': 500_000,
        },
        {
          'perSqFt': 974.478_945_129_732,
          'perUnit': 76_366.666_666_666_67,
          'percentConstructionCost': 3.204_475_915_726_899_6,
          'percentTotalCost': 1.671_760_461_321_129_9,
          'title': 'Construction - Heating and ventilation',
          'total': 2_291_000,
        },
        {
          'perSqFt': 6239.897_915_780_519,
          'perUnit': 489_000,
          'percentConstructionCost': 20.519_276_160_503_54,
          'percentTotalCost': 10.704_812_731_375_371,
          'title': 'Construction - VAT',
          'total': 14_670_000,
        },
        {
          'perSqFt': 957.039_557_635_048_9,
          'perUnit': 75_000,
          'percentConstructionCost': 3.147_128_245_476_003,
          'percentTotalCost': 1.641_842_443_462_480_3,
          'title': 'Professional fees - Property cost',
          'total': 2_250_000,
        },
        {
          'perSqFt': 893.236_920_459_379,
          'perUnit': 70_000,
          'percentConstructionCost': 2.937_319_695_777_603,
          'percentTotalCost': 1.532_386_280_564_981_5,
          'title': 'Professional fees - Builders cost',
          'total': 2_100_000,
        },
        {
          'perSqFt': 30_409.931_943_853_68,
          'perUnit': 2_383_125,
          'percentConstructionCost': 100,
          'percentTotalCost': 52.169_543_641_020_31,
          'title': 'Total construction costs',
          'total': 71_493_750,
        },
      ],
      'Build costs £/sqft': 30_409.931_943_853_68,
      'Build time': 8,
      'Cost psqft (nia/gea)': '73596 / 173023471',
      'Costs vs sales': {
        'Costs': {
          'Construction costs': 71_493_750,
          'Finance costs': 9_517_414.27,
          'Purchase costs': 55_030_000,
          'Sales costs': 1_000_000,
        },
        'Revenue': { 'Sales': 173_023_471 },
      },
      'Equity partner': 'Yes',
      'Finance cost': 9_517_414.27,
      'Finance costs breakdown': [
        {
          'amount': 97_559_431,
          'cost': 9_517_414.27,
          'ltc': 71.19,
          'ltgdv': 56.39,
          'type': 'Senior debt',
        },
        {
          'amount': 39_481_734,
          'cost': 0,
          'ltc': 28.81,
          'ltgdv': 22.82,
          'type': 'Developer\'s equity',
        },
        {
          'amount': 0,
          'cost': 0,
          'ltc': 0,
          'ltgdv': 0,
          'type': 'JV Equity',
        },
      ],
      'GDV': 173_023_471,
      'Land cost': 55_000_000,
      'Land purchase costs summary': {
        'Purchase cost - Solicitor': [
          0,
          0,
          0,
        ],
        'Purchase cost - Stamp duty': [
          0,
          0,
          0,
        ],
        'Purchase cost - Surveyor': [
          30_000,
          0.021_891_232_579_499_736,
          1000,
        ],
        'Total purchase costs': [
          55_030_000,
          40.155_817_628_329_02,
          1_834_333.333_333_333_3,
        ],
      },
      // @ts-ignore
      'Main budget': {
        '0 - Construction - Exterior walls': [
          0,
          0,
          272_333.333_333_333_3,
          272_333.333_333_333_3,
          272_333.333_333_333_3,
          272_333.333_333_333_3,
          272_333.333_333_333_3,
          272_333.333_333_333_3,
          272_333.333_333_333_3,
          272_333.333_333_333_3,
          272_333.333_333_333_3,
        ],
        '1 - Construction - Frame completion': [
          0,
          0,
          902_733.333_333_333_4,
          902_733.333_333_333_4,
          902_733.333_333_333_4,
          902_733.333_333_333_4,
          902_733.333_333_333_4,
          902_733.333_333_333_4,
          902_733.333_333_333_4,
          902_733.333_333_333_4,
          902_733.333_333_333_4,
        ],
        '10 - Construction - Painting': [
          0,
          0,
          271_133.333_333_333_3,
          271_133.333_333_333_3,
          271_133.333_333_333_3,
          271_133.333_333_333_3,
          271_133.333_333_333_3,
          271_133.333_333_333_3,
          271_133.333_333_333_3,
          271_133.333_333_333_3,
          271_133.333_333_333_3,
        ],
        '11 - Construction - Kitchen': [
          0,
          0,
          517_055.555_555_555_56,
          517_055.555_555_555_56,
          517_055.555_555_555_56,
          517_055.555_555_555_56,
          517_055.555_555_555_56,
          517_055.555_555_555_56,
          517_055.555_555_555_56,
          517_055.555_555_555_56,
          517_055.555_555_555_56,
        ],
        '12 - Construction - Furniture': [
          0,
          0,
          207_777.777_777_777_78,
          207_777.777_777_777_78,
          207_777.777_777_777_78,
          207_777.777_777_777_78,
          207_777.777_777_777_78,
          207_777.777_777_777_78,
          207_777.777_777_777_78,
          207_777.777_777_777_78,
          207_777.777_777_777_78,
        ],
        '13 - Construction - Appliances': [
          0,
          0,
          286_777.777_777_777_75,
          286_777.777_777_777_75,
          286_777.777_777_777_75,
          286_777.777_777_777_75,
          286_777.777_777_777_75,
          286_777.777_777_777_75,
          286_777.777_777_777_75,
          286_777.777_777_777_75,
          286_777.777_777_777_75,
        ],
        '14 - Construction - Electricity': [
          0,
          0,
          273_333.333_333_333_3,
          273_333.333_333_333_3,
          273_333.333_333_333_3,
          273_333.333_333_333_3,
          273_333.333_333_333_3,
          273_333.333_333_333_3,
          273_333.333_333_333_3,
          273_333.333_333_333_3,
          273_333.333_333_333_3,
        ],
        '15 - Construction - Plumbing': [
          0,
          0,
          378_961.111_111_111_1,
          378_961.111_111_111_1,
          378_961.111_111_111_1,
          378_961.111_111_111_1,
          378_961.111_111_111_1,
          378_961.111_111_111_1,
          378_961.111_111_111_1,
          378_961.111_111_111_1,
          378_961.111_111_111_1,
        ],
        '16 - Construction - Elevator': [
          0,
          0,
          361_666.666_666_666_7,
          361_666.666_666_666_7,
          361_666.666_666_666_7,
          361_666.666_666_666_7,
          361_666.666_666_666_7,
          361_666.666_666_666_7,
          361_666.666_666_666_7,
          361_666.666_666_666_7,
          361_666.666_666_666_7,
        ],
        '17 - Construction - Commissioning': [
          0,
          0,
          55_555.555_555_555_555,
          55_555.555_555_555_555,
          55_555.555_555_555_555,
          55_555.555_555_555_555,
          55_555.555_555_555_555,
          55_555.555_555_555_555,
          55_555.555_555_555_555,
          55_555.555_555_555_555,
          55_555.555_555_555_555,
        ],
        '18 - Construction - Heating and ventilation': [
          0,
          0,
          254_555.555_555_555_56,
          254_555.555_555_555_56,
          254_555.555_555_555_56,
          254_555.555_555_555_56,
          254_555.555_555_555_56,
          254_555.555_555_555_56,
          254_555.555_555_555_56,
          254_555.555_555_555_56,
          254_555.555_555_555_56,
        ],
        '19 - Construction - VAT': [
          0,
          0,
          1_630_000,
          1_630_000,
          1_630_000,
          1_630_000,
          1_630_000,
          1_630_000,
          1_630_000,
          1_630_000,
          1_630_000,
        ],
        '2 - Construction - Interior walls': [
          0,
          0,
          164_444.444_444_444_44,
          164_444.444_444_444_44,
          164_444.444_444_444_44,
          164_444.444_444_444_44,
          164_444.444_444_444_44,
          164_444.444_444_444_44,
          164_444.444_444_444_44,
          164_444.444_444_444_44,
          164_444.444_444_444_44,
        ],
        '21 - Professional fees - Property cost': [
          0,
          0,
          2_250_000,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        '22 - Professional fees - Builders cost': [
          0,
          0,
          2_100_000,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        '3 - Construction - Windows': [
          0,
          0,
          306_733.333_333_333_3,
          306_733.333_333_333_3,
          306_733.333_333_333_3,
          306_733.333_333_333_3,
          306_733.333_333_333_3,
          306_733.333_333_333_3,
          306_733.333_333_333_3,
          306_733.333_333_333_3,
          306_733.333_333_333_3,
        ],
        '4 - Construction - Roof trusses': [
          0,
          0,
          481_911.111_111_111_1,
          481_911.111_111_111_1,
          481_911.111_111_111_1,
          481_911.111_111_111_1,
          481_911.111_111_111_1,
          481_911.111_111_111_1,
          481_911.111_111_111_1,
          481_911.111_111_111_1,
          481_911.111_111_111_1,
        ],
        '5 - Construction - Plate': [
          0,
          0,
          331_666.666_666_666_7,
          331_666.666_666_666_7,
          331_666.666_666_666_7,
          331_666.666_666_666_7,
          331_666.666_666_666_7,
          331_666.666_666_666_7,
          331_666.666_666_666_7,
          331_666.666_666_666_7,
          331_666.666_666_666_7,
        ],
        '6 - Construction - Plastering': [
          0,
          0,
          274_222.222_222_222_25,
          274_222.222_222_222_25,
          274_222.222_222_222_25,
          274_222.222_222_222_25,
          274_222.222_222_222_25,
          274_222.222_222_222_25,
          274_222.222_222_222_25,
          274_222.222_222_222_25,
          274_222.222_222_222_25,
        ],
        '7 - Construction - Interior doors': [
          0,
          0,
          62_111.111_111_111_11,
          62_111.111_111_111_11,
          62_111.111_111_111_11,
          62_111.111_111_111_11,
          62_111.111_111_111_11,
          62_111.111_111_111_11,
          62_111.111_111_111_11,
          62_111.111_111_111_11,
          62_111.111_111_111_11,
        ],
        '8 - Construction - Ceramics': [
          0,
          0,
          243_888.888_888_888_88,
          243_888.888_888_888_88,
          243_888.888_888_888_88,
          243_888.888_888_888_88,
          243_888.888_888_888_88,
          243_888.888_888_888_88,
          243_888.888_888_888_88,
          243_888.888_888_888_88,
          243_888.888_888_888_88,
        ],
        '9 - Construction - Floor': [
          0,
          0,
          183_555.555_555_555_56,
          183_555.555_555_555_56,
          183_555.555_555_555_56,
          183_555.555_555_555_56,
          183_555.555_555_555_56,
          183_555.555_555_555_56,
          183_555.555_555_555_56,
          183_555.555_555_555_56,
          183_555.555_555_555_56,
        ],
        'Cash balance': [
          39_481_733.647_055_22,
          39_481_733.647_055_22,
          67_522_748.853_333_37,
          60_062_332.186_666_705,
          52_601_915.520_000_04,
          45_141_498.853_333_38,
          37_681_082.186_666_71,
          30_220_665.520_000_044,
          22_760_248.853_333_376,
          15_299_832.186_666_708,
          75_464_040.377_055_21,
        ],
        'Cash flow': [
          0,
          0,
          -69_518_415.416_666_67,
          -7_460_416.666_666_667,
          -7_460_416.666_666_667,
          -7_460_416.666_666_667,
          -7_460_416.666_666_667,
          -7_460_416.666_666_667,
          -7_460_416.666_666_667,
          -7_460_416.666_666_667,
          157_723_638.813_333_33,
        ],
        'Developer\'s equity costs': [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        'JV Equity costs': [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        'Purchase cost - Solicitor': [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        'Purchase cost - Stamp duty': [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        'Purchase cost - Surveyor': [
          0,
          0,
          30_000,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        'Sales cost - Estate Agent': [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1_000_000,
        ],
        'Senior debt costs': [
          0,
          0,
          2_677_998.75,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          6_839_415.52,
        ],
        'Total construction costs': [
          0,
          0,
          11_810_416.666_666_668,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
        ],
        'Total costs': [
          0,
          0,
          69_518_415.416_666_67,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          15_299_832.186_666_667,
        ],
        'Total finance costs': [
          0,
          0,
          2_677_998.75,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          6_839_415.52,
        ],
        'Total non finance costs': [
          0,
          0,
          66_840_416.666_666_67,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          7_460_416.666_666_667,
          8_460_416.666_666_668,
        ],
        'Total purchase costs': [
          0,
          0,
          55_030_000,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        'Total sales costs': [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1_000_000,
        ],
        'Unit sales': [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          173_023_471,
        ],
        'land_purchase': [
          0,
          0,
          55_000_000,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
      },
      'Main budget months': [
        'Mar 24',
        'Apr 24',
        'May 24',
        'Jun 24',
        'Jul 24',
        'Aug 24',
        'Sep 24',
        'Oct 24',
        'Nov 24',
        'Dec 24',
        'Jan 25',
      ],
      'Main unit type': 'Apartment',
      'NIA/GEA': 0.78,
      'Net Profit:': 35_982_306.729_999_96,
      'Net margin': 20.796_199_799_968_175,
      'Net profit': 35_982_306.729_999_99,
      'Own capital required': 39_481_733.647_055_22,
      'Profit on GDV': 20.8,
      'Profit on cost': 26.26,
      'Project type': 'New build apartments',
      'ROI': 91.136_592_561_162_16,
      'Rental': {
        'Annual rental costs': 0,
        'Gross yield': 0,
        'Nominal net yield': 0,
        'Nominal rental cashflow': 0,
        'Nominal total annual rent': 0,
      },
      'Residual land value': 55_000_000,
      'Return on Equity': 0,
      'Revenue vs costs vs finance': {
        'Costs': {
          'Construction costs': 71_493_750,
          'Finance costs': 9_517_414.27,
          'Purchase costs': 55_030_000,
          'Sales costs': 1_000_000,
        },
        'Financing': [
          {
            'amount': 97_559_430.622_944_82,
            'type': 'Senior debt',
          },
          {
            'amount': 39_481_733.647_055_22,
            'type': 'Developer\'s equity',
          },
          {
            'amount': 0,
            'type': 'JV Equity',
          },
        ],
        'Revenue': { 'Sales': 173_023_471 },
      },
      // @ts-ignore
      'Sales cost breakdown': {
        'Sales cost - Estate Agent': [
          1_000_000,
          0.577_956_270_453_041_7,
          0.729_707_752_649_991_2,
          33_333.333_333_333_336,
        ],
        'Total sales costs': [
          1_000_000,
          0.577_956_270_453_041_7,
          0.729_707_752_649_991_2,
          33_333.333_333_333_336,
        ],
      },
      'Sales value psqft': 73_595.691_620_586_98,
      'Sellable £sqft': 73_595.691_620_586_98,
      'Stamp duty total': 0,
      'Timeline': {
        'construction_complete_month': 'Fri, 31 Jan 2025 00:00:00 GMT',
        'construction_start_month': 'Fri, 31 May 2024 00:00:00 GMT',
        'exit_month': 'Fri, 31 Jan 2025 00:00:00 GMT',
        'purchase_complete_month': 'Fri, 31 May 2024 00:00:00 GMT',
        'sales_complete_month': 'Fri, 31 Jan 2025 00:00:00 GMT',
        'start_date': 'Sun, 31 Mar 2024 00:00:00 GMT',
        'start_month': 'Sun, 31 Mar 2024 00:00:00 GMT',
      },
      'Total build costs': 71_493_750,
      'Total cost': 137_041_164.270_000_04,
      'Total costs': 137_041_164.270_000_04,
      'Total debt': 97_559_430.622_944_82,
      'Total floor area': '2351',
      'Total nia': '2351',
      'Total project cost': 137_041_164.270_000_04,
      'Total purchase costs': 55_030_000,
      'Total sales costs': 1_000_000,
      'Total units': 30,
      'Units overview': {
        '': [
          'BRF Loan (Sweden only)',
          0,
          1,
          '',
          32_914_000,
          2351,
        ],
      },
      'mainBudget': [
        {
          'bold': true,
          'subSections': [
            {
              'subSections': [
                {
                  'title': 'Estate Agent',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    -1_000_000,
                  ],
                  subSections: [],
                },
                {
                  'bold': true,
                  'title': 'Total sales costs',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    -1_000_000,
                  ],
                  subSections: [],
                },
              ],
              'title': 'Sales costs',
              'values': [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -1_000_000,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Senior debt costs',
                  'values': [
                    0,
                    0,
                    -2_677_998.75,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    -6_839_415.52,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Developer\'s equity costs',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
                {
                  'title': 'JV Equity costs',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
                {
                  'bold': true,
                  'title': 'Total finance costs',
                  'values': [
                    0,
                    0,
                    -2_677_998.75,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    -6_839_415.52,
                  ],
                  subSections: [],
                },
              ],
              'title': 'Finance expenses',
              'values': [
                0,
                0,
                -2_677_998.75,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -6_839_415.52,
              ],
            },
            {
              'bold': true,
              'title': 'Net cashflow from operating activities',
              'values': [
                0,
                0,
                -2_677_998.75,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -7_839_415.52,
              ],
              subSections: [],
            },
          ],
          'title': 'Cashflow from operating activities',
          'values': [
            0,
            0,
            -2_677_998.75,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            -7_839_415.52,
          ],
        },
        {
          'bold': true,
          'subSections': [
            {
              'subSections': [
                {
                  'title': 'Purchase cost',
                  'values': [
                    0,
                    0,
                    -55_000_000,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Solicitor',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Surveyor',
                  'values': [
                    0,
                    0,
                    -30_000,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Stamp duty',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
                {
                  'bold': true,
                  'title': 'Total purchase costs',
                  'values': [
                    0,
                    0,
                    -55_030_000,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
              ],
              'title': 'Purchase costs',
              'values': [
                0,
                0,
                -55_030_000,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Exterior walls',
                  'values': [
                    0,
                    0,
                    -272_333.333_333_333_3,
                    -272_333.333_333_333_3,
                    -272_333.333_333_333_3,
                    -272_333.333_333_333_3,
                    -272_333.333_333_333_3,
                    -272_333.333_333_333_3,
                    -272_333.333_333_333_3,
                    -272_333.333_333_333_3,
                    -272_333.333_333_333_3,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Frame completion',
                  'values': [
                    0,
                    0,
                    -902_733.333_333_333_4,
                    -902_733.333_333_333_4,
                    -902_733.333_333_333_4,
                    -902_733.333_333_333_4,
                    -902_733.333_333_333_4,
                    -902_733.333_333_333_4,
                    -902_733.333_333_333_4,
                    -902_733.333_333_333_4,
                    -902_733.333_333_333_4,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Interior walls',
                  'values': [
                    0,
                    0,
                    -164_444.444_444_444_44,
                    -164_444.444_444_444_44,
                    -164_444.444_444_444_44,
                    -164_444.444_444_444_44,
                    -164_444.444_444_444_44,
                    -164_444.444_444_444_44,
                    -164_444.444_444_444_44,
                    -164_444.444_444_444_44,
                    -164_444.444_444_444_44,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Windows',
                  'values': [
                    0,
                    0,
                    -306_733.333_333_333_3,
                    -306_733.333_333_333_3,
                    -306_733.333_333_333_3,
                    -306_733.333_333_333_3,
                    -306_733.333_333_333_3,
                    -306_733.333_333_333_3,
                    -306_733.333_333_333_3,
                    -306_733.333_333_333_3,
                    -306_733.333_333_333_3,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Roof trusses',
                  'values': [
                    0,
                    0,
                    -481_911.111_111_111_1,
                    -481_911.111_111_111_1,
                    -481_911.111_111_111_1,
                    -481_911.111_111_111_1,
                    -481_911.111_111_111_1,
                    -481_911.111_111_111_1,
                    -481_911.111_111_111_1,
                    -481_911.111_111_111_1,
                    -481_911.111_111_111_1,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Plate',
                  'values': [
                    0,
                    0,
                    -331_666.666_666_666_7,
                    -331_666.666_666_666_7,
                    -331_666.666_666_666_7,
                    -331_666.666_666_666_7,
                    -331_666.666_666_666_7,
                    -331_666.666_666_666_7,
                    -331_666.666_666_666_7,
                    -331_666.666_666_666_7,
                    -331_666.666_666_666_7,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Plastering',
                  'values': [
                    0,
                    0,
                    -274_222.222_222_222_25,
                    -274_222.222_222_222_25,
                    -274_222.222_222_222_25,
                    -274_222.222_222_222_25,
                    -274_222.222_222_222_25,
                    -274_222.222_222_222_25,
                    -274_222.222_222_222_25,
                    -274_222.222_222_222_25,
                    -274_222.222_222_222_25,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Interior doors',
                  'values': [
                    0,
                    0,
                    -62_111.111_111_111_11,
                    -62_111.111_111_111_11,
                    -62_111.111_111_111_11,
                    -62_111.111_111_111_11,
                    -62_111.111_111_111_11,
                    -62_111.111_111_111_11,
                    -62_111.111_111_111_11,
                    -62_111.111_111_111_11,
                    -62_111.111_111_111_11,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Ceramics',
                  'values': [
                    0,
                    0,
                    -243_888.888_888_888_88,
                    -243_888.888_888_888_88,
                    -243_888.888_888_888_88,
                    -243_888.888_888_888_88,
                    -243_888.888_888_888_88,
                    -243_888.888_888_888_88,
                    -243_888.888_888_888_88,
                    -243_888.888_888_888_88,
                    -243_888.888_888_888_88,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Floor',
                  'values': [
                    0,
                    0,
                    -183_555.555_555_555_56,
                    -183_555.555_555_555_56,
                    -183_555.555_555_555_56,
                    -183_555.555_555_555_56,
                    -183_555.555_555_555_56,
                    -183_555.555_555_555_56,
                    -183_555.555_555_555_56,
                    -183_555.555_555_555_56,
                    -183_555.555_555_555_56,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Painting',
                  'values': [
                    0,
                    0,
                    -271_133.333_333_333_3,
                    -271_133.333_333_333_3,
                    -271_133.333_333_333_3,
                    -271_133.333_333_333_3,
                    -271_133.333_333_333_3,
                    -271_133.333_333_333_3,
                    -271_133.333_333_333_3,
                    -271_133.333_333_333_3,
                    -271_133.333_333_333_3,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Kitchen',
                  'values': [
                    0,
                    0,
                    -517_055.555_555_555_56,
                    -517_055.555_555_555_56,
                    -517_055.555_555_555_56,
                    -517_055.555_555_555_56,
                    -517_055.555_555_555_56,
                    -517_055.555_555_555_56,
                    -517_055.555_555_555_56,
                    -517_055.555_555_555_56,
                    -517_055.555_555_555_56,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Furniture',
                  'values': [
                    0,
                    0,
                    -207_777.777_777_777_78,
                    -207_777.777_777_777_78,
                    -207_777.777_777_777_78,
                    -207_777.777_777_777_78,
                    -207_777.777_777_777_78,
                    -207_777.777_777_777_78,
                    -207_777.777_777_777_78,
                    -207_777.777_777_777_78,
                    -207_777.777_777_777_78,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Appliances',
                  'values': [
                    0,
                    0,
                    -286_777.777_777_777_75,
                    -286_777.777_777_777_75,
                    -286_777.777_777_777_75,
                    -286_777.777_777_777_75,
                    -286_777.777_777_777_75,
                    -286_777.777_777_777_75,
                    -286_777.777_777_777_75,
                    -286_777.777_777_777_75,
                    -286_777.777_777_777_75,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Electricity',
                  'values': [
                    0,
                    0,
                    -273_333.333_333_333_3,
                    -273_333.333_333_333_3,
                    -273_333.333_333_333_3,
                    -273_333.333_333_333_3,
                    -273_333.333_333_333_3,
                    -273_333.333_333_333_3,
                    -273_333.333_333_333_3,
                    -273_333.333_333_333_3,
                    -273_333.333_333_333_3,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Plumbing',
                  'values': [
                    0,
                    0,
                    -378_961.111_111_111_1,
                    -378_961.111_111_111_1,
                    -378_961.111_111_111_1,
                    -378_961.111_111_111_1,
                    -378_961.111_111_111_1,
                    -378_961.111_111_111_1,
                    -378_961.111_111_111_1,
                    -378_961.111_111_111_1,
                    -378_961.111_111_111_1,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Elevator',
                  'values': [
                    0,
                    0,
                    -361_666.666_666_666_7,
                    -361_666.666_666_666_7,
                    -361_666.666_666_666_7,
                    -361_666.666_666_666_7,
                    -361_666.666_666_666_7,
                    -361_666.666_666_666_7,
                    -361_666.666_666_666_7,
                    -361_666.666_666_666_7,
                    -361_666.666_666_666_7,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Commissioning',
                  'values': [
                    0,
                    0,
                    -55_555.555_555_555_555,
                    -55_555.555_555_555_555,
                    -55_555.555_555_555_555,
                    -55_555.555_555_555_555,
                    -55_555.555_555_555_555,
                    -55_555.555_555_555_555,
                    -55_555.555_555_555_555,
                    -55_555.555_555_555_555,
                    -55_555.555_555_555_555,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Heating and ventilation',
                  'values': [
                    0,
                    0,
                    -254_555.555_555_555_56,
                    -254_555.555_555_555_56,
                    -254_555.555_555_555_56,
                    -254_555.555_555_555_56,
                    -254_555.555_555_555_56,
                    -254_555.555_555_555_56,
                    -254_555.555_555_555_56,
                    -254_555.555_555_555_56,
                    -254_555.555_555_555_56,
                  ],
                  subSections: [],
                },
                {
                  'title': 'VAT',
                  'values': [
                    0,
                    0,
                    -1_630_000,
                    -1_630_000,
                    -1_630_000,
                    -1_630_000,
                    -1_630_000,
                    -1_630_000,
                    -1_630_000,
                    -1_630_000,
                    -1_630_000,
                  ],
                  subSections: [],
                },
                {
                  'bold': true,
                  'title': 'Total construction costs',
                  'values': [
                    0,
                    0,
                    -7_460_416.666_666_667,
                    -7_460_416.666_666_667,
                    -7_460_416.666_666_667,
                    -7_460_416.666_666_667,
                    -7_460_416.666_666_667,
                    -7_460_416.666_666_667,
                    -7_460_416.666_666_667,
                    -7_460_416.666_666_667,
                    -7_460_416.666_666_667,
                  ],
                  subSections: [],
                },
              ],
              'title': 'Construction costs',
              'values': [
                0,
                0,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Property cost',
                  'values': [
                    0,
                    0,
                    -2_250_000,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
                {
                  'title': 'Builders cost',
                  'values': [
                    0,
                    0,
                    -2_100_000,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
                {
                  'bold': true,
                  'title': 'Total construction costs',
                  'values': [
                    0,
                    0,
                    -4_350_000,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
              ],
              'title': 'Professional fees',
              'values': [
                0,
                0,
                -4_350_000,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Residential',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    140_109_471,
                  ],
                  subSections: [],
                },
                {
                  'title': 'BRF Loan (Sweden only)',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    32_914_000,
                  ],
                  subSections: [],
                },
                {
                  'bold': true,
                  'title': 'Total',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    173_023_471,
                  ],
                  subSections: [],
                },
              ],
              'title': 'Net proceeds from property sales',
              'values': [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                173_023_471,
              ],
            },
            {
              'bold': true,
              'title': 'Net cashflow from investing activities',
              'values': [
                0,
                0,
                -66_840_416.666_666_664,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                165_563_054.333_333_34,
              ],
              subSections: [],
            },
          ],
          'title': 'Cashflow from investing activities',
          'values': [
            0,
            0,
            -66_840_416.666_666_664,
            -7_460_416.666_666_667,
            -7_460_416.666_666_667,
            -7_460_416.666_666_667,
            -7_460_416.666_666_667,
            -7_460_416.666_666_667,
            -7_460_416.666_666_667,
            -7_460_416.666_666_667,
            165_563_054.333_333_34,
          ],
        },
        {
          'bold': true,
          'subSections': [
            {
              'subSections': [
                {
                  'title': 'Developer\'s equity',
                  'values': [
                    39_481_733.647_055_22,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
                {
                  'title': 'JV Equity',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
                {
                  'bold': true,
                  'title': 'Total equity financing',
                  'values': [
                    39_481_733.647_055_22,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
              ],
              'title': 'Equity financing',
              'values': [
                39_481_733.647_055_22,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Developer\'s equity',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    -71_865_809.704_055_19,
                  ],
                  subSections: [],
                },
                {
                  'title': 'JV Equity',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    -3_598_230.672_999_995_8,
                  ],
                  subSections: [],
                },
                {
                  'bold': true,
                  'title': 'Total dividends repaid',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    -75_464_040.377_055_18,
                  ],
                  subSections: [],
                },
              ],
              'title': 'Dividends repaid',
              'values': [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -75_464_040.377_055_18,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Senior debt',
                  'values': [
                    97_559_430.622_944_82,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
                {
                  'bold': true,
                  'title': 'Total debt financing',
                  'values': [
                    97_559_430.622_944_82,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                  subSections: [],
                },
              ],
              'title': 'Debt financing received',
              'values': [
                97_559_430.622_944_82,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Senior debt',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    -97_559_430.622_944_82,
                  ],
                  subSections: [],
                },
                {
                  'bold': true,
                  'title': 'Total debt repaid',
                  'values': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    -97_559_430.622_944_82,
                  ],
                  subSections: [],
                },
              ],
              'title': 'Debt financing repaid',
              'values': [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -97_559_430.622_944_82,
              ],
            },
            {
              'bold': true,
              'title': 'Net cashflow from financing activities',
              'values': [
                137_041_164.270_000_04,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -173_023_471,
              ],
              subSections: [],
            },
          ],
          'title': 'Cashflow from financing activities',
          'values': [
            137_041_164.270_000_04,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            -173_023_471,
          ],
        },
        {
          'bold': true,
          'subSections': [
            {
              'title': 'Net investors\' cashflow',
              'values': [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -10_794_692.018_999_986,
              ],
              subSections: [],
            },
            {
              'title': 'Net developers\' cashflow',
              'values': [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
              ],
              subSections: [],
            },
            {
              'bold': true,
              'title': 'Total cashflow',
              'values': [
                137_041_164.270_000_04,
                0,
                -69_518_415.416_666_66,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -7_460_416.666_666_667,
                -15_299_832.186_666_667,
              ],
              subSections: [],
            },
          ],
          'title': 'Net cashflow',
          'values': [
            137_041_164.270_000_04,
            0,
            -69_518_415.416_666_66,
            -7_460_416.666_666_667,
            -7_460_416.666_666_667,
            -7_460_416.666_666_667,
            -7_460_416.666_666_667,
            -7_460_416.666_666_667,
            -7_460_416.666_666_667,
            -7_460_416.666_666_667,
            -15_299_832.186_666_667,
          ],
        },
      ],
      'mainBudgetSummary': [
        {
          'bold': true,
          'subSections': [
            {
              'subSections': [
                {
                  'title': 'Estate Agent',
                  'values': [
                    -1_000_000,
                    -425.35,
                    -33_333.33,
                  ],
                },
                {
                  'bold': true,
                  'title': 'Total sales costs',
                  'totalValue': true,
                  'values': [
                    -1_000_000,
                    -425.35,
                    -33_333.33,
                  ],
                },
              ],
              'title': 'Sales costs',
              'values': [
                -1_000_000,
                -425.35,
                -33_333.33,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Senior debt costs',
                  'values': [
                    -9_517_414.27,
                    -4048.24,
                    -317_247.14,
                  ],
                },
                {
                  'title': 'Developer\'s equity costs',
                  'values': [
                    0,
                    0,
                    0,
                  ],
                },
                {
                  'title': 'JV Equity costs',
                  'values': [
                    0,
                    0,
                    0,
                  ],
                },
                {
                  'bold': true,
                  'title': 'Total finance costs',
                  'totalValue': true,
                  'values': [
                    -9_517_414.27,
                    -4048.24,
                    -317_247.14,
                  ],
                },
              ],
              'title': 'Finance expenses',
              'values': [
                -9_517_414.27,
                -4048.24,
                -317_247.14,
              ],
            },
            {
              'bold': true,
              'title': 'Net contribution from operating activities',
              'totalValue': true,
              'values': [
                -10_517_414.27,
                -4473.59,
                -350_580.48,
              ],
            },
          ],
          'title': 'Revenue from operating activities',
          'values': [
            -10_517_414.27,
            -4473.59,
            -350_580.48,
          ],
        },
        {
          'bold': true,
          'subSections': [
            {
              'subSections': [
                {
                  'title': 'Purchase cost',
                  'values': [
                    -55_000_000,
                    -23_394.3,
                    -1_833_333.33,
                  ],
                },
                {
                  'title': 'Solicitor',
                  'values': [
                    0,
                    0,
                    0,
                  ],
                },
                {
                  'title': 'Surveyor',
                  'values': [
                    -30_000,
                    -12.76,
                    -1000,
                  ],
                },
                {
                  'title': 'Stamp duty',
                  'values': [
                    0,
                    0,
                    0,
                  ],
                },
                {
                  'bold': true,
                  'title': 'Total purchase costs',
                  'totalValue': true,
                  'values': [
                    -55_030_000,
                    -23_407.06,
                    -1_834_333.33,
                  ],
                },
              ],
              'title': 'Purchase costs',
              'values': [
                -55_030_000,
                -23_407.06,
                -1_834_333.33,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Exterior walls',
                  'values': [
                    -2_451_000,
                    -1042.54,
                    -81_700,
                  ],
                },
                {
                  'title': 'Frame completion',
                  'values': [
                    -8_124_600,
                    -3455.81,
                    -270_820,
                  ],
                },
                {
                  'title': 'Interior walls',
                  'values': [
                    -1_480_000,
                    -629.52,
                    -49_333.33,
                  ],
                },
                {
                  'title': 'Windows',
                  'values': [
                    -2_760_600,
                    -1174.22,
                    -92_020,
                  ],
                },
                {
                  'title': 'Roof trusses',
                  'values': [
                    -4_337_200,
                    -1844.83,
                    -144_573.33,
                  ],
                },
                {
                  'title': 'Plate',
                  'values': [
                    -2_985_000,
                    -1269.67,
                    -99_500,
                  ],
                },
                {
                  'title': 'Plastering',
                  'values': [
                    -2_468_000,
                    -1049.77,
                    -82_266.67,
                  ],
                },
                {
                  'title': 'Interior doors',
                  'values': [
                    -559_000,
                    -237.77,
                    -18_633.33,
                  ],
                },
                {
                  'title': 'Ceramics',
                  'values': [
                    -2_195_000,
                    -933.65,
                    -73_166.67,
                  ],
                },
                {
                  'title': 'Floor',
                  'values': [
                    -1_652_000,
                    -702.68,
                    -55_066.67,
                  ],
                },
                {
                  'title': 'Painting',
                  'values': [
                    -2_440_200,
                    -1037.94,
                    -81_340,
                  ],
                },
                {
                  'title': 'Kitchen',
                  'values': [
                    -4_653_500,
                    -1979.37,
                    -155_116.67,
                  ],
                },
                {
                  'title': 'Furniture',
                  'values': [
                    -1_870_000,
                    -795.41,
                    -62_333.33,
                  ],
                },
                {
                  'title': 'Appliances',
                  'values': [
                    -2_581_000,
                    -1097.83,
                    -86_033.33,
                  ],
                },
                {
                  'title': 'Electricity',
                  'values': [
                    -2_460_000,
                    -1046.36,
                    -82_000,
                  ],
                },
                {
                  'title': 'Plumbing',
                  'values': [
                    -3_410_650,
                    -1450.72,
                    -113_688.33,
                  ],
                },
                {
                  'title': 'Elevator',
                  'values': [
                    -3_255_000,
                    -1384.52,
                    -108_500,
                  ],
                },
                {
                  'title': 'Commissioning',
                  'values': [
                    -500_000,
                    -212.68,
                    -16_666.67,
                  ],
                },
                {
                  'title': 'Heating and ventilation',
                  'values': [
                    -2_291_000,
                    -974.48,
                    -76_366.67,
                  ],
                },
                {
                  'title': 'VAT',
                  'values': [
                    -14_670_000,
                    -6239.9,
                    -489_000,
                  ],
                },
                {
                  'bold': true,
                  'title': 'Total construction costs',
                  'totalValue': true,
                  'values': [
                    -67_143_750,
                    -28_559.66,
                    -2_238_125,
                  ],
                },
              ],
              'title': 'Construction costs',
              'values': [
                -67_143_750,
                -28_559.66,
                -2_238_125,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Property cost',
                  'values': [
                    -2_250_000,
                    -957.04,
                    -75_000,
                  ],
                },
                {
                  'title': 'Builders cost',
                  'values': [
                    -2_100_000,
                    -893.24,
                    -70_000,
                  ],
                },
                {
                  'bold': true,
                  'title': 'Total construction costs',
                  'totalValue': true,
                  'values': [
                    -4_350_000,
                    -1850.28,
                    -145_000,
                  ],
                },
              ],
              'title': 'Professional fees',
              'values': [
                -4_350_000,
                -1850.28,
                -145_000,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Residential',
                  'values': [
                    140_109_471,
                    59_595.69,
                    4_670_315.7,
                  ],
                },
                {
                  'title': 'BRF Loan (Sweden only)',
                  'values': [
                    32_914_000,
                    14_000,
                    1_097_133.33,
                  ],
                },
                {
                  'bold': true,
                  'title': 'Total',
                  'totalValue': true,
                  'values': [
                    173_023_471,
                    73_595.69,
                    5_767_449.03,
                  ],
                },
              ],
              'title': 'Net proceeds from property sales',
              'values': [
                173_023_471,
                73_595.69,
                5_767_449.03,
              ],
            },
            {
              'bold': true,
              'title': 'Net contribution from investing activities',
              'totalValue': true,
              'values': [
                46_499_721,
                19_778.7,
                1_549_990.7,
              ],
            },
          ],
          'title': 'Revenue from investing activities',
          'values': [
            46_499_721,
            19_778.7,
            1_549_990.7,
          ],
        },
        {
          'bold': true,
          'subSections': [
            {
              'subSections': [
                {
                  'title': 'Developer\'s equity',
                  'values': [
                    39_481_733.65,
                    16_793.59,
                    1_316_057.79,
                  ],
                },
                {
                  'title': 'JV Equity',
                  'values': [
                    0,
                    0,
                    0,
                  ],
                },
                {
                  'bold': true,
                  'title': 'Total equity financing',
                  'totalValue': true,
                  'values': [
                    39_481_733.65,
                    16_793.59,
                    1_316_057.79,
                  ],
                },
              ],
              'title': 'Equity financing',
              'values': [
                39_481_733.65,
                16_793.59,
                1_316_057.79,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Developer\'s equity',
                  'values': [
                    -71_865_809.7,
                    -30_568.19,
                    -2_395_526.99,
                  ],
                },
                {
                  'title': 'JV Equity',
                  'values': [
                    -3_598_230.67,
                    -1530.51,
                    -119_941.02,
                  ],
                },
                {
                  'bold': true,
                  'title': 'Total dividends repaid',
                  'totalValue': true,
                  'values': [
                    -75_464_040.38,
                    -32_098.7,
                    -2_515_468.01,
                  ],
                },
              ],
              'title': 'Dividends repaid',
              'values': [
                -75_464_040.38,
                -32_098.7,
                -2_515_468.01,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Senior debt',
                  'values': [
                    97_559_430.62,
                    41_496.99,
                    3_251_981.02,
                  ],
                },
                {
                  'bold': true,
                  'title': 'Total debt financing',
                  'totalValue': true,
                  'values': [
                    97_559_430.62,
                    41_496.99,
                    3_251_981.02,
                  ],
                },
              ],
              'title': 'Debt financing received',
              'values': [
                97_559_430.62,
                41_496.99,
                3_251_981.02,
              ],
            },
            {
              'subSections': [
                {
                  'title': 'Senior debt',
                  'values': [
                    -97_559_430.62,
                    -41_496.99,
                    -3_251_981.02,
                  ],
                },
                {
                  'bold': true,
                  'title': 'Total debt repaid',
                  'totalValue': true,
                  'values': [
                    -97_559_430.62,
                    -41_496.99,
                    -3_251_981.02,
                  ],
                },
              ],
              'title': 'Debt financing repaid',
              'values': [
                -97_559_430.62,
                -41_496.99,
                -3_251_981.02,
              ],
            },
            {
              'bold': true,
              'title': 'Net contribution from financing',
              'totalValue': true,
              'values': [
                -35_982_306.73,
                -15_305.11,
                -1_199_410.22,
              ],
            },
          ],
          'title': 'Financing',
          'values': [
            -35_982_306.73,
            -15_305.11,
            -1_199_410.22,
          ],
        },
        {
          'bold': true,
          'subSections': [
            {
              'title': 'Net investors\' cashflow',
              'values': [
                -10_794_692.02,
                -4591.53,
                -359_823.07,
              ],
            },
            {
              'title': 'Net developers\' cashflow',
              'values': [
                0,
                0,
                0,
              ],
            },
            {
              'bold': true,
              'title': 'Total',
              'values': [
                0,
                0,
                0,
              ],
            },
          ],
          'title': 'Net profit',
          'values': [
            0,
            0,
            0,
          ],
        },
      ],
    },
  },
  'sensitivityAnalysis': [
    [
      11_933_815.530_000_001,
      20_584_989.080_000_013,
      29_236_162.629_999_995,
      37_887_336.180_000_01,
      46_538_509.729_999_99,
      55_189_683.28,
      63_840_856.830_000_01,
      72_492_030.38,
      81_143_203.93,
      89_794_377.479_999_99,
    ],
    [
      8_092_340.219_999_999,
      16_743_513.770_000_01,
      25_394_687.319_999_993,
      34_045_860.870_000_005,
      42_697_034.419_999_99,
      51_348_207.97,
      59_999_381.520_000_01,
      68_650_555.07,
      77_301_728.62,
      85_952_902.169_999_99,
    ],
    [
      4_250_864.909_999_996,
      12_902_038.460_000_008,
      21_553_212.009_999_99,
      30_204_385.560_000_002,
      38_855_559.109_999_985,
      47_506_732.66,
      56_157_906.210_000_01,
      64_809_079.759_999_99,
      73_460_253.31,
      82_111_426.859_999_98,
    ],
    [
      409_389.590_000_003_6,
      9_060_563.140_000_015,
      17_711_736.689_999_998,
      26_362_910.240_000_01,
      35_014_083.789_999_99,
      43_665_257.34,
      52_316_430.890_000_015,
      60_967_604.44,
      69_618_777.990_000_01,
      78_269_951.539_999_99,
    ],
    [
      -3_432_085.710_000_008_3,
      5_219_087.840_000_004,
      13_870_261.389_999_986,
      22_521_434.939_999_998,
      31_172_608.489_999_98,
      39_823_782.039_999_99,
      48_474_955.59,
      57_126_129.139_999_986,
      65_777_302.69,
      74_428_476.239_999_98,
    ],
    [
      -7_273_561.019_999_996,
      1_377_612.530_000_001_2,
      10_028_786.080_000_013,
      18_679_959.629_999_995,
      27_331_133.179_999_977,
      35_982_306.729_999_99,
      44_633_480.28,
      53_284_653.829_999_98,
      61_935_827.379_999_995,
      70_587_000.929_999_98,
    ],
    [
      -11_115_036.329_999_998,
      -2_463_862.779_999_971_4,
      6_187_310.769_999_981,
      14_838_484.319_999_993,
      23_489_657.870_000_005,
      32_140_831.420_000_017,
      40_792_004.970_000_03,
      49_443_178.519_999_98,
      58_094_352.069_999_99,
      66_745_525.620_000_005,
    ],
    [
      -14_956_511.629_999_995,
      -6_305_338.079_999_983,
      2_345_835.469_999_999,
      10_997_009.020_000_01,
      19_648_182.569_999_993,
      28_299_356.120_000_005,
      36_950_529.670_000_02,
      45_601_703.22,
      54_252_876.770_000_01,
      62_904_050.319_999_99,
    ],
    [
      -18_797_986.939_999_998,
      -10_146_813.389_999_986,
      -1_495_639.840_000_003_6,
      7_155_533.710_000_008,
      15_806_707.259_999_99,
      24_457_880.810_000_002,
      33_109_054.360_000_014,
      41_760_227.91,
      50_411_401.460_000_01,
      59_062_575.009_999_99,
    ],
    [
      -22_639_462.25,
      -13_988_288.699_999_988,
      -5_337_115.150_000_006,
      3_314_058.400_000_006,
      11_965_231.949_999_988,
      20_616_405.5,
      29_267_579.050_000_012,
      37_918_752.599_999_994,
      46_569_926.150_000_006,
      55_221_099.699_999_99,
    ],
  ],
  'comparables': {
    'selectedComparables': [],
    'comparablesFilter': {
      'type': [],
      'bedroom': [],
      'area': { 'value': 'default' },
      'price': {
        'min': 0,
        'max': 100_000_000,
      },
    },
  },
  'localArea': {
    userId: '',
    projectId: 0,
    'title': 'stockholm, Stockholm, Stockholms län',
    'narrative': 'Your site is in the central urban area of stockholm. The population of stockholm is 984 748. The average monthly salary is 37 500,00 kr and the average age is 39,9 years.',
    'locality': 'stockholm',
    'population': 984_748,
    'local': {
      'meanIncomeLocal': 37_500,
      'medianAgeLocal': 39.9,
      'crimesPer10000Local': undefined,
    },
    'region': {
      'meanIncomeRegion': undefined,
      'medianAgeRegion': undefined,
      'crimesPer10000Region': undefined,
    },
  },
  'localMarket': {
    'narrative': '1, 2, 3, 4, 5 and 6 bedroom apartment . The median asking price in last year is 7 850 000,00 kr. The 75th and 90th percentile in the local market are 7 975 000,00 kr and 8 050 000,00 kr respectively.',
    'hint': 'Statistics are calculated using relevant market, which in this case includes 1, 2, 3, 4, 5 and 6 bedroom apartment . in the region of Stockholms län',
    'timeSeries': [
      {
        'month': '2022-01-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2022-02-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2022-03-01',
        'median': 11_475_000,
        'count': 2,
        'movingAverage': 11_475_000,
      },
      {
        'month': '2022-04-01',
        'median': 11_625_000,
        'count': 2,
        'movingAverage': 11_625_000,
      },
      {
        'month': '2022-05-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2022-06-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2022-07-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2022-08-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2022-09-01',
        'median': 7_120_000,
        'count': 1,
        'movingAverage': 7_120_000,
      },
      {
        'month': '2022-10-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2022-11-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2022-12-01',
        'median': 4_500_000,
        'count': 2,
        'movingAverage': 4_500_000,
      },
      {
        'month': '2023-01-01',
        'median': 12_650_000,
        'count': 1,
        'movingAverage': 12_650_000,
      },
      {
        'month': '2023-02-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2023-03-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2023-04-01',
        'median': 8_100_000,
        'count': 1,
        'movingAverage': 8_100_000,
      },
      {
        'month': '2023-05-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2023-06-01',
        'median': 7_600_000,
        'count': 1,
        'movingAverage': 7_600_000,
      },
      {
        'month': '2023-07-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2023-08-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2023-09-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2023-10-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2023-11-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
      {
        'month': '2023-12-01',
        'median': 0,
        'count': 0,
        'movingAverage': 0,
      },
    ],
    'market': {
      'quartile50': 7_850_000,
      'quartile75': 7_975_000,
      'quartile90': 8_050_000,
      'total': 10,
    },
    'distribution': {
      '2000000 - 2500000': 1,
      '2500000 - 3000000': 0,
      '3000000 - 3500000': 0,
      '3500000 - 4000000': 0,
      '4000000 - 4500000': 0,
      '4500000 - 5000000': 0,
      '5000000 - 5500000': 0,
      '5500000 - 6000000': 0,
      '6000000 - 6500000': 0,
      '6500000 - 7000000': 0,
      '7000000 - 7500000': 2,
      '7500000 - 8000000': 1,
      '8000000 - 8500000': 1,
      '8500000 - 9000000': 0,
      '9000000 - 9500000': 0,
      '9500000 - 10000000': 0,
      '10000000 - 10500000': 1,
      '10500000 - 11000000': 1,
      '11000000 - 11500000': 0,
      '11500000 - 12000000': 0,
      '12000000 - 13000000': 3,
    },
    'marketMappingValues': {
      'bbox': [
        [
          18.043_131_964_242_036,
          59.448_769_998_545_6,
        ],
        [
          18.055_705_035_757_963,
          59.448_769_998_545_6,
        ],
        [
          18.055_705_152_389_74,
          59.455_160_998_545_21,
        ],
        [
          18.043_131_847_610_26,
          59.455_160_998_545_21,
        ],
      ],
      'result': [
        {
          'color': '#3baa34',
          'price': 10_450_000,
          'latitude': '59.451355',
          'longitude': '18.046573',
        },
        {
          'color': '#3baa34',
          'price': 2_000_000,
          'latitude': '59.453652',
          'longitude': '18.045973',
        },
        {
          'color': '#ff1717',
          'price': 12_650_000,
          'latitude': '59.45043',
          'longitude': '18.046521',
        },
        {
          'color': '#3baa34',
          'price': 7_120_000,
          'latitude': '59.453464',
          'longitude': '18.044704',
        },
        {
          'color': '#ff1717',
          'price': 12_500_000,
          'latitude': '59.454309',
          'longitude': '18.053889',
        },
        {
          'color': '#ff1717',
          'price': 10_750_000,
          'latitude': '59.453133',
          'longitude': '18.055088',
        },
        {
          'color': '#3baa34',
          'price': 7_000_000,
          'latitude': '59.455161',
          'longitude': '18.046043',
        },
        {
          'color': '#3baa34',
          'price': 7_600_000,
          'latitude': '59.450548',
          'longitude': '18.043749',
        },
        {
          'color': '#3baa34',
          'price': 8_100_000,
          'latitude': '59.448892',
          'longitude': '18.04799',
        },
        {
          'color': '#ff1717',
          'price': 12_500_000,
          'latitude': '59.44877',
          'longitude': '18.048693',
        },
      ],
    },
  },
  'schools': [
    {
      'school': 'Brink School',
      'type': 'Primary school,Pre-school class,After-school home',
      'coordinates': {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [
            '18.04648229999998',
            '59.4595954',
          ],
        },
      },
      'ofstedRating': '',
      'totalNumberOfPupils': '412',
      'distance': 0.8,
      'time': {
        'duration': 11,
        'unit': 'minute',
        'message': '11 minutes walk',
      },
    },
    {
      'school': 'Noblaskolan Täby',
      'type': 'Primary school,Pre-school class,After-school home',
      'coordinates': {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [
            '18.033067399999936',
            '59.4523261',
          ],
        },
      },
      'ofstedRating': '',
      'totalNumberOfPupils': '466',
      'distance': 0.9,
      'time': {
        'duration': 13,
        'unit': 'minute',
        'message': '13 minutes walk',
      },
    },
    {
      'school': 'Ellagård School',
      'type': 'Primary school,Pre-school class,After-school home',
      'coordinates': {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [
            '18.04509529999996',
            '59.4434107',
          ],
        },
      },
      'ofstedRating': '',
      'totalNumberOfPupils': '364',
      'distance': 1,
      'time': {
        'duration': 5,
        'unit': 'minute',
        'message': '5 minutes drive',
      },
    },
    {
      'school': 'Täby Vocational High School',
      'type': 'Upper secondary school',
      'coordinates': {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [
            '18.0473644148193',
            '59.43902135433444',
          ],
        },
      },
      'ofstedRating': '',
      'totalNumberOfPupils': '140',
      'distance': 1.5,
      'time': {
        'duration': 6,
        'unit': 'minute',
        'message': '6 minutes drive',
      },
    },
    {
      'school': 'Tibble Gymnasium Campus Täby',
      'type': 'Upper secondary school',
      'coordinates': {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [
            '18.06685990000005',
            '59.44224190000001',
          ],
        },
      },
      'ofstedRating': '',
      'totalNumberOfPupils': '1748',
      'distance': 1.5,
      'time': {
        'duration': 6,
        'unit': 'minute',
        'message': '6 minutes drive',
      },
    },
    {
      'school': 'Åva high school',
      'type': 'Upper secondary school',
      'coordinates': {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [
            '18.0631086',
            '59.44007199999999',
          ],
        },
      },
      'ofstedRating': '',
      'totalNumberOfPupils': '1329',
      'distance': 1.6,
      'time': {
        'duration': 6,
        'unit': 'minute',
        'message': '6 minutes drive',
      },
    },
  ],
  'stations': [
    {
      'name': 'Ensta station',
      'address': '',
      'distance': 0.82,
      'coordinates': {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [
            '18.063606',
            '59.451946',
          ],
        },
      },
      'closestDestination': {
        'name': '',
        'priority': '4',
        'trainCounter': undefined,
        'meanTripTimeToClosest': undefined,
      },
      'time': {
        'duration': 13,
        'unit': 'minute',
        'message': '13 minutes walk',
      },
    },
    {
      'name': 'Tibble station',
      'address': '',
      'distance': 1.38,
      'coordinates': {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [
            '18.062565',
            '59.44214',
          ],
        },
      },
      'closestDestination': {
        'name': '',
        'priority': '4',
        'trainCounter': undefined,
        'meanTripTimeToClosest': undefined,
      },
      'time': {
        'duration': 4,
        'unit': 'minute',
        'message': '4 minutes drive',
      },
    },
    {
      'name': 'Visinge station',
      'address': '',
      'distance': 1.39,
      'coordinates': {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [
            '18.063197',
            '59.462738',
          ],
        },
      },
      'closestDestination': {
        'name': '',
        'priority': '4',
        'trainCounter': undefined,
        'meanTripTimeToClosest': undefined,
      },
      'time': {
        'duration': 7,
        'unit': 'minute',
        'message': '7 minutes drive',
      },
    },
  ],
  'locationService': {
    'getGeoCodes': {
      id: null,
      type: null,
      placeType: null,
      'placeName': 'Turebergsvägen 31, 187 32 Täby, Sverige',
      'geometry': {
        'type': 'Point',
        'coordinates': [
          18.049_116,
          59.452_455_9,
        ],
      },
      'postcode': '187 32',
    },
  },
};
export const demoFiles: FilesTable[] = [
  {
    _id: '1',
    name: 'EllaAndPark_bostaderna.jpg',
    'url': 'https://storage.googleapis.com/caclulator-node-bucket/file_03cec223-4e13-4838-81b2-6cba915794f4.jpeg',
    fileType: 'jpg',
    size: 2300,
    uploaded: new Date().toISOString(),
    uploadedBy: 'Artem Titov',
    requested: new Date().toISOString(),
    requestedBy: 'Artem Titov',
    section: 'Lead',
    isUploaded: true,
  },
  {
    _id: '2',
    name: 'kök_closeup.jpg',
    'url': 'https://storage.googleapis.com/caclulator-node-bucket/file_2268ed80-655b-4554-a927-a2d9f7ad3b90.jpeg',
    fileType: 'jpg',
    size: 2420,
    uploaded: new Date().toISOString(),
    uploadedBy: 'Artem Titov',
    requested: new Date().toISOString(),
    requestedBy: 'Artem Titov',
    section: 'Research',
    isUploaded: true,
  },
  {
    _id: '3',
    name: 'kitchen_island.jpg',
    'url': 'https://storage.googleapis.com/caclulator-node-bucket/file_036ebc55-3ef3-4b5b-a123-14f41d7f670c.jpeg',
    fileType: 'jpg',
    size: 512,
    uploaded: new Date().toISOString(),
    uploadedBy: 'Artem Titov',
    requested: new Date().toISOString(),
    requestedBy: 'Artem Titov',
    section: 'Financing',
    isUploaded: true,
  },
  {
    _id: '4',
    name: 'EllaAndPark_vardagsrum.jpg',
    'url': 'https://storage.googleapis.com/caclulator-node-bucket/file_aff262f5-efca-4ad6-90e1-5f70f42e31c7.jpeg',
    fileType: 'jpg',
    size: 2270,
    uploaded: new Date().toISOString(),
    uploadedBy: 'Artem Titov',
    requested: new Date().toISOString(),
    requestedBy: 'Artem Titov',
    section: 'Asset',
    isUploaded: true,
  },
  {
    _id: '5',
    name: 'EllaAndPark_badrum.jpg',
    'url': 'https://storage.googleapis.com/caclulator-node-bucket/file_156ed0d8-1606-44cb-aba8-87cb31463b0c.jpeg',
    fileType: 'jpg',
    uploaded: new Date().toISOString(),
    uploadedBy: 'Artem Titov',
    requested: new Date().toISOString(),
    requestedBy: 'Artem Titov',
    section: 'Tendering',
    isUploaded: true,
  },
];

demoScenario.lead = {
  ...demoLeads[0],
  cadastralId: 'SWE56345',
  location: {
    address: demoScenario.locationService.getGeoCodes.placeName!,
    coordinates: demoScenario.locationService.getGeoCodes.geometry.coordinates!,
  },
  files: demoFiles,
  contactDetails: {
    email: 'jane.cooper@gmail.com',
    phone: '+46-12-345-6789',
    firstName: 'Jane',
    lastName: 'Cooper',
    company: 'Real Estate Company',
  },
  localArea: demoScenario.localArea,
  stations: demoScenario.stations,
  schools: demoScenario.schools,
  country: demoScenario.calculate.input.country as CountryCode,
  localMarket: demoScenario.localMarket,
};

export const demoResearches: Research[] = [
  {
    leadName: 'Igelsta Strandentré',
    assignees: [{
      profileLink: 'analyst0',
      name: 'Victor Klintåker',
      _id: '0',
      designation: 'Analyst',
    }],
    source: 'Owner Request',
    status: 'Approved',
    location: { address: 'Spadvägen, Heby, Sweden' },
    _id: '0',
    project: {
      _id: demoScenario.projectId,
      name: 'Igelsta Strandentré',
      scenario: {
        updatedAt: 'Today',
        ...demoScenario,
        calculate: {
          ...demoScenario.calculate,
          output: {
            ...demoScenario.calculate.output,
            GDV: 120_850_000,
            'Profit on GDV': 10_633_500,
          },
        },
      },
    },
  },
  {
    leadName: 'Gökegårds Tun',
    assignees: [{
      profileLink: 'analyst0',
      name: 'Victor Klintåker',
      _id: '0',
      designation: 'Analyst',
    }],
    _id: '1',
    source: 'Owner Request',
    status: 'Approved',
    location: { address: 'Kumla Trädgårdsgata, Uppsala, Swedenjkh' },
    project: {
      _id: demoScenario.projectId,
      name: 'Gökegårds Tun',
      scenario: {
        updatedAt: '1 day ago',
        ...demoScenario,
        calculate: {
          ...demoScenario.calculate,
          output: {
            ...demoScenario.calculate.output,
            GDV: 90_850_000,
            'Profit on GDV': 4_870_330,
          },
        },
      },
    },
  },
  {
    leadName: 'Igelsta Loft',
    assignees: [{
      name: 'Johan Plyhr',
      profileLink: 'analyst1',
      _id: '1',
      designation: 'Analyst',
    }],
    location: { address: 'Herrhamra, Nynäshamn, Sweden' },
    source: 'Owner Request',
    status: 'In Progress',
    _id: '2',
    project: {
      _id: demoScenario.projectId,
      name: 'Igelsta Loft',
      scenario: {
        ...demoScenario,
        updatedAt: '2 days ago',
        calculate: {
          ...demoScenario.calculate,
          output: {
            ...demoScenario.calculate.output,
            GDV: 181_150_000,
            'Profit on GDV': 45_279_000,
          },
        },
      },
    },
  },
  {
    leadName: 'Älvsjö Loft',
    assignees: [{
      name: 'Johan Plyhr',
      profileLink: 'analyst1',
      _id: '1',
      designation: 'Analyst',
    }],
    _id: '3',
    source: 'Owner Request',
    status: 'Rejected',
    location: { address: 'Ösmo, Sweden' },
    project: {
      _id: demoScenario.projectId,
      name: 'Älvsjö Loft',
      scenario: {
        updatedAt: '2 days ago',
        ...demoScenario,
        calculate: {
          ...demoScenario.calculate,
          output: {
            ...demoScenario.calculate.output,
            GDV: 61_950_000,
            'Profit on GDV': 14_498_000,
          },
        },
      },
    },
  },
  {
    leadName: 'Gunhild Ägarlägenheter',
    _id: '4',
    assignees: [{
      name: 'Victor Klintåker',
      profileLink: 'analyst0',
      _id: '0',
      designation: 'Analyst',
    }],
    source: 'Owner Request',
    status: 'In Progress',
    location: { address: 'Herbert Widmans väg, Sköndal, Sweden' },
    project: {
      _id: demoScenario.projectId,
      name: 'Gunhild Ägarlägenheter',
      scenario: {
        updatedAt: '3 days ago',
        ...demoScenario,
        calculate: {
          ...demoScenario.calculate,
          output: {
            ...demoScenario.calculate.output,
            GDV: 432_160_000,
            'Profit on GDV': 2_400_000,
          },
        },
      },
    },
  },
];

export const demoUserList: User[] = [
  {
    id: 'analyst0',
    name: 'Victor',
    surname: 'Klintåker',
    role: 'user',
    roleNP: 'project-manager',
    email: 'victor@example.com',
    phoneNumber: '(225) 555-0118',
  },
  {
    id: 'analyst1',
    name: 'Johan',
    surname: 'Plyhr',
    role: 'user',
    roleNP: 'analyst',
    email: 'johan@example.com',
    phoneNumber: '(225) 555-0118',
  },
];

export const demoProject: Project = {
  _id: demoScenario.projectId,
  name: demoScenario.name!,
  scenario: demoScenario,
};

export const demoDetailsView: DetailViewData = {
  lead: demoScenario.lead,
  project: demoProject,
};

export const demoPartnersSuppliers: PartnerItemType[] = [
  {
    _id: '0',
    companyName: 'Derome Husproduktion AB',
    companySubType: ['General Contractor', 'Factory'],
    productAmount: 8,
    companyType: 'Supplier',
    designation: 'Sales Manager',
    companyContactName: 'Tobias Adolfsson',
    companyContactEmail: 'jofn.smith@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
  {
    _id: '1',
    companyName: 'Skanska AB',
    companySubType: ['Factory'],
    productAmount: 8,
    companyType: 'Supplier',
    designation: 'Sales Manager',
    companyContactName: 'Tobias Adolfsson',
    companyContactEmail: 'jofn.smith@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
  {
    _id: '2',
    companyName: 'NCC',
    companySubType: ['Construction Company'],
    productAmount: 8,
    companyType: 'Supplier',
    designation: 'Sales Manager',
    companyContactName: 'Tobias Adolfsson',
    companyContactEmail: 'jofn.smith@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
  {
    _id: '3',
    companyName: 'JM AB',
    companySubType: ['Development Partner'],
    productAmount: 8,
    companyType: 'Supplier',
    designation: 'Sales Manager',
    companyContactName: 'Tobias Adolfsson',
    companyContactEmail: 'jofn.smith@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
  {
    _id: '4',
    companyName: 'Serneke',
    companySubType: ['Project Management'],
    productAmount: 8,
    companyType: 'Supplier',
    designation: 'Sales Manager',
    companyContactName: 'Tobias Adolfsson',
    companyContactEmail: 'jofn.smith@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
];
export const demoPartnersEquityPartners: PartnerItemType[] = [
  {
    _id: '5',
    companyName: 'Pareto Securities',
    designation: 'Sales Manager',
    companyType: 'Equity Partner',
    companySubType: ['Investment bank'],
    companyContactName: 'Tobias Adolfsson',
    companyContactEmail: 'jofn.smith@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
  {
    _id: '6',
    companyName: 'Vinga Securities AB',
    designation: 'Sales Manager',
    companyType: 'Equity Partner',
    companySubType: ['Investment club'],
    companyContactName: 'Marvin McKinney',
    companyContactEmail: 'marvin.mckinney@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
  {
    _id: '7',
    companyName: 'Carnegie',
    designation: 'Sales Manager',
    companyType: 'Equity Partner',
    companySubType: ['Crowd'],
    companyContactName: 'Eleanor Pena',
    companyContactEmail: 'eleanor.pena@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
  {
    _id: '8',
    companyName: 'Coeli',
    designation: 'Sales Manager',
    companyType: 'Equity Partner',
    companySubType: ['Asset Manager'],
    companyContactName: 'Wade Warren',
    companyContactEmail: 'wade.warren@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
  {
    _id: '9',
    companyName: 'G&W Fondkommission',
    designation: 'Sales Manager',
    companyType: 'Equity Partner',
    companySubType: ['Family Office'],
    companyContactName: 'Cody Fisher',
    companyContactEmail: 'cody.fisher@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
];
export const demoPartnersBanks: PartnerItemType[] = [
  {
    _id: '10',
    companyName: 'Nordea Bank',
    designation: 'Sales Manager',
    companyType: 'Bank',
    companySubType: ['Investment bank'],
    companyContactName: 'Tobias Adolfsson',
    companyContactEmail: 'jofn.smith@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
  {
    _id: '11',
    companyName: 'Svenska Handelsbanken',
    designation: 'Sales Manager',
    companyType: 'Bank',
    companySubType: ['Bank'],
    companyContactName: 'Tobias Adolfsson',
    companyContactEmail: 'jofn.smith@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
  {
    _id: '12',
    companyName: 'Swedbank',
    designation: 'Sales Manager',
    companyType: 'Bank',
    companySubType: ['Fund'],
    companyContactName: 'Tobias Adolfsson',
    companyContactEmail: 'jofn.smith@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
  {
    _id: '13',
    companyName: 'Länsförsäkringar Bank',
    designation: 'Sales Manager',
    companyType: 'Bank',
    companySubType: ['Crowd'],
    companyContactName: 'Tobias Adolfsson',
    companyContactEmail: 'jofn.smith@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
  {
    _id: '14',
    companyName: 'Länsförsäkringar Bank',
    designation: 'Sales Manager',
    companyType: 'Bank',
    companySubType: ['Bank'],
    companyContactName: 'Tobias Adolfsson',
    companyContactEmail: 'jofn.smith@gmail.com',
    companyContactPhone: '+46-87-562-9108',
  },
];

export const demoSuppliersProducts: SupplierProductType[] = [
  {
    _id: '0',
    name: 'Hagagläntan',
    type: 'Apartment building',
    roofAngle: 13,
    buildSystem: 'Modules',
    pricePerSqm: 21_000,
    minFloors: 4,
    maxFloors: 6,
    buildTimeFrameInMonths: 12,
    idealMinBoaInSqm: 1800,
    idealMaxBoaInSqm: 2400,
    primaryMaterial: 'Wood',
  },
  {
    _id: '1',
    name: 'The Pillar Hall and the Arcade',
    type: 'Apartment building',
    buildSystem: 'Plan elements',
    pricePerSqm: 22_000,
    minFloors: 6,
    maxFloors: 8,
    buildTimeFrameInMonths: 12,
    idealMinBoaInSqm: 1800,
    idealMaxBoaInSqm: 2400,
    primaryMaterial: 'Wood',
  },
  {
    _id: '2',
    name: 'Hagagläntan',
    type: 'Apartment building',
    buildSystem: 'Modules',
    pricePerSqm: 21_000,
    minFloors: 2,
    maxFloors: 6,
    buildTimeFrameInMonths: 12,
    idealMinBoaInSqm: 1800,
    idealMaxBoaInSqm: 2400,
    primaryMaterial: 'Wood',
  },
  {
    _id: '3',
    name: 'Pilgläntan',
    type: 'Apartment building',
    buildSystem: 'Modules',
    pricePerSqm: 21_000,
    minFloors: 5,
    maxFloors: 6,
    buildTimeFrameInMonths: 12,
    idealMinBoaInSqm: 1800,
    idealMaxBoaInSqm: 2400,
    primaryMaterial: 'Wood',
  },
  {
    _id: '4',
    name: 'Hagagläntan',
    type: 'Apartment building',
    buildSystem: 'Modules',
    pricePerSqm: 21_000,
    minFloors: 4,
    maxFloors: 6,
    buildTimeFrameInMonths: 12,
    idealMinBoaInSqm: 1800,
    idealMaxBoaInSqm: 2400,
    primaryMaterial: 'Wood',
  },
];


export const demoBanksProducts: BankProductType[] = [
  {
    _id: '1',
    name: 'Product 1',
    subType: 'Senior',
    monthlyRateStartingFrom: 1.5,
    fees: 100,
    minLoan: 10_000,
    maxLoan: 50_000,
    maxLtv: 80,
    maxLtgdv: 70,
    minLoanTermInMonths: 12,
    maxLoanTermInMonths: 60,
    payoutTerms: 'Monthly',
    repaymentTerms: 'Fixed',
    description: 'Description for Product 1',
  },
  {
    _id: '2',
    name: 'Product 2',
    subType: 'Junior',
    monthlyRateStartingFrom: 1.7,
    fees: 150,
    minLoan: 15_000,
    maxLoan: 60_000,
    maxLtv: 75,
    maxLtgdv: 65,
    minLoanTermInMonths: 12,
    maxLoanTermInMonths: 72,
    payoutTerms: 'Monthly',
    repaymentTerms: 'Variable',
    description: 'Description for Product 2',
  },
  {
    _id: '3',
    name: 'Product 3',
    subType: 'Senior',
    monthlyRateStartingFrom: 1.6,
    fees: 120,
    minLoan: 12_000,
    maxLoan: 55_000,
    maxLtv: 78,
    maxLtgdv: 68,
    minLoanTermInMonths: 12,
    maxLoanTermInMonths: 64,
    payoutTerms: 'Monthly',
    repaymentTerms: 'Fixed',
    description: 'Description for Product 3',
  },
  {
    _id: '4',
    name: 'Product 4',
    subType: 'Junior',
    monthlyRateStartingFrom: 1.8,
    fees: 130,
    minLoan: 13_000,
    maxLoan: 70_000,
    maxLtv: 70,
    maxLtgdv: 60,
    minLoanTermInMonths: 12,
    maxLoanTermInMonths: 72,
    payoutTerms: 'Monthly',
    repaymentTerms: 'Variable',
    description: 'Description for Product 4',
  },
];

export const defaultAssetsSections: AssetSections = {
  gallery: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  aboutProject: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  ourRequest: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  siteAndPurchase: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  proposedUnits: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  construction: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  finance: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  sales: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  timelineAndCashflow: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  sensitivityAnalysis: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  projectMultiples: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  localArea: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  localMarket: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  comparables: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
};
