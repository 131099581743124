import { Avatar, Button, ListItem, ListItemAvatar, ListItemText, TableCell, TableRow } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { take } from 'lodash';
import { MouseEventHandler, useMemo } from 'react';
import { ArrowIcon } from 'src/assets/svg';
import { MoreOptions, OptionType } from 'src/components';
import { allChipColors, DEFAULT_CURRENCY } from 'src/constants/constants';
import { usePopover } from 'src/hooks';
import { fDate, fNumber, fPercent } from 'src/utils';

import { FinancialTableType, OptionBlockKey } from '../../../../../types';

export interface FinancialTableRowProps {
  row: FinancialTableType;
  btnTitle?: string;
  showIsCalculate?: boolean;
  btnOnClickHandler?: MouseEventHandler<HTMLButtonElement>;
  btnConfig?: ButtonProps;
  moreOptionBlockKey?: OptionBlockKey;
  resendRequestClickHandler?: VoidFunction;
  seeDetailsClickHandler?: VoidFunction;
  uploadTermSheetClickHandler?: VoidFunction;
  deleteClickHandler?: VoidFunction;
  editClickHandler?: VoidFunction;
  requestFinancingAgreement?: VoidFunction;
  uploadDebtFinancingDocuments?: VoidFunction;
  uploadDebtFinancingAgreement?: VoidFunction;
}

export const FinancialTableRow = (props: FinancialTableRowProps) => {
  const {
    row,
    btnTitle,
    btnConfig,
    btnOnClickHandler,
    moreOptionBlockKey,
    showIsCalculate,
    resendRequestClickHandler,
    seeDetailsClickHandler,
    uploadTermSheetClickHandler,
    deleteClickHandler,
    uploadDebtFinancingDocuments,
    uploadDebtFinancingAgreement,
    editClickHandler,
  } = props;
  const popover = usePopover();
  const termSheetPopover = usePopover();

  const moreOptions: { [key in OptionBlockKey]: OptionType[] } = useMemo(() => {
    const isDebtFinancingAgreementDocumentsUploaded = row.debtFinancingAgreement?.documents?.every(file => file.uploaded);
    const agreementOption = row.debtFinancingAgreement?.agreementFile ? null : {
      label: isDebtFinancingAgreementDocumentsUploaded ? 'Upload Agreement' : 'Upload Documents',
      itemOnClickHandler: isDebtFinancingAgreementDocumentsUploaded ? uploadDebtFinancingAgreement : uploadDebtFinancingDocuments,
      closePopover: popover.onClose,
    };

    return {
      debtFinancingAgreement: [
        agreementOption,
        {
          label: 'See Details',
          itemOnClickHandler: seeDetailsClickHandler,
          closePopover: popover.onClose,
        },
      ],
      termSheets: [
        row.status === 'Requested' ? {
          label: 'Upload Term Sheet',
          itemOnClickHandler: uploadTermSheetClickHandler,
          closePopover: popover.onClose,
        } : { hidden: true },
        {
          label: 'See Details',
          itemOnClickHandler: seeDetailsClickHandler,
          closePopover: popover.onClose,
        },
      ],
      offers: [
        {
          label: 'Delete',
          icon: 'delete',
          color: 'error.main',
          closePopover: popover.onClose,
          itemOnClickHandler: deleteClickHandler,
        },
        {
          label: 'Edit',
          icon: 'edit',
          closePopover: popover.onClose,
          itemOnClickHandler: editClickHandler,
        },
      ],
      preliminaryDebtRequests: [
        {
          label: 'Resend Request',
          itemOnClickHandler: resendRequestClickHandler,
        },
        {
          label: 'Delete',
          icon: 'delete',
          color: 'error.main',
          itemOnClickHandler: deleteClickHandler,
        },
      ],
    };
  }, [deleteClickHandler, editClickHandler, popover.onClose, resendRequestClickHandler, row.debtFinancingAgreement?.agreementFile, row.debtFinancingAgreement?.documents, row.status, seeDetailsClickHandler, uploadDebtFinancingAgreement, uploadDebtFinancingDocuments, uploadTermSheetClickHandler]);

  const customClickElement = (moreOptionBlockKey === 'termSheets' || moreOptionBlockKey === 'debtFinancingAgreement') ? (
    <Button
      size='small'
      variant='outlined'
      onClick={popover.onOpen}
      endIcon={<ArrowIcon style={{ rotate: '90deg' }}/>}
    >
      Action
    </Button>
  ) : null;

  const offerDate = moreOptionBlockKey === 'preliminaryDebtRequests' ? row.preliminaryDebtRequest?.date : row.offer?.date;
  const requestedDate = moreOptionBlockKey === 'preliminaryDebtRequests' ? row.preliminaryDebtRequest?.date : row.offer?.date;
  const totalFunds = moreOptionBlockKey === 'preliminaryDebtRequests' ? row.preliminaryDebtRequest?.totalFunds : row.offer?.totalFunds;
  const rate = moreOptionBlockKey === 'preliminaryDebtRequests' ? row.preliminaryDebtRequest?.rate : row.offer?.rate;
  const fee = moreOptionBlockKey === 'preliminaryDebtRequests' ? row.preliminaryDebtRequest?.fee : row.offer?.fee;
  const ltc = moreOptionBlockKey === 'preliminaryDebtRequests' ? row.preliminaryDebtRequest?.ltc : row.offer?.ltc;
  const ltgdv = moreOptionBlockKey === 'preliminaryDebtRequests' ? row.preliminaryDebtRequest?.ltgdv : row.offer?.ltgdv;

  return (
    <TableRow>
      <TableCell>
        <ListItem sx={{ p: 0 }}>
          <ListItemAvatar>
            <Avatar variant='rounded' alt='bankLogo' src={row.bankSrc}/>
          </ListItemAvatar>
          <ListItemText
            primary={row.bankName}
            secondary={((offerDate || requestedDate) && moreOptionBlockKey !== 'termSheets') ? fDate(offerDate) || fDate(requestedDate) : ''}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
        </ListItem>
      </TableCell>
      <TableCell>
        <ListItemText
          primary={`${fNumber(totalFunds)} ${DEFAULT_CURRENCY}`}
          secondary={fPercent(rate)}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      </TableCell>
      <TableCell>
        {fPercent(fee)}
      </TableCell>
      <TableCell>
        <ListItemText
          primary={fPercent(ltc)}
          secondary={fPercent(ltgdv)}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      </TableCell>
      <TableCell>
        <Chip
          size='small'
          variant='soft'
          color={allChipColors[row.type] ?? 'default'}
          label={row.type}
        />
      </TableCell>
      {row.status &&
        <TableCell>
          <Chip
            size='small'
            variant='soft'
            label={row.status}
            color={allChipColors[row.status] ?? 'default'}
          />
        </TableCell>
      }
      {showIsCalculate &&
        <TableCell>
          <Chip
            size='small'
            variant='outlined'
            label={row.offer?.recalculateStatus || 'Not used'}
            color={allChipColors[row.offer?.recalculateStatus || 'Not used']}
          />
        </TableCell>
      }
      <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
        {btnTitle &&
          <Button
            size='small'
            variant='outlined'
            onClick={btnOnClickHandler}
            sx={{ whiteSpa1ce: 'nowrap' }}
            {...btnConfig}
          >
            {btnTitle}
          </Button>
        }
        {moreOptionBlockKey &&
          <MoreOptions
            options={moreOptions[moreOptionBlockKey]}
            clickElement={customClickElement}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
            popoverOpen={popover.open}
          />
        }
        {(moreOptionBlockKey === 'termSheets' || moreOptionBlockKey === 'debtFinancingAgreement') &&
          <MoreOptions
            options={take(moreOptions['offers'], 1)}
            popoverOnClose={termSheetPopover.onClose}
            popoverOnOpen={termSheetPopover.onOpen}
            popoverOpen={termSheetPopover.open}
          />
        }
      </TableCell>
    </TableRow>
  );
};
