import { AttachFile } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { UploadCloudIcon } from 'src/assets/svg';
import { CustomSelect, DrawerCustom, getFileConfig, SearchField, UploadedFilesType } from 'src/components';
import { FileModal } from 'src/components/files/components';
import { sectionsList } from 'src/constants/constants';
import { UseBooleanReturnType } from 'src/hooks';
import { fNumber, openInNewTab } from 'src/utils';

import { FinanceUploadFileType } from '../../../../../../../../types';

interface AddDocumentsProps {
  drawer: UseBooleanReturnType;
  modalUploadFileHandler: (file: FinanceUploadFileType) => void;
  onAdd: (documents: UploadedFilesType[]) => void;
  documentsList?: UploadedFilesType[];
  alreadySelectedDocs?: UploadedFilesType[];
  headTitle?: string;
  isAttachButton?: boolean;
  onCloseDrawerHandler?: VoidFunction;
  btnSx?: SxProps<Theme>;
}

export const AddDocuments = (props: AddDocumentsProps) => {
  const {
    drawer,
    modalUploadFileHandler,
    onCloseDrawerHandler,
    onAdd,
    btnSx,
    isAttachButton = false,
    headTitle = 'Documents',
    documentsList = [],
    alreadySelectedDocs = [],
  } = props;
  const theme = useTheme();
  const [isUploadOpen, setUploadOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [selectedDocs, setSelectedDocs] = useState<UploadedFilesType[]>([]);
  const [section, setSection] = useState<string>('');
  const selectedIds = selectedDocs.map(doc => doc._id);

  useEffect(() => {
    if (drawer.value) {
      setSelectedDocs(alreadySelectedDocs);
    }
    //   eslint-disable-next-line
  }, [drawer.value]);

  const filteredDocs: UploadedFilesType[] = useMemo(() => {
    if (!search && !section) return documentsList;
    const searchLow = search.trim().toLowerCase();

    const bySearch = searchLow ? documentsList.filter((doc) => doc.filename?.trim().toLowerCase().includes(searchLow)) : documentsList;

    return section ? bySearch.filter((doc) => doc.section === section) : bySearch;
  }, [documentsList, search, section]);

  const toggleUpload = (value: boolean) => () => {
    setUploadOpen(value);
  };

  const toggleCheckbox = (file: UploadedFilesType) => () => {
    const selected = selectedIds.includes(file._id);
    if (selected) {
      const selectedDocuments = selectedDocs.filter(doc => doc._id !== file._id);
      setSelectedDocs(selectedDocuments);
    } else {
      const selectedDocuments = [...selectedDocs, file];
      setSelectedDocs(selectedDocuments);
    }
  };

  const onAddClick = () => {
    onAdd(selectedDocs);
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const onSectionChange = (e: SelectChangeEvent<string[]>) => {
    const v = e.target.value;
    if (!v || !v.length) {
      setSection('');
    } else {
      setSection(v as string);
    }
  };

  const openFileClickHandler = (file: UploadedFilesType) => () => {
    openInNewTab(file.url);
  };

  return (
    <>
      <DrawerCustom
        containerSx={{ width: 480 }}
        open={drawer.value}
        onCloseDrawerHandler={onCloseDrawerHandler || drawer.onFalse}
        headChildren={
          <Stack
            width='100%'
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
          >
            <Typography variant='h6'>{headTitle}</Typography>
            <Button
              onClick={toggleUpload(true)}
              startIcon={<UploadCloudIcon/>}
              variant='text'
              size='small'
            >
              Upload document
            </Button>
          </Stack>
        }
        bodyChildren={
          <Stack gap={4}>
            <Stack direction='row' gap={1.5}>
              <Box sx={{ flex: 1 }}>
                <CustomSelect
                  controlSx={{ width: '100%' }}
                  value={section ? [section] : []}
                  handleSelectOnChange={onSectionChange}
                  label='Section'
                  options={sectionsList}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <SearchField
                  value={search}
                  inputSx={{ width: 260 }}
                  handleFieldOnChange={onSearchChange}
                  isFullWidth
                />
              </Box>
            </Stack>
            <Stack gap={3}>
              {filteredDocs.map(doc =>
                <Stack
                  key={doc._id}
                  gap={1}
                  direction='row'
                  alignItems='center'
                >
                  <Checkbox
                    checked={selectedIds.includes(doc._id)}
                    onChange={toggleCheckbox(doc)}
                    size='medium'
                    color='primary'
                  />
                  <Box
                    flexDirection='row'
                    display='flex'
                    sx={{ cursor: 'pointer' }}
                    gap={1}
                    width='100%'
                    alignItems='center'
                    onClick={openFileClickHandler(doc)}
                  >
                    {getFileConfig(doc.filetype).icon}
                    <Stack gap={0.5}>
                      <Typography variant='subtitle2'>
                        {doc.filename}
                      </Typography>
                      <Typography
                        variant='caption'
                        color={theme.palette.text.disabled}
                      >
                        {doc.section}
                      </Typography>
                    </Stack>
                    {(doc.size || doc.filesize) &&
                      <Typography
                        variant='subtitle2'
                        sx={{
                          ml: 'auto',
                          textAlign: 'right',
                        }}
                      >
                        {fNumber(doc.size || doc.filesize)} KB
                      </Typography>
                    }
                  </Box>
                </Stack>,
              )}
            </Stack>
          </Stack>
        }
        footerChildren={
          <Button
            fullWidth={!isAttachButton}
            onClick={onAddClick}
            disabled={!selectedDocs.length}
            variant='contained'
            size='large'
            sx={btnSx}
            startIcon={isAttachButton ? <AttachFile/> : null}
          >
            {isAttachButton ? 'Attach' : 'Add Documents'}
          </Button>
        }
      />

      <FileModal
        openModal={isUploadOpen}
        modalUploadFileHandler={modalUploadFileHandler}
        onClose={toggleUpload(false)}
      />
    </>
  );
};
