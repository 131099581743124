import { Button, Stack } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CrumbType, DetailView, MoreOptions, OptionType } from 'src/components';
import { paths } from 'src/config';
import { allChipColors } from 'src/constants/constants';
import { usePopover, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { deleteLeadThunk } from 'src/store/lead';
import { researchClickHelper } from 'src/utils';

export const LeadDetails = () => {
  const { id } = useParams<{
    id: string,
  }>();

  const dispatch = useDispatch();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const proverestUrl = useSelector((state: RootState) => state.App.config?.proverestUrl);
  const currentLead = detailsData?.lead;
  const popover = usePopover();
  const router = useRouter();

  const goToLeadList = () => {
    router.push(paths.leads.list);
  };

  const deleteHandler = () => {
    dispatch(deleteLeadThunk(id, goToLeadList));
  };

  const crumbs: CrumbType[] = [
    {
      title: 'Opportunities',
      href: paths.leads.list,
    },
    {
      title: 'Leads',
      href: paths.leads.list,
    },
    { title: currentLead?.location?.address },
  ];

  const onAssignCloudBtnClick = useCallback(() => {
    router.replace('team');
  }, [router]);


  const leadDetailsMoreOption: OptionType[] = [
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: deleteHandler,
      color: 'error.main',
    },
  ];

  const editCLickHandler = () => {
    router.push(paths.leads.edit(id));
  };

  const toResearchClickHandler = useCallback(() => {
    researchClickHelper(proverestUrl, id, detailsData?.project?.scenario);
  }, [detailsData?.project?.scenario, id, proverestUrl]);

  return (
    <DetailView
      showToolbarBadge
      showOverviewCloud
      crumbs={crumbs}
      componentsViews='lead'
      showData={Boolean(currentLead)}
      toolbarTitle={currentLead?.location?.address}
      toolbarBadgeTitle={currentLead?.status}
      toolbarChildren={
        <Stack spacing={1.5} direction='row'>
          <Button variant='outlined' onClick={editCLickHandler}>Edit</Button>
          <Button variant='contained' sx={{ whiteSpace: 'nowrap' }} onClick={toResearchClickHandler}>
            To research
          </Button>
          <MoreOptions
            options={leadDetailsMoreOption}
            popoverOpen={popover.open}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
          />
        </Stack>
      }
      toolbarBadgeVariant='outlined'
      toolbarBadgeColor={allChipColors[currentLead?.status ?? 'Waiting for Review']}
      cloudProps={{ onBtnClickHandler: onAssignCloudBtnClick }}
    />
  );
};
