import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CrumbType, DetailView, Iconify, MoreOptions, OptionType } from 'src/components';
import { paths } from 'src/config';
import { allChipColors } from 'src/constants/constants';
import { usePopover, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { getOfferingDetail } from 'src/store/partners-portal';
import { copyToClipboard, getMarketplaceUrl, openInNewTab } from 'src/utils';

type ParamsType = {
  marketplaceId: string;
  id: string;
  tab: string;
}

type ProjectDetailsProps = {
  unlistProject?: (id: string) => void;
  isPartner?: boolean;
  isPartnerOffering?: boolean;
}

export const ProjectDetails = (props: ProjectDetailsProps) => {
  const {
    isPartner,
    isPartnerOffering,
    unlistProject,
  } = props;
  const theme = useTheme();
  const router = useRouter();
  const popover = usePopover();
  const dispatch = useDispatch();
  const { id } = useParams<ParamsType>();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const reportConfigurations = detailsData.offeringDetails?.reportConfigurations;
  const { enqueueSnackbar } = useSnackbar();

  const crumbs = useMemo<CrumbType[]>(() => {
    if (isPartnerOffering) {
      return [
        { title: 'Investments' },
        { title: 'Ongoing Offerings' },
        {
          title: detailsData.project?.name,
          color: theme.palette.text.disabled,
        },
      ];
    }

    if (isPartner) {
      return [
        {
          title: 'Investments',
          href: paths.partnerPortal.opportunities.list,
        },
        {
          title: 'New Opportunities',
          href: paths.partnerPortal.opportunities.list,
        },
        {
          title: detailsData.project?.name,
          color: theme.palette.text.disabled,
        },
      ];
    }

    return [
      { title: 'Marketplaces' },
      // TODO change to marketplace name
      { title: detailsData.project?.name },
      {
        title: detailsData.project?.name,
        color: theme.palette.text.disabled,
      },
    ];
  }, [detailsData.project?.name, isPartner, isPartnerOffering, theme.palette.text.disabled]);

  const onMarketplaceClick = () => {
    openInNewTab(getMarketplaceUrl(id));
  };

  const onShareClick = () => {
    copyToClipboard(getMarketplaceUrl(id));
    enqueueSnackbar('Link copied!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  const onPublishClick = () => {
    router.push('offering-publish');
  };

  const customDetailsFc = useCallback(() => {
    dispatch(getOfferingDetail(id));
  }, [dispatch, id]);

  const moreOptions: OptionType[] = [
    {
      label: 'Unlist',
      icon: 'delete',
      color: 'error.main',
      closePopover: popover.onClose,
      itemOnClickHandler: () => {
        unlistProject?.(id);
      },
    },
    {
      label: 'Edit',
      icon: 'edit',
      closePopover: popover.onClose,
      itemOnClickHandler: onPublishClick,
    },
  ];

  return (
    <DetailView
      useCustomFiles
      showGeneralReportModified
      showGeneralReport={false}
      showGeneralReportOriginal={!!reportConfigurations?.generalReport}
      showBankReports={!!reportConfigurations?.bankReport}
      showSupplierReports={!!reportConfigurations?.supplierReport}
      customFiles={detailsData.assets?.documents}
      showToolbarBadge={!isPartner}
      crumbs={crumbs}
      isPartnerPortal={isPartner}
      isPartnerOffering={isPartnerOffering}
      toolbarBadgeTitle={detailsData.offeringDetails?.status}
      toolbarBadgeVariant='outlined'
      toolbarBadgeColor={allChipColors[detailsData.offeringDetails?.status || 'All']}
      customDetailsFc={customDetailsFc}
      componentsViews='marketplaceProject'
      toolbarTitle={detailsData.project?.name}
      toolbarChildren={
        <Stack direction='row' gap={1.5}>
          {!isPartner
            ? <>
              <Button
                onClick={onMarketplaceClick}
                startIcon={<Iconify icon='solar:eye-bold'/>}
                variant='outlined'
                size='medium'
              >
                Open On Marketplace
              </Button>
              <Button
                onClick={onShareClick}
                variant='contained'
                size='medium'
              >
                Share
              </Button>
            </>
            : <Button
              onClick={onPublishClick}
              variant='contained'
              size='medium'
            >
              Edit
            </Button>
          }

          {isPartnerOffering &&
            <MoreOptions
              options={moreOptions}
              popoverOnClose={popover.onClose}
              popoverOnOpen={popover.onOpen}
              popoverOpen={popover.open}
            />
          }
        </Stack>
      }
    />
  );
};
