import { paths } from 'src/config';

import { Asset, DashboardInterface, FinancialType, Lead, QuotationType } from '../../../types';
import { ChipKeyVariants } from '../constants/constants';


export type GetCompletedProjectStepsParams = {
  lead?: Lead;
  assets?: Asset;
  tendering?: QuotationType[];
  financing?: FinancialType;
}

export type OrderStepKey = 'pp' | 'suppliers' | 'debt' | 'equity' | 'sell'
export type OrderItemType = {
  orderNumber: number;
  isCompleted: boolean;
  isChecked: boolean;
}

export const isPrevStepsCompleted = (currentStep: OrderStepKey, params: GetCompletedProjectStepsParams) => {
  const {
    isEquityCompleted,
    isTenderingCompleted,
    isDebtCompleted,
    isProjectPreparationCompleted,
  } = getCompletedProjectSteps(params);
  const { lead } = params;
  const distribution = lead?.distribution;
  const order: Record<OrderStepKey, OrderItemType> = {
    pp: {
      orderNumber: 0,
      isCompleted: isProjectPreparationCompleted,
      isChecked: true,
    },
    suppliers: {
      orderNumber: 1,
      isCompleted: isTenderingCompleted,
      isChecked: !!distribution?.tendering,
    },
    debt: {
      orderNumber: 2,
      isCompleted: isDebtCompleted,
      isChecked: !!distribution?.debtFinancing,
    },
    equity: {
      orderNumber: 3,
      isCompleted: isEquityCompleted,
      isChecked: !!distribution?.equityFinancing,
    },
    sell: {
      orderNumber: 4,
      isCompleted: false,
      isChecked: !!distribution?.sell,
    },
  };
  const currentItem = order[currentStep];

  for (let i = 0; i < currentItem.orderNumber; i++) {
    const prevStep = Object.values(order)[i];
    if (prevStep.isChecked && !prevStep.isCompleted) {
      return false;
    }
  }

  return true;
};

export const getCompletedProjectSteps = (params: GetCompletedProjectStepsParams) => {
  const {
    lead,
    assets,
    tendering,
    financing,
  } = params;
  const distribution = lead?.distribution;

  const isProjectPreparationCompleted = Boolean(
    assets?.general?.projectName
    && assets?.general.offeringClosingDate
    && assets?.marketPlaceListing?.coverImages?.length || 0 > 0
    && assets?.marketPlaceListing?.description?.length || 0 > 0
    && (distribution?.tendering || distribution?.debtFinancing
      || distribution?.equityFinancing || distribution?.sell
    ),
  );

  const isTenderingCompleted = Boolean(tendering?.find(item => item.status === 'Term Sheet'));
  const isDebtCompleted = Boolean(financing?.termSheets?.find(item => item.status === 'Signed'));
  const isEquityCompleted = true;

  return {
    isProjectPreparationCompleted,
    isTenderingCompleted,
    isDebtCompleted,
    isEquityCompleted,
  };
};

type SectionInfo = {
  statusKey: keyof DashboardInterface;
  finalStatuses: string[];
  link: (dashboard: DashboardInterface) => string;
};

const sectionInfoList: SectionInfo[] = [
  {
    statusKey: 'researchStatus',
    finalStatuses: ['Approved', 'Rejected'],
    link: (dashboard) => paths.research.details(dashboard.lead?._id || ''),
  },
  {
    statusKey: 'preparationStatus',
    finalStatuses: ['Done'],
    link: (dashboard) => paths.projectPreparation.details(dashboard.lead?._id || ''),
  },
  {
    statusKey: 'tenderingStatus',
    finalStatuses: ['Done'],
    link: (dashboard) => paths.tendering.details(dashboard.lead?._id || ''),
  },
  {
    statusKey: 'debtStatus',
    finalStatuses: ['Done'],
    link: (dashboard) => paths.financing.details(dashboard.lead?._id || ''),
  },
  {
    statusKey: 'equityStatus',
    finalStatuses: ['Raised'],
    link: (dashboard) => paths.readyForInvestors.details(dashboard.lead?._id || ''),
  },
  {
    statusKey: 'saleStatus',
    finalStatuses: ['Sold'],
    link: (dashboard) => paths.sell.details(dashboard.lead?._id || ''),
  },
];

export const getCurrentSectionLink = (dashboard: DashboardInterface): string | undefined => {
  let lastCompletedSectionLink: string | undefined = undefined;

  for (const sectionInfo of sectionInfoList) {
    const status = dashboard[sectionInfo.statusKey] as ChipKeyVariants | undefined;
    if (status === 'Not used' || !status) {
      continue;
    }
    if (!sectionInfo.finalStatuses.includes(status)) {
      return sectionInfo.link(dashboard);
    }
    if (sectionInfo.finalStatuses.includes(status)) {
      lastCompletedSectionLink = sectionInfo.link(dashboard);
    }
  }

  return lastCompletedSectionLink;
};
