import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { StyledTableRow } from 'src/components';
import { allChipColors, DEFAULT_CURRENCY } from 'src/constants/constants';
import { fNumber, fPercent } from 'src/utils';

import { PartnerTransactionType } from '../../../../../../../types';

interface TransactionTableRowProps {
  row: PartnerTransactionType;
}

export const TransactionTableRow = (props: TransactionTableRowProps) => {
  const { row } = props;
  const theme = useTheme();

  return (
    <StyledTableRow hover>
      <TableCell>
        <Typography variant='body2' color='text.primary'>{row.projectName}</Typography>
        <Typography variant='caption' color={theme.palette.text.secondary}>{row.projectType}</Typography>
      </TableCell>

      <TableCell>
        <Typography
          sx={{ ml: 'auto' }}
          variant='body2'
          color='text.primary'
        >
          {fNumber(row.commission)} {DEFAULT_CURRENCY}
        </Typography>
        <Typography
          sx={{
            ml: 'auto',
            display: 'block',
          }}
          variant='caption'
          color={theme.palette.text.secondary}
        >
          {fPercent(row.fee)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          sx={{ ml: 'auto' }}
          variant='body2'
          color='text.primary'
        >
          {fNumber(row.investedAmount)} {DEFAULT_CURRENCY}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant='body2' color='text.primary'>{row.investorName}</Typography>
        <Typography variant='caption' color={theme.palette.text.secondary}>{row.investorEmail}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant='body2' color='text.primary'>{row.lastUpdatedAt}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant='body2' color='text.primary'>
          <Chip
            size='small'
            variant='outlined'
            color={allChipColors[row.status]}
            label={row.status}
          />
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
};
