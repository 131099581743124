import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { m } from 'framer-motion';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Iconify, Label, Scrollbar } from 'src/components';
import { varHover } from 'src/components/animate';
import { useResponsive } from 'src/hooks';
import { useBoolean } from 'src/hooks/';
import { RootState } from 'src/store';
import { readNotifications } from 'src/store/storage';

import { NotificationType } from '../../../../../types';
import { NotificationItem } from './notification-item';

export type NotificationTabType = 'all' | 'unread' | 'archive';

export const NotificationsPopover = () => {
  const [currentTab, setCurrentTab] = useState<NotificationTabType>('all');

  const notifications = useSelector((state: RootState) => state.Storage.notifications);
  const dispatch = useDispatch();
  const drawer = useBoolean();
  const smUp = useResponsive('up', 'sm');

  const unreadNotifications = notifications.filter(notification => !notification.isDone);
  const readedNotificatons = notifications.filter(notification => notification.isDone);
  const unreadNotificationsCount = unreadNotifications.length;
  const readedNotificatonsCount = readedNotificatons.length;

  const notificationsToRender: Record<NotificationTabType, NotificationType[]> = {
    all: notifications,
    unread: unreadNotifications,
    archive: readedNotificatons,
  };

  const TABS = [
    {
      value: 'all',
      label: 'All',
      count: notifications.length,
    },
    {
      value: 'unread',
      label: 'Unread',
      count: unreadNotificationsCount,
    },
    {
      value: 'archive',
      label: 'Archived',
      count: readedNotificatonsCount,
    },
  ];

  const handleChangeTab = useCallback((_: SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue as NotificationTabType);
  }, []);

  const handleMarkAllAsRead = () => {
    dispatch(readNotifications('notifications', unreadNotifications.map(notification => notification._id)));
  };

  const onOpenClickHandler = (notification: NotificationType) => (event: SyntheticEvent) => {
    event.stopPropagation();
    onReadClickHandler(notification)(event);
    window.open(notification.openUrl);
  };

  const onReadClickHandler = (notification: NotificationType) => (event: SyntheticEvent) => {
    event.stopPropagation();
    if (notification.isDone) return;
    dispatch(readNotifications('notifications', [notification._id]));
  };

  const renderHead = (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        py: 2,
        pl: 2.5,
        pr: 1,
        minHeight: 68,
      }}
    >
      <Typography variant='h6' sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {unreadNotificationsCount ? (
        <Tooltip title='Mark all as read'>
          <IconButton color='primary' onClick={handleMarkAllAsRead}>
            <Iconify icon='eva:done-all-fill'/>
          </IconButton>
        </Tooltip>
      ) : null}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon='mingcute:close-line'/>
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition='end'
          value={tab.value}
          label={tab.label}
          icon={
            tab.count ? (
              <Label
                variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
                color={
                  (tab.value === 'unread' && 'info') ||
                  'default'
                }
              >
                {tab.count}
              </Label>
            ) : undefined
          }
          sx={{ '&:not(:last-of-type)': { mr: 3 } }}
        />
      ))}
    </Tabs>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notificationsToRender[currentTab].map((notification) => (
          <NotificationItem
            onOpenClickHandler={onOpenClickHandler}
            onReadClickHandler={onReadClickHandler}
            key={notification._id}
            notification={notification}
          />
        ))}
      </List>
    </Scrollbar>
  )
  ;

  return (
    <>
      <IconButton
        component={m.button}
        whileTap='tap'
        whileHover='hover'
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={unreadNotificationsCount} color='error'>
          <Iconify icon='solar:bell-bing-bold-duotone' width={24}/>
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor='right'
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{
          sx: {
            width: 1,
            maxWidth: 420,
          },
        }}
      >
        {renderHead}

        <Divider/>

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            pl: 2.5,
            pr: 1,
          }}
        >
          {renderTabs}
        </Stack>

        <Divider/>

        {renderList}
      </Drawer>
    </>
  );
};
