import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack, { StackProps } from '@mui/material/Stack';
import isEqual from 'lodash/isEqual';
import { Fragment, useCallback } from 'react';
import { allChipColors, allChipVariants } from 'src/constants/constants';

import { ChipColorsType, ChipVariantsType } from '../../../../types';
import { Iconify } from '../iconify';
import { Block } from './block';

type TableFiltersResultProps<N, V> = StackProps & {
  filters: Object;
  chipColors?: {
    [key: string]: ChipColorsType;
  };
  chipVariants?: {
    [key: string]: ChipVariantsType;
  };
  defaultFilters: Object;
  onFiltersDeleteHandler: (name: keyof N, value: V) => void;
  onResetFilters: VoidFunction;
  results?: number;
};

export const TableFiltersResult = <N, V, >(props: TableFiltersResultProps<N, V>) => {

  const {
    filters,
    defaultFilters,
    onFiltersDeleteHandler,
    onResetFilters,
    results = 0,
    chipColors = allChipColors,
    chipVariants = allChipVariants,
    ...other
  } = props;

  const capitalizeFirstLetter = useCallback((str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }, []);

  return (
    <Stack spacing={1.5} {...other}>
      <Stack flexGrow={1} spacing={1} direction='row' flexWrap='wrap' alignItems='center'>
        {Object.keys(filters).map((filterKey) => {
          const filterValue = filters[filterKey as keyof typeof filters];
          const defaultFilterValue = defaultFilters[filterKey as keyof typeof defaultFilters];
          const identical = isEqual(filterValue, defaultFilterValue);
          const filterValueToArr = Array.isArray(filterValue) ? filterValue : [filterValue];

          return (
            <Fragment key={filterKey}>
              {!identical && (
                <Block label={`${capitalizeFirstLetter(filterKey)} :`}>
                  {filterValueToArr.map((value) => {
                    return (
                      <Chip
                        key={value}
                        label={value}
                        color={chipColors?.[value] || 'default'}
                        variant={chipVariants?.[value] || 'outlined'}
                        size='small'
                        onDelete={() => onFiltersDeleteHandler(filterKey as keyof N, value)}
                      />
                    );
                  })}
                </Block>
              )}
            </Fragment>
          );
        })}
        <Button
          color='error'
          onClick={onResetFilters}
          startIcon={<Iconify icon='solar:trash-bin-trash-bold'/>}
        >
          Clear
        </Button>
      </Stack>

      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component='span' sx={{
          color: 'text.secondary',
          ml: 0.25,
        }}>
          results found
        </Box>
      </Box>
    </Stack>
  );
};


