export const openInNewTabHandler = (href: string = '') => () => {
  openInNewTab(href);
};

export const openInNewTab = (href: string = '') => {
  const a = document.createElement('a');
  a.href = href;
  a.target = '_blank';
  a.rel = 'noreferrer';
  document.body.append(a);
  a.click();
  a.remove();
};

export const openMailClickHandler = (email: string = '') => () => {
  openMail(email);
};

export const openMail = (email: string = '') => {
  window.open(`mailto:${email}`);
};
