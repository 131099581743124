import _, { omit } from 'lodash';
import { defaultAssetsSections } from 'src/constants/demo-mock-data';
import { DashboardFilters } from 'src/features/dashboard/data';
import { getDocumentsWithAttached, getFullSectionConfig } from 'src/features/report-detail/helpers';
import { sectionKeyToValue } from 'src/features/report-page/edit-drawer/data';
import { AppThunk } from 'src/store';
import { customizer, removeEmptyValues } from 'src/utils';

import {
  Asset,
  AssetDocumentsType,
  CoverImagesType,
  DashboardDataType,
  DetailViewData,
  EquityMonitoringType,
  FileForUpload,
  NoteSettingType,
  NotificationRequestType,
  NotificationType,
  ReportKeyType,
  ReportSettingsType,
} from '../../../../types';
import { apiFetch } from '../api';
import { deleteDocument } from '../financial';
import {
  addNewReportSettings,
  setAddresses,
  setComparables,
  setDetailViewData,
  setEquityMonitoring,
  setHistory,
  setNewNotifications,
  setNotifications,
  updateAssetsViewData,
} from '.';
import { GeoCodesResponse, LoadComparablesBody, SaveReportSettingsProps } from './types';

export async function getLandPlot(latitude: number, longitude: number) {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    redirect: 'follow',
  };

  try {
    const response = await fetch(
      `https://dev.platform.naviantech.com/api/v1/lands/plot?latitude=${latitude}&longitude=${longitude}`,
      requestOptions);
    return await response.json();
  } catch (error) {
    return console.error('getLand error', error);
  }
}

export const getComparables = (comparablesData: LoadComparablesBody): AppThunk => async (dispatch) => {
  const corrsepondingBody: LoadComparablesBody = {
    maxPrice: 100_000_000,
    minPrice: 0,
    saleStatus: 'all',
    types: ['Apartment', 'Detached', 'Semi-detached', 'Terraced'],
    ...comparablesData,
  };
  try {
    const res = await apiFetch('storage/comparables', {
      method: 'post',
      body: JSON.stringify(corrsepondingBody),
      headers: {
        'Content-Type': 'application/json',
        language: 'SE',
      },
      returnError: true,
    });

    if ('error' in res) {
      console.error(res);
      return;
    }

    dispatch(setComparables(res));

  } catch (error) {
    console.error(error);
  }
};

export const getAddresses = (search: string): AppThunk => async (dispatch) => {
  try {
    const res = await apiFetch(`storage/address/${search}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        language: 'SE',
      },
      returnError: true,
    });

    if ('error' in res) {
      console.error(res);
      return;
    }
    dispatch(setAddresses(res));
  } catch (error) {
    console.error(error);
  }
};

export const getCoordinates = async (address: string): Promise<GeoCodesResponse | undefined> => {
  try {
    const res = await apiFetch(`storage/geocodes/${address}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        language: 'SE',
      },
      returnError: true,
    });

    if ('error' in res) {
      console.error(res);
      return;
    }

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const updateAsset = (asset: Asset): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;

  if (isDemo || !asset?._id) {
    dispatch(updateAssetsViewData(asset));
    return;
  }

  try {
    const removedFieldsAsset = omit(asset, ['_id', 'marketPlaceListing.coverImages', 'updatedAt', 'marketPlaceListing.coverImagesToDelete', 'marketPlaceListing.newCoverImageId', 'marketPlaceListing.documentsToDelete']);
    const cleanedAsset = removeEmptyValues(removedFieldsAsset);
    const response = await apiFetch(`assets/update/${asset?._id}`, {
      method: 'post',
      body: JSON.stringify({ ...cleanedAsset }),
      headers: { 'Content-Type': 'application/json' },
    });
    if (response._id) {
      dispatch(updateAssetsViewData(asset));
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const uploadCoverImageToAsset = async (assetId: string, file: CoverImagesType, isDemo?: boolean): Promise<CoverImagesType | undefined> => {
  if (isDemo) {
    return file;
  }

  try {
    const res: {
      _id: string
    } = await apiFetch(`assets/cover-image/${assetId}/${file.isCoverImage}/${file.sequenceNumber}`, {
      method: 'post',
      headers: { 'Content-Type': file.file?.type || file.filetype || 'image/png' },
      body: file.file,
    });
    return {
      ...file,
      file: undefined,
      _id: res._id,
    };
  } catch (error) {
    console.error(error);
  }
};

export const deleteCoverImageThunk = (imageId: string): AppThunk => async (_, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    return;
  }

  try {
    await apiFetch(`assets/cover-image/${imageId}`, { method: 'delete' });
  } catch (error) {
    console.error(error);
  }
};

export const setImageAsCoverThunk = (imageId: string): AppThunk => async (_, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    return;
  }

  try {
    await apiFetch(`assets/cover-image/${imageId}`, { method: 'post' });
  } catch (error) {
    console.error(error);
  }
};

export const getReportDetails = (leadId: string, mode: ReportKeyType, identifier: string = ''): AppThunk => async (dispatch) => {
  try {
    const response: DetailViewData = await apiFetch(`report/details/${leadId}/${mode}/${identifier}`, { method: 'get' });
    if (response.lead?._id) {
      const filledObject = _.mergeWith(response.assets?.sections, defaultAssetsSections, customizer);
      const sections: NoteSettingType[] = Object.entries(filledObject).map(([section, values]) => ({
        section: sectionKeyToValue[section],
        isActive: values.isActive,
        note: section === 'aboutProject' ? response.assets?.marketPlaceListing?.description : values.note,
        isNoteActive: values.isNoteActive,
        files: response.assets?.documents?.filter(doc => doc.attachedToAssetSection?.[section as keyof typeof doc.attachedToAssetSection]),
      }));

      const formattedData: ReportSettingsType = {
        leadId,
        identifier,
        reportName: mode,
        files: response.assets?.documents,
        images: response.assets?.marketPlaceListing?.coverImages,
        sections: sections,
        notes: sections.filter(sec => sec.section !== 'Gallery'),
        summaryText: response.assets?.marketPlaceListing?.description,
      };
      dispatch(setDetailViewData(response));
      dispatch(addNewReportSettings(formattedData));
    }
  } catch (error) {
    console.error(error);
  }
};

export const saveReportSettings = (settings: SaveReportSettingsProps, callback?: (settings: SaveReportSettingsProps) => void): AppThunk => async (dispatch, getState) => {
  try {
    const assets = getState().Storage.detailsData.assets;
    const isDemo = getState().App.config?.isDemo;

    if (assets?._id) {
      const filesInNotesSection = _.flatMap(settings.notes, 'files');
      const finalFiles = _.uniqBy([...settings.files || [], ...filesInNotesSection], '_id') as AssetDocumentsType[];
      dispatch(addNewReportSettings({
        ...settings,
        images: settings.images?.map(img => ({
          ...img,
          file: undefined,
        })),
        files: finalFiles,
      }));
      dispatch(updateAsset({
        ...assets,
        marketPlaceListing: {
          ...assets.marketPlaceListing,
          description: getFullSectionConfig(settings, 'Project Summary').note,
        },
        documents: getDocumentsWithAttached(finalFiles, settings),
        sections: {
          gallery: getFullSectionConfig(settings, 'Gallery'),
          aboutProject: getFullSectionConfig(settings, 'Project Summary'),
          ourRequest: getFullSectionConfig(settings, 'Our Request'),
          siteAndPurchase: getFullSectionConfig(settings, 'Site & Purchase'),
          proposedUnits: getFullSectionConfig(settings, 'Proposed Units'),
          construction: getFullSectionConfig(settings, 'Construction'),
          finance: getFullSectionConfig(settings, 'Finance'),
          sales: getFullSectionConfig(settings, 'Sales'),
          timelineAndCashflow: getFullSectionConfig(settings, 'Timeline & Cashflow'),
          sensitivityAnalysis: getFullSectionConfig(settings, 'Sensitivity Analysis'),
          projectMultiples: getFullSectionConfig(settings, 'Project Multiples'),
          localArea: getFullSectionConfig(settings, 'Local Area'),
          localMarket: getFullSectionConfig(settings, 'Local Market'),
          comparables: getFullSectionConfig(settings, 'Comparables'),
        },
      }));
      for (const doc of assets.documents || []) {
        if (!settings.files?.map(file => file?._id)?.includes(doc._id)) {
          await deleteDocument(doc._id, isDemo, true);
        }
      }
      const coverImages = [];
      for (const file of settings.images || []) {
        let coverImage = file;
        if (file.file) {
          const uploadedFile = await uploadCoverImageToAsset(assets._id, file, isDemo);
          if (uploadedFile) {
            coverImage = uploadedFile;
          }
        }
        coverImages.push(coverImage);
      }
      settings.coverImagesToDelete?.forEach(file => {
        dispatch(deleteCoverImageThunk(file._id));
      });
      settings.newCoverImageId && dispatch(setImageAsCoverThunk(settings.newCoverImageId));
      callback?.({
        ...settings,
        images: coverImages,
        coverImagesToDelete: [],
        newCoverImageId: '',
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const uploadFileToStorage = async (file: FileForUpload) => {
  try {
    const encodedFileName = encodeURIComponent(file.filename || '');
    const res: { url: string, } = await apiFetch(`storage/file/upload/${encodedFileName}`, {
      method: 'post',
      headers: { 'Content-Type': file.file?.type || file.filetype || 'image/png' },
      body: file.file,
    });
    return {
      ...file,
      file: undefined,
      url: res.url,
      _id: res.url,
    };
  } catch (error) {
    console.error(error);
  }
};


export type LoadNotificationsProps = {
  notificationType: NotificationRequestType;
  onlyNew?: boolean;
  onlyDone?: boolean;
  leadId?: string
  projectId?: string
}

export const loadNotifications = (params: LoadNotificationsProps): AppThunk => async (dispatch) => {
  try {
    const {
      notificationType,
      onlyNew = false,
      onlyDone = false,
      leadId = '',
      projectId = '',
    } = params;
    const leadQuery = leadId ? `/${leadId}` : '';
    const projectQuery = projectId ? `/${projectId}` : '';
    const notifications: NotificationType[] = await apiFetch(`notification/list/${notificationType}${leadQuery}${projectQuery}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        onlyNew,
        onlyDone,
      }),
    });
    if (notificationType === 'notifications') {
      if (onlyNew) {
        dispatch(setNewNotifications(notifications || []));
        return;
      }
      dispatch(setNotifications(notifications || []));
    }

    if (notificationType === 'logs') {
      dispatch(setHistory(notifications || []));
    }
  } catch (error) {
    console.error(error);
  }
};

export const readNotifications = (notificationType: NotificationRequestType, notificationIds: string[], leadId: string = '', projectId: string = ''): AppThunk => async (dispatch, getState) => {
  try {
    const notificationsInStore = getState().Storage.notifications;
    const historyInStore = getState().Storage.history;

    await Promise.all(notificationIds.map(id =>
      apiFetch(`notification/read/${id}`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
      },
      )));
    if (notificationType === 'notifications') {
      const updatedNotifications = notificationsInStore.map((notification) => ({
        ...notification,
        isDone: notificationIds.includes(notification._id) ? true : notification.isDone,
      }));
      dispatch(setNotifications(updatedNotifications));
    }
    if (notificationType === 'logs') {
      const updatedHistory = historyInStore.map((history) => ({
        ...history,
        isDone: notificationIds.includes(history._id) ? true : history.isDone,
      }));
      dispatch(setHistory(updatedHistory));
    }
    dispatch(loadNotifications({
      notificationType,
      leadId,
      projectId,
    }));
  } catch (error) {
    console.error(error);
  }
};


export const getEquityMonitoringData = (projectId: string, partnerId?: string): AppThunk => async (dispatch) => {
  try {
    const response: EquityMonitoringType = await apiFetch('ready-for-investors/equity-monitoring', {
      method: 'post',
      body: JSON.stringify({
        projectId,
        partnerId,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch(setEquityMonitoring(response));
  } catch (error) {
    console.error(error);
  }
};

export const getDashboardData = async (body: DashboardFilters) => {
  try {
    const response: DashboardDataType = await apiFetch('dashboard', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};
