import { Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { DetailsList, FieldType, getFileConfig, ListItemCustom } from 'src/components';
import { secondaryDefaultTextColor } from 'src/constants/colors';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { loadTermSheetDetails } from 'src/store/financial';
import { fDate, fNumber, fPercent, openInNewTab, showEmptyValue } from 'src/utils';

import { FinancialStepType, FinancialTableType } from '../../../../../types';

export interface DetailsDrawerProps {
  item?: FinancialTableType;
}

export const DetailsDrawer = (props: DetailsDrawerProps) => {
  const { item } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (item?._id) {
      dispatch(loadTermSheetDetails(item));
    }
    //   eslint-disable-next-line
  }, [dispatch, item?._id]);

  const ternSheetBlock: FieldType[] = useMemo(() => {
    return [
      {
        label: 'Requested',
        value: item?.termSheet?.date,
      },
      {
        label: 'Contact Person',
        value: item?.termSheet?.contactPerson,
      },
      {
        label: 'Contact Email',
        value: item?.termSheet?.contactEmail,
      },
    ];
  }, [item?.termSheet?.contactEmail, item?.termSheet?.contactPerson, item?.termSheet?.date]);

  const generateFields = useCallback((item?: FinancialStepType) => {
    return [
      {
        label: 'Total Funds',
        value: fNumber(item?.totalFunds) + ` ${DEFAULT_CURRENCY}`,
      },
      {
        label: 'Rate',
        value: fPercent(item?.rate),
      },
      {
        label: 'Fee',
        value: fPercent(item?.fee),
      },
      {
        label: 'Profit Share',
        value: showEmptyValue(fNumber(item?.profitShare)),
      },
      {
        label: 'LTGDV',
        value: fPercent(item?.ltgdv),
      },
      {
        label: 'LTC',
        value: fPercent(item?.ltc),
      },
      {
        label: 'Date',
        value: fDate(item?.date),
      },
      {
        label: 'Contact Person',
        value: item?.contactPerson,
      },
      {
        label: 'Contact Email',
        value: item?.contactEmail,
      },
    ];
  }, []);

  const bankOffer: FieldType[] = useMemo(() => {
    return generateFields(item?.offer);
  }, [generateFields, item?.offer]);

  const request: FieldType[] = useMemo(() => {
    return generateFields(item?.preliminaryDebtRequest);
  }, [generateFields, item?.preliminaryDebtRequest]);

  const onFileClickHandler = () => {
    openInNewTab(item?.termSheet?.file?.url);
  };

  return (
    <>
      {
        !item?.termSheet?.file?.url ? (
          <Stack gap={1.5}>
            <Typography variant='subtitle2'>Term Sheet</Typography>
            <DetailsList
              fields={ternSheetBlock}
              boxConfig={{
                p: 0,
                gap: 1.5,
              }}
            />
          </Stack>
        ) : (
          <Stack
            key={item?.termSheet?.file?._id}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={onFileClickHandler}
          >
            <ListItemCustom
              showAvatar
              listItemSx={{
                p: 0,
                width: 'fit-content',
              }}
              primaryText={item?.termSheet?.file?.filename}
              secondaryTextTypographySx={{ color: secondaryDefaultTextColor }}
              secondaryText={item?.termSheet?.file.uploadedBy}
              customAvatar={getFileConfig(item?.termSheet?.file?.filetype)?.icon}
            />
            <Typography noWrap variant='body2'>
              {item?.termSheet?.file.uploadedAt}
            </Typography>
          </Stack>
        )
      }
      <Stack gap={1.5}>
        <Typography variant='subtitle2'>Bank Offer</Typography>
        <DetailsList
          fields={bankOffer}
          boxConfig={{
            p: 0,
            gap: 1.5,
          }}
        />
      </Stack>
      <Stack gap={1.5}>
        <Typography variant='subtitle2'>Preliminary Request</Typography>
        <DetailsList
          fields={request}
          boxConfig={{
            p: 0,
            gap: 1.5,
          }}
        />
      </Stack>
    </>
  );
};
