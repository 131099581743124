import { Stack, Step, StepLabel, Stepper } from '@mui/material';
import { ElementType, ReactElement } from 'react';

import { Connector, StepIconComponent } from './components';

interface StepperCustomProps {
  steps: StepType[];
  connector?: ReactElement;
  customStepIcon?: ElementType | undefined;
}

export type StepType = {
  label: string;
  active: boolean;
  completed: boolean;
}

export const StepperCustom = (props: StepperCustomProps) => {
  const {
    steps,
    connector = <Connector/>,
    customStepIcon = StepIconComponent,
  } = props;

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper
        alternativeLabel
        connector={connector}
      >
        {steps.map((step) => (
          <Step active={step.active} completed={step.completed} key={step.label}>
            <StepLabel
              sx={{ whiteSpace: 'nowrap' }}
              StepIconComponent={customStepIcon}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};
