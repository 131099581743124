import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Card, { CardProps } from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from 'apexcharts';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Chart, useChart } from 'src/components/chart';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { usePopover } from 'src/hooks';
import { fNumber } from 'src/utils';

import { CustomPopover } from '../custom-popover';
import { Iconify } from '../iconify';

export interface CardRadialFilterBarChartProps extends CardProps {
  title?: string;
  chartHeader?: string;
  seriesValues?: string[];
  customSeriesValue?: string;
  customTotal?: number;
  updateCustomSeriesValue?: (value: string) => void;
  subheader?: string;
  showWidget?: boolean;
  showFilter?: boolean;
  chart: {
    colors?: string[][];
    series: {
      seriesFilter?: string;
      label: string;
      value: number;
    }[];
    options?: ApexOptions;
  };
}

export const CardRadialFilterBarChart = (props: CardRadialFilterBarChartProps) => {
  const {
    title,
    chartHeader,
    subheader,
    showWidget = true,
    showFilter = true,
    chart,
    seriesValues = [],
    customSeriesValue,
    updateCustomSeriesValue,
    customTotal,
    ...other
  } = props;
  const theme = useTheme();
  const [seriesFilter, setSeriesFilter] = useState<string>(seriesValues[0]);
  const popover = usePopover();

  const {
    colors = [
      [theme.palette.success.main, theme.palette.success.main],
      [theme.palette.success.dark, theme.palette.success.dark],
    ],
    series,
    options,
  } = chart;

  useEffect(() => {
    if (customSeriesValue && customSeriesValue !== seriesFilter) {
      setSeriesFilter(customSeriesValue);
    }
  }, [customSeriesValue, seriesFilter]);

  const handleChangeSeries = useCallback((newValue: string = '') => {
    popover.onClose();
    setSeriesFilter(newValue);
    updateCustomSeriesValue?.(newValue);
  }, [popover]);

  const total = useMemo(() => {
    return customTotal || series.reduce((acc, currentValue) => {
      if (currentValue.seriesFilter === seriesFilter) {
        return acc + currentValue.value;
      }
      return acc;
    }, 0 as number);
  }, [customTotal, series, seriesFilter]);

  const chartSeries = series.reduce((acc, currentValue) => {
    if (currentValue.seriesFilter === seriesFilter) {
      const newValue = +fNumber((currentValue.value / total) * 100);
      return [...acc, newValue];
    }
    return acc;
  }, [] as number[]);

  const memoChartOptions = {
    colors: colors.map((colr) => colr[1]),
    legend: { show: false },
    labels: series.map((i) => i.label),
    grid: {
      padding: {
        top: -32,
        bottom: -32,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: colors.map((colr) => [
          {
            offset: 0,
            color: colr[0],
          },
          {
            offset: 100,
            color: colr[1],
          },
        ]),
      },
    },
    plotOptions: {
      radialBar: {
        hollow: { size: '64%' },
        dataLabels: {
          name: { offsetY: -16 },
          value: { offsetY: 8 },
          total: {
            label: chartHeader || 'Total',
            formatter: () => {
              return `${fNumber(total)} ${DEFAULT_CURRENCY}`;
            },
          },
        },
      },
    },
    ...options,
  };

  const chartOptions = useChart(memoChartOptions);

  return (
    <>
      {
        showWidget ?
          <Card {...other}>
            <CardHeader
              title={title}
              subheader={subheader}
              sx={{ mb: 8 }}
              action={
                <>
                  {showFilter &&
                    <ButtonBase
                      onClick={popover.onOpen}
                      sx={{
                        pl: 1,
                        py: 0.5,
                        pr: 0.5,
                        borderRadius: 1,
                        typography: 'subtitle2',
                        bgcolor: 'background.neutral',
                      }}
                    >
                      {seriesFilter}
                      <Iconify
                        width={16}
                        icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                        sx={{ ml: 0.5 }}
                      />
                    </ButtonBase>
                  }
                </>
              }
            />
            {series.map((item, index) => {
              return (
                <Fragment key={index}>
                  {(item.seriesFilter === seriesFilter && index % 2 === 0) && (
                    <Chart
                      key={total}
                      type='radialBar'
                      series={chartSeries}
                      options={chartOptions}
                      sx={{
                        '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
                          height: '100% !important',
                          overflow: 'visible',
                        },
                      }}
                      height={323}
                    />
                  )}
                </Fragment>
              );
            })}

            <Stack spacing={2} sx={{ p: 5 }}>
              {series.map((item, index) => {
                let color = colors[index];
                color = color ?? colors[index % 2];
                return (
                  <>
                    {item.seriesFilter === seriesFilter &&
                      <Stack
                        key={item.label}
                        spacing={1}
                        direction='row'
                        alignItems='center'
                        sx={{ typography: 'subtitle2' }}
                      >
                        <Box
                          sx={{
                            width: 16,
                            height: 16,
                            borderRadius: 0.75,
                            bgcolor: color,
                          }}
                        />
                        <Box
                          sx={{
                            color: 'text.secondary',
                            flexGrow: 1,
                          }}
                        >
                          {item.label}
                        </Box>
                        {fNumber(item?.value)} {DEFAULT_CURRENCY}
                      </Stack>
                    }
                  </>
                );
              })}
            </Stack>
          </Card>
          : <Skeleton variant='rounded' height='492px' width='100%'/>
      }
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        {seriesValues.map((option, index) => (
          <MenuItem
            key={index}
            selected={option === seriesFilter}
            onClick={() => handleChangeSeries(option)}
          >
            {option}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
};
